
import React, { useContext, useRef, useState } from 'react'
import { Button } from '../../../../components/utils/atoms/Button';
import logo from '../../../../image/logo.png';
import Link from '../../../../components/utils/atoms/Link';
import { useNavigate } from 'react-router-dom';
import { UserApi } from '../../../../api/user';
import { UserContext } from '../../../../context/UserContext';
import { ConfirmContext } from '../../../../context/ModalContext';
import { LoadingSpinner } from '../../../../components/utils/atoms/LoadingSpinner';

export const LoginPage = () => {
  const { setUser, removeUser } = useContext(UserContext);
  const [spinner, setSpinner] = useState(false);

  const navigate = useNavigate();
  const { openConfirm } = useContext(ConfirmContext);


  const getUser = async () => {
    const res = (await UserApi.getUser()).data;
    if (res.result === 'success') {
      setUser(res.resultMap);
    } else {
      removeUser();
    }
  }

  const loginUser = async (id, password) => {
    await UserApi.login({ id, password }).then(res => {
      setSpinner(false);
      if (res.data.result === 'success') {
        sessionStorage.setItem('loginState/user', 'true');
        sessionStorage.setItem('loginState/user-id', id);
        navigate('/');
        getUser();

      }
      else {
        openConfirm(res.data.msg);
      }
    });
  }




  //@@ 이벤트 처리
  const idRef = useRef<HTMLInputElement>(null);
  const pwRef = useRef<HTMLInputElement>(null);

  const handleLogin = () => {

    const id = idRef.current.value.toString();
    const password = pwRef.current.value.toString();
    if (id === '') {
      openConfirm('아이디를 입력해주세요');
      return;
    };
    if (password === '') {
      openConfirm('비밀번호를 입력해주세요');
      return;
    };
    setSpinner(true);

    loginUser(id, password);

  }


  return (
    <div className='flex min-h-[80vh]'>
      {spinner && <LoadingSpinner />}
      <div className='self-center shadow-md m-auto w-[450px] rounded bg-white align-center p-4 pt-5 tablet:shadow-none'>
        <div>
          <img src={logo} alt='logo' className='inline-block h-6' />
          <span className='float-right'>Log in</span>
        </div>
        <input type="text" className='w-full border m-1 p-1 h-10' ref={idRef} placeholder='아이디를 입력하세요' />
        <input type="password" className='w-full border m-1 p-1 h-10' ref={pwRef} onKeyUp={(e) => e.key === 'Enter' && handleLogin()} placeholder='비밀번호를 입력하세요' />
        <div className='flex flex-col mt-10 gap-3'>
          <Button eventHandle={() => handleLogin()} size="full" color='main' >Log in</Button>
          <Button eventHandle={() => navigate('/user/join')} size="full" color='gray'>회원가입</Button>
        </div>
        <div className='flex gap-2 text-sm float-right mt-2'>
          <Link route="/user/findId">아이디 찾기</Link>
          <p>|</p>
          <Link route="/user/findPw">비밀번호 찾기</Link>
        </div>
      </div>
    </div>
  )
}
