import React, { useEffect, useState } from 'react';
import styles from './AlbumNav.module.css';
import { AlbumAttr, ChapterAttr, ContentTypeAttr } from '../../../components/utils/molecules/AlbumInfo';
import PDF from '../../../image/PictureasPdfFilled.png';
import Video from '../../../image/VideocamFilled.png';
import Link from '../../../image/LinkFilled.png';
import Text from '../../../image/text.png';
import Cloud from '../../../image/CloudDownloadFilled.png';
import CloseFilled from '../../../image/CloseFilled.png';
import { AlbumApi } from '../../../api/album';
import { useParams } from 'react-router-dom';

type AlbumCover = AlbumAttr & {
  contents: ChapterAttr[];
  onClose: () => void;
  handleNav: (id: string) => void;
};

export const AlbumNav = ({ info, contents, handleNav, onClose }: AlbumCover) => {

  const { contentId } = useParams();

  const [contentIdx, setContentIdx] = useState(0);

  const getIndex = () => {
    const index = contents.findIndex(item => {
      return item.id === contentId
    });
    setContentIdx(prev => index)
  }

  const getContent = (id) => {
    handleNav(id);
    getIndex();
  }

  useEffect(() => {
    getIndex();

  }, [contentId, contentIdx])

  return (
    <div className={styles.container}>
      <button className={styles.closeButton} onClick={onClose}>
        <img src={CloseFilled} alt="CloseFilled" />
      </button>
      <h2 className={styles.AlbumTitle}>{info.name}</h2>
      <p className={styles.progress}>
        '진도율 : {contentIdx + 1}번째 / 총 {contents.length}개 ({((contentIdx + 1) / (contents.length) * 100).toFixed(1)})%'
      </p>
      {info.chapter.map((chapter) => {
        return (
          <div>
            <div className={styles.chapterTitle}>{chapter.name}</div>
            {chapter.content.map(item => <div className={styles.classTitle} onClick={() => getContent(item.id)}>
              {item.name}
              {item.type === 1 ? <img className={styles.typeIcon} src={Video} alt="Video" />
                : item.type === 2 ? <img className={styles.typeIcon} src={PDF} alt="PDF" />
                  : item.type === 3 ? <img className={styles.typeIcon} src={Link} alt="Link" />
                    : item.type === 4 ? <img className={styles.typeIcon} src={Cloud} alt="Cloud" />
                      : <img className={styles.typeIcon} src={Text} alt="Text" />}
            </div>)}
          </div>
        )
      })}
    </div>
  )
}
