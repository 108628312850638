import React, { useEffect, useState } from 'react'
import { Youtube } from '../../../utils/atoms/Youtube'
import { Video } from '../../../../components/utils/atoms/Video'
import { checkNaverUrl, checkYoutubeUrl } from '../../../../utils/RegExp';

export type VideoAttr = {
  videoLink: string;
  height?: string;
}
export const VideoContent = ({ videoLink, height = '750' }: VideoAttr) => {
  const [videoPlayer, setVideoPlayer] = useState<'naver' | 'youtube'>('youtube');
  const [link, setLink] = useState(videoLink);
  useEffect(() => {
    if (checkNaverUrl(videoLink) !== null) {
      setVideoPlayer('naver');
      const linkChange = videoLink.replace('/v/', '/embed/')
      setLink(linkChange);
    } else if (checkYoutubeUrl(videoLink) !== null) {
      setVideoPlayer('youtube')
      setLink(videoLink);
    }
  }, [videoLink])
  return (
    <div>
      {
        videoPlayer === 'youtube' ? <Youtube videoLink={link} height={height} />
          : videoPlayer === 'naver' && <Video videoLink={link} height={'800'} />
      }
    </div>
  )
}
