import React from 'react'

type tagProp = {
  text: string;
  color?: 'main' | 'sub';
}

export const Tag = ({ text, color = 'main' }: tagProp) => {
  return (
    <>
      {
        color === 'main'
          ? <div className='rounded-xl border border-blue-900 inline-block pl-2 pr-2  text-xs m2 text-blue-900 mr-1' >
            <p className=' inline-block break-all'>{text}</p></div>
          : <div className='rounded-xl border border-gray-400 inline-block pl-2 pr-2 pt-[1px] text-xs mr-1 text-gray-400' >{text}</div>
      }
    </>
  )
}
