import { SubNav } from '../../../../../components/utils/molecules/SubNav';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { manageMenuArr } from '../../manage/ManageUserPage';
import Table from '../../../../../components/utils/molecules/Table';
import { Search } from '../../../../../components/utils/atoms/Search';
import { Title } from '../../../../../components/utils/atoms/Title';
import { Button } from '../../../../../components/utils/atoms/Button';
import { useNavigate } from 'react-router-dom';
import { BoardApi } from '../../../../../api/board';
import { PagiNation } from '../../../../../components/utils/atoms/PagiNation'
import { UserContext } from '../../../../../context/UserContext';


export const boardMenuArr = [
  {
    url: '/board/notice/list',
    text: '공지사항'
  },
  {
    url: '/board/qna/list',
    text: 'QnA'
  }
]
export const NoticeListPage = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext)
  const [noticeList, setNoticeList] = useState([]);

  const handlePageChange = (page) => {
    setPage(prev => ({ ...prev, currentPage: page }))
  }

  const [page, setPage] = useState({
    count: 1,
    currentPage: 1,
    endPage: 1,
    next: false,
    prev: false,
    startCount: 0,
    startPage: 1,
    totalPage: 1
  });

  const getList = async (option, text) => {
    const result = await BoardApi.getNoticeList({ page: page.currentPage, search_option: option, search_text: text })
    setNoticeList(result.data.resultList);
    setPage(result.data.resultMap)
  }
  useEffect(() => {
    getList(1, '');
  }, [page.currentPage])



  const columns = useMemo(() => columnData, []);


  const data = useMemo(() => noticeList, [noticeList]);

  const click = (seq) => {
    navigate('/board/notice/view/' + seq);
  }
  const handleOption = (option, keyword) => {
    getList(option, keyword);
  }


  return (
    <>
      <div className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl m-auto flex pt-5'>
        <SubNav menu={boardMenuArr} />
        <div className='pl-5 w-full'>
          <Title>공지사항</Title>
          <div className='pl-5 pt-3'>
            <Search options={Option} propsHandle={handleOption} />
            <div className='float-left inline-block mt-8'>총 게시물 {page.count}개</div>
            <Table columns={columns} data={data} clickHandle={click} />
          </div>
          <div className='float-right mt-10'>
            {
              user !== null && user.type === 1 && <Button eventHandle={() => navigate('/board/notice/write')} color="sub">공지사항 작성</Button>
            }
          </div>
        </div>
      </div>
      <PagiNation
        activePage={page.currentPage}
        itemsCountPerPage={10}
        totalItemsCount={page.count}
        pageRangeDisplayed={5}
        prevPageText={"‹"}
        nextPageText={"›"}
        firstPageText={"‹‹"}
        lastPageText={"››"}
        onChange={handlePageChange} />
    </>
  )
}



const Option = [{
  'title': '제목+내용',
  "value": 1,
}, {
  'title': '제목',
  "value": 2,
}, {
  'title': '내용',
  "value": 3,
}];

const columnData = [
  {
    Header: '순번',
    accessor: 'seq',
  },
  {
    Header: '제목',
    accessor: 'title',
  },
  {
    Header: '작성일',
    accessor: 'datetime',
  },
  {
    Header: '작성자',
    accessor: 'nickname',
  },
  {
    Header: '조회수',
    accessor: 'view_count',
  },
];