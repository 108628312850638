import React, { useEffect, useRef } from 'react';
import { Button } from '../atoms/Button';

type DialogAttr = {
  msg: string;
  submitHandle?: () => void;
  closeHandle: () => void;
}
type CustomAttr = {
  component: any;
  submitHandle?: () => void;
  closeHandle: () => void;
}

type PromptAttr = {
  type?: string;
  msg: string;
  defaultVal?: string;
  confirmButton: string;
  submitHandle: (text?: string) => void;
  cancelHandle: () => void;
  subButton?: string;
  subHandle?: () => void;
}


export const Alert = ({ msg, submitHandle, closeHandle }: DialogAttr) => {
  const submit = () => {
    submitHandle();
    closeHandle();
  }
  return (
    <div className='fixed top-0 left-0 z-50 bg-[rgba(0,0,0,0.15)] w-[100%] h-[100vh]'>
      <div className='rounded shadow-md p-3 m-auto border w-[500px] fixed top-[40%] left-[50%] translate-x-[-50%]  bg-white'>
        {msg}
        <div className='flex gap-2 flex-row-reverse'>
          <Button eventHandle={submit} color="gray">확인</Button>
          <Button eventHandle={closeHandle} color="gray">취소</Button>
        </div>
      </div >
    </div>
  )
}

export const Confirm = ({ msg, closeHandle, submitHandle }: DialogAttr) => {
  useEffect(() => {
    setTimeout(closeHandle, 1000);
    setTimeout(submitHandle, 1100);
  }, [])
  return (
    <div className='fixed top-0 left-0 z-50 bg-[rgba(0,0,0,0.15)] w-[100%] h-[100vh]'>
      <div className='rounded shadow-md p-7 m-auto border w-[500px] fixed top-[40%] left-[50%] translate-x-[-50%]  bg-white z-10 tablet:w-[90%] '>
        {msg}
      </div >
    </div>
  )
}

export const Custom = ({ component, closeHandle }: CustomAttr) => {

  return (
    <div className='fixed top-0 left-0 z-50 bg-[rgba(0,0,0,0.15)] w-[100%] h-[100vh]'>
      <div className='rounded shadow-md p-7 m-auto border w-[500px] fixed top-[40%] left-[50%] translate-x-[-50%]  bg-white z-10 tablet:w-[90%] '>
        {typeof component === 'function' ? component() : component}
      </div >
    </div>
  )
}


export const Prompt = ({ type, msg, defaultVal, submitHandle, cancelHandle }: PromptAttr) => {
  const inputRef = useRef<HTMLInputElement>(null);;

  const submit = () => {
    console.log(inputRef.current.value)
    submitHandle(inputRef.current.value);
    cancelHandle();
  }

  return (
    <div className='fixed top-0 left-0 z-50 bg-[rgba(0,0,0,0.15)] w-[100%] h-[100vh]'>
      <div className='rounded shadow p-3 m-auto border w-1/3 fixed bg-white top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-1 '>
        {msg}
        <input type={type} className="border block mt-2 mb-5 w-full h-10 p-1" ref={inputRef} defaultValue={defaultVal} />
        <div className='flex gap-2 flex-row-reverse'>
          <Button eventHandle={cancelHandle} color="gray">취소</Button>
          <Button eventHandle={submit} color="sub">확인</Button>
        </div>
      </div >
    </div>
  )
}


export const PromptEdit = ({ msg, confirmButton, defaultVal, subButton, submitHandle, subHandle, cancelHandle }: PromptAttr) => {
  const inputRef = useRef<HTMLInputElement>(null);;
  const submit = () => {
    submitHandle(inputRef.current.value.toString());
  }
  return (
    <div className='fixed top-0 left-0 z-50 bg-[rgba(0,0,0,0.15)] w-[100%] h-[100vh]'>
      <div className='fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded shadow p-3 m-auto border w-1/3 bg-white'>
        {msg}
        <input
          type={'text'}
          className="border block mt-2 mb-5 w-full h-10"
          ref={inputRef}
          defaultValue={defaultVal} />
        <div className='flex gap-2 flex-row-reverse'>
          <Button eventHandle={cancelHandle} color="gray">취소</Button>
          <Button eventHandle={subHandle} color="gray">{subButton}</Button>
          <Button eventHandle={submit} color="sub">{confirmButton}</Button>
        </div>
      </div >
    </div>
  )
}

