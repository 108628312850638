import { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AlbumApi } from "../api/album";
import { ConfirmContext } from "./ModalContext";

type albumAttr = {
  name: string,
  introduction: string,
  chapter: { name: string, content: any, val: boolean }[] | null,
  thumbnail: string,
  category: string,
  tag: string,
  pk: string,
}



export const CreateAlbumContext = createContext(null);
export const CreateAlbumProvider = ({ children }: any) => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const { openConfirm } = useContext(ConfirmContext);


  const [contentAlbum, setContentAlbum] = useState<albumAttr>({
    name: '',
    introduction: '',
    chapter: [{
      name: '',
      val: false,
      content: [
        {
          name: '', type: '1', show: true, val: false,
        }
      ]
    }],
    thumbnail: '',
    category: '',
    tag: '',
    pk: ''
  });


  //앨범 정보를 변경
  const changeContentAlbum = (album: albumAttr) => setContentAlbum(prev => album);

  const changeStep = (step: number) => {
    setStep(step);
  }

  const changeInfo = (album: albumAttr) => {
    setContentAlbum(prev => ({ ...prev, ...album }));
  }
  const changeChapter = (chapter: { name: string, val: boolean, content: string }[]) => {
    setContentAlbum(prev => ({
      ...prev,
      chapter: chapter
    }));
  }

  //중복클릭 방지
  const [disable, setDisable] = useState(false);

  const publish = () => {
    !disable && AlbumApi.setAlbum(contentAlbum)

      .then(() => setDisable(true))
      .then(() => navigate('/album/list'))
      .catch(() => openConfirm('앨범이 생성되지 않았습니다.'))
  }

  const modify = (id) => {
    const modifyData: albumAttr = {
      ...contentAlbum,
      chapter: contentAlbum.chapter.map(chapter => {
        return {
          ...chapter,
          content: chapter.content.map(item => {
            let newItem;
            if (item.type === "2" || item.type === "4") {
              newItem = { ...item, file: item.link };

            }
            newItem = { ...item, content: item.content !== undefined ? item.content : item.link };
            delete item.link;
            return newItem;
          }),
        };
      }),
    }

    !disable && AlbumApi.modifyAlbum(id, modifyData)
      .then(() => setDisable(true))
      .then(() => navigate('/album/list'))
      .catch(() => openConfirm('앨범이 수정되지 않았습니다.'))
  }




  return (
    <CreateAlbumContext.Provider
      value={{ contentAlbum, changeContentAlbum, step, modify, changeStep, changeInfo, changeChapter, publish }}>
      {children}
    </CreateAlbumContext.Provider>
  )
}