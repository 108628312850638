import { NoticeListPage } from '../components/views/page/board/notice/NoticeListPage';
import { NoticeModifyPage } from '../components/views/page/board/notice/NoticeModifyPage';
import { NoticeViewPage } from '../components/views/page/board/notice/NoticeViewPage';
import { NoticeWritePage } from '../components/views/page/board/notice/NoticeWritePage';
import { QnaListPage } from '../components/views/page/board/qna/QnaListPage';
import { QnaModifyPage } from '../components/views/page/board/qna/QnaModifyPage';
import { QnaViewPage } from '../components/views/page/board/qna/QnaViewPage';
import { QnaWritePage } from '../components/views/page/board/qna/QnaWritePage';
import { Route, Routes } from 'react-router-dom';
import React from 'react';
import { BoardProvider } from '../context/BoardContext';


export const RouteBoard = () => {
  return (
    <Routes>

      <Route
        path='/notice/list'
        element={
          <BoardProvider>
            <NoticeListPage />
          </BoardProvider>}>
      </Route>
      <Route
        path='/notice/view/:seq'
        element={
          <BoardProvider>
            <NoticeViewPage />
          </BoardProvider>}>
      </Route>
      <Route
        path='/notice/write'
        element={
          <BoardProvider>
            <NoticeWritePage />
          </BoardProvider>}>
      </Route>
      <Route
        path='/notice/modify/:seq'
        element={
          <BoardProvider>
            <NoticeModifyPage />
          </BoardProvider>}>
      </Route>
      <Route
        path='/qna/list'
        element={
          <BoardProvider>
            <QnaListPage />
          </BoardProvider>}>
      </Route>
      <Route
        path='/qna/view/:seq'
        element={
          <BoardProvider>
            <QnaViewPage />
          </BoardProvider>}>
      </Route>
      <Route
        path='/qna/write'
        element={
          <BoardProvider>
            <QnaWritePage />
          </BoardProvider>}>
      </Route>
      <Route
        path='/qna/modify/:seq'
        element={
          <BoardProvider>
            <QnaModifyPage />
          </BoardProvider>}>
      </Route>
    </Routes>
  )
}
