import axios from "axios";

export const ManageApi = {

  //user
  getUserList: async (params?: { page?: number, sort_option?: number, search_text?: string }) => {
    try {
      const res = await axios.get(`/codeenator/api/admin/user/getUserList.do?user_agency=web`, { params });
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  initPw: async (params: { seq: number, enable_email?: boolean }) => {
    try {
      const res = await axios.get(`/codeenator/api/admin/user/initPassword.do?user_agency=web`, { params });
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  sendEmail: async (seq: number, object?: { title: string, content: string }) => {
    try {
      const res = await axios.post(`/codeenator/api/admin/user/sendEmail.do?user_agency=web&seq=${seq}`, object);
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  setUserComment: async (seq: number, object?: { comment: string }) => {
    try {
      const res = await axios.post(`/codeenator/api/admin/user/setComment.do?user_agency=web&seq=${seq}`, object);
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  deleteUser: async (params: { seq: number }) => {
    try {
      const res = await axios.get(`/codeenator/api/admin/user/deleteUser.do?user_agency=web`, { params });
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  //board
  getContentsList: async (params?: { page?: number, sort_option?: number, search_text?: string }) => {
    try {
      const res = await axios.get(`/codeenator/api/admin/board/getContentList.do?user_agency=web`, { params });
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  contentEnableChange: async (seq: number, object: { enable: boolean }) => {
    try {
      const res = await axios.post(`/codeenator/api/admin/board/enableViewContent.do?user_agency=web&seq=${seq}`, object);
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  deleteContent: async (seq: number) => {
    const res = await axios.get(`/codeenator/api/admin/board/deleteContent.do?user_agency=web&seq=${seq}`);
    return res;
  },
  getCommentList: async (seq: number, object?: { comment: string }) => {
    try {
      const res = await axios.post(`/codeenator/api/admin/user/setComment.do?user_agency=web&seq=${seq}`, object);
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  commentViewChange: async (params: { seq: number }) => {
    try {
      const res = await axios.get(`/codeenator/api/admin/user/deleteUser.do?user_agency=web`, { params });
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  deleteComment: async (params: { seq: number }) => {
    try {
      const res = await axios.get(`/codeenator/api/admin/user/deleteUser.do?user_agency=web`, { params });
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  //album
  getAlbumList: async (params?: { page?: number, sort_option?: number }) => {
    try {
      const res = await axios.get(`/codeenator/api/admin/album/getAlbumList.do?user_agency=web`, { params });
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  getAlbum: async (params: { id: string }) => {
    try {
      const res = await axios.get(`/codeenator/api/admin/album/getAlbum.do?user_agency=web`, { params });
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  modifyAlbum: async (id: string, object: { name: string, introduction: string, category: string, user_tag: string, admin_tag: string, thumbnail: string }) => {
    try {
      const res = await axios.post(`/codeenator/api/admin/album/modifyAlbum.do?user_agency=web&id=${id}`, object);
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  modifyStatus: async (id: string, object?: { status: string }) => {
    try {
      const res = await axios.post(`/codeenator/api/admin/album/modifyStatus.do?user_agency=web&id=${id}`, object);
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  getAlbumDenialReason: async (seq: number, object?: { comment: string }) => {
    try {
      const res = await axios.post(`/codeenator/api/admin/user/setComment.do?user_agency=web&seq=${seq}`, object);
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  setAlbumDenialReason: async (params: { seq: number }) => {
    try {
      const res = await axios.get(`/codeenator/api/admin/user/deleteUser.do?user_agency=web`, { params });
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  deleteAlbum: async (params: { id: string }) => {
    try {
      const res = await axios.get(`/codeenator/api/admin/album/deleteAlbum.do?user_agency=web`, { params });
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  //portal
  getSetting: async () => {
    try {
      const res = await axios.get(`/codeenator/api/admin/portal/getSetting.do?user_agency=web`);
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  setSetting: async (object: { banner: any[], album_count: number, album_sort: number, popup_enable: boolean, popup_width: number, popup_height: number, popup_content: string }) => {
    try {
      const res = await axios.post(`/codeenator/api/admin/portal/setSetting.do?user_agency=web`, object);
      return res;
    }
    catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  }
}