import axios from "axios";

export const BoardApi = {
  getAlbumList: async (params: {
    user_agent: string,
    page?: number | null,
    category?: string | null,
    sort_option?: number | null,
    search_option?: number | null,
    search_text?: string | null,
  }) => {
    const res = await axios.get(`/codeenator/api/album/getAlbumList.do?user_agent=web`, { params });
    return res;
  },


  getNoticeList: async (params: { page?: number, search_option?: number, search_text?: string }) => {
    const res = await axios.get(`/codeenator/api/board/getNoticeList.do?user_agent=web`, { params });
    return res;
  },

  setNotice: async (object: { title: string, content: string, file?: any }) => {
    const res = await axios.post(`/codeenator/api/board/setNotice.do?user_agent=web`, object);
    return res;
  },

  modifyNotice: async (seq: string, object: { title: string, content: string, file?: any }) => {
    const res = await axios.post(`/codeenator/api/board/modifyNotice.do?user_agent=web&seq=${seq}`, object);
    return res;
  },

  getNotice: async (params: { seq: string }) => {
    const res = await axios.get(`/codeenator/api/board/getNotice.do?user_agent=web`, { params });
    return res;
  },

  deleteNotice: async (params: { seq: string }) => {
    const res = await axios.get(`/codeenator/api/board/deleteNotice.do?user_agent=web`, { params });
    return res;
  },

  getComment: async (params: { content: string, page?: number }) => {
    const res = await axios.get(`/codeenator/api/board/getCommentList.do?user_agent=web`, { params });
    return res;
  },

  setComment: async (content: string, object: { comment: string, reply?: number }) => {
    const res = await axios.post(`/codeenator/api/board/setComment.do?user_agent=web&content=${content}`, object);
    return res;
  },

  deleteComment: async (params: { seq: number }) => {
    const res = await axios.get(`/codeenator/api/board/deleteComment.do?user_agent=web`, { params });
    return res;
  },

  getQuestionList: async (params: { page?: number, search_option?: number, search_text?: string }) => {
    try {
      const res = await axios.get(`/codeenator/api/board/getQuestionList.do?user_agent=web`, { params });
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  setQuestion: async (object: { title: string, content: string, file?: any }) => {
    try {
      const res = await axios.post(`/codeenator/api/board/setQuestion.do?user_agent=web`, object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  modifyQuestion: async (seq: string, object: { title: string, content: string, file?: any }) => {
    try {
      const res = await axios.post(`/codeenator/api/board/modifyQuestion.do?user_agent=web&seq=${seq}`, object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  getQuestion: async (params: { seq: string }) => {
    try {
      const res = await axios.get(`/codeenator/api/board/getQuestion.do?user_agent=web`, { params });
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  deleteQuestion: async (params: { seq: string }) => {
    try {
      const res = await axios.get(`/codeenator/api/board/deleteQuestion.do?user_agent=web`, { params });
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
}