import axios from "axios";

export const UserApi = {

  getUser: async () => {
    try {
      const res = await axios.get(`/codeenator/api/user/getUser.do?user_agency=web`);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  // login-out
  login: async (object: { id: string, password: string }) => {
    try {
      const res = await axios.post(`/codeenator/api/user/login.do?user_agency=web`, object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  logout: async () => {
    try {
      const res = await axios.post(`/codeenator/api/user/logout.do?user_agency=web`);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  // join
  checkId: async (object: { id: string }) => {
    try {
      const res = await axios.post(`/codeenator/api/user/isUsedId.do?user_agency=web`, object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  getCipher: async (object: { email: string }) => {
    try {
      const res = await axios.post('/codeenator/api/user/email/getCipher.do?user_agency=web', object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  checkCipher: async (object: { email: string, cipher: string }) => {
    try {
      const res = await axios.post('/codeenator/api/user/email/checkCipher.do?user_agency=web', object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  checkNick: async (object: { nickname: string }) => {
    try {
      const res = await axios.post('/codeenator/api/user/isUsedNickname.do?user_agency=web', object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  join: async (object: { id: string, password: string, email: string, nickname: string }) => {
    try {
      const res = await axios.post('/codeenator/api/user/join.do?user_agency=web', object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  // findId
  findId: async (object: { email: string }) => {
    try {
      const res = await axios.post('/codeenator/api/user/findId.do?user_agency=web', object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  findPassword: async (object: { id: string, email: string }) => {
    try {
      const res = await axios.post('/codeenator/api/user/findPassword.do?user_agency=web', object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  // change
  checkUserPw: async (object: { password: string }) => {
    try {
      const res = await axios.post('/codeenator/api/user/isUsedPassword.do?user_agency=web', object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  modifyUser: async (object: { email: string, nickname: string }) => {
    try {
      const res = await axios.post('/codeenator/api/user/modifyUser.do?user_agency=web', object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  modifyPw: async (object: { current_password: string, new_password: string }) => {
    try {
      const res = await axios.post('/codeenator/api/user/modifyPassword.do?user_agency=web', object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  // withdrawal
  withdrawal: async (object: { user_agent: 'web', email: string }) => {
    try {
      const res = await axios.post('/codeenator/api/user/withdrawal.do?user_agency=web', object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  }
}