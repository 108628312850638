import React from 'react';
import { LoginPage } from '../components/views/page/userInfo/LoginPage';
import { Route, Routes } from 'react-router-dom';
import { JoinPage } from '../components/views/page/userInfo/JoinPage';
import { ModifyinfoPage } from '../components/views/page/userInfo/ModifyinfoPage';
import { ModifyPwPage } from '../components/views/page/userInfo/ModifyPwPage';
import { WelcomePage } from '../components/views/page/userInfo/WelcomePage';
import { FindIdPage } from '../components/views/page/userInfo/FindIdPage';
import { FindPwPage } from '../components/views/page/userInfo/FindPwPage';

export const RouteUserInfo = () => {
  return (
    <Routes>
      <Route path='/login' element={<LoginPage />}></Route>
      <Route path='/join' element={<JoinPage />}></Route>
      <Route path='/welcome' element={<WelcomePage />}></Route>
      <Route path='/findId' element={<FindIdPage />}></Route>
      <Route path='/findPw' element={<FindPwPage />}></Route>
      <Route path='/modify/info' element={<ModifyinfoPage />}></Route>
      <Route path='/modify/pw' element={<ModifyPwPage />}></Route>
    </Routes>
  )
}
