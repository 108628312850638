import React, { useContext, useEffect, useMemo, useState } from 'react'
import { SubNav } from '../../../utils/molecules/SubNav';
import { PagiNation } from '../../../utils/atoms/PagiNation';
import { ListenTable } from '../../template/myContents/ListenTable';
import { AlbumApi } from '../../../../api/album';
import { Confirm } from '../../../../components/utils/atoms/Dialog';
import { ConfirmContext } from '../../../../context/ModalContext';



export const ListenAlbumViewPage = () => {
  const { openConfirm } = useContext(ConfirmContext);
  const [page, setPage] = useState({
    count: 1,
    currentPage: 1,
    endPage: 1,
    next: false,
    prev: false,
    startCount: 0,
    startPage: 1,
    totalPage: 1
  });


  const getListenAlbums = async () => {
    const table = (await AlbumApi.getListenAlbumList()).data.resultList;
    table.map(item => {
      item["manage"] = '열람 이력 삭제';
    })
    setListenList(table);

    const page = (await AlbumApi.getListenAlbumList()).data.resultMap;
    setPage(page);

  };

  const [listenList, setListenList] = useState([]);
  useEffect(() => {
    getListenAlbums();
  }, [page.currentPage]);

  const data = useMemo(() => listenList, [listenList]);

  const click = (id) => {
    AlbumApi.deleteListenAlbum(id).then(() => openConfirm('내역이 삭제되었습니다'));
  }

  const handlePageChange = (page) => {
    setPage(prev => ({ ...prev, currentPage: page }))
  }


  return (
    <>
      <div className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl m-auto flex pt-5'>
        <SubNav menu={menuArr} />
        <div className='pl-5 w-full'>
          <ListenTable columns={columnData} data={data} clickHandle={(id) => click(id)} />
        </div>
      </div>
      <PagiNation
        activePage={page.currentPage}
        itemsCountPerPage={20}
        totalItemsCount={page.count}
        pageRangeDisplayed={5}
        prevPageText={"‹"}
        nextPageText={"›"}
        firstPageText={"‹‹"}
        lastPageText={"››"}
        onChange={handlePageChange} />
    </>
  )
}

export const menuArr = [
  {
    url: '/mycontents/album/view',
    text: '내 열람'
  },
]
const columnData = [
  {
    Header: '앨범명',
    accessor: 'name',
  },
  {
    Header: '첫 열람 날짜',
    accessor: 'first_datetime',
  },
  {
    Header: '최근 열람 날짜',
    accessor: 'recent_datetime',
  },
  {
    Header: '열람 횟수',
    accessor: 'listen_count',
  },
  {
    Header: '기능',
    accessor: 'manage',
  },
];
