
import { Button } from "../../../../components/utils/atoms/Button";
import React from "react";
import { useTable } from "react-table";

interface TableAttr {
  columns: any[];
  data: any[];
  rejectHandle: (id) => void;
  approveHandle: (id) => void;
  modifyHandle: (id) => void;
  deleteHandle: (id) => void;
}

export const AlbumTable = ({ columns, data, rejectHandle, approveHandle, modifyHandle, deleteHandle }: TableAttr) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <table {...getTableProps()} className="w-full text-center text-sm">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="pt-3 pb-3 border-b-2 border-b-gray-300">{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                if (cell.column.id === 'manage') {
                  return (
                    <td className="pr-5 pl-5 pt-2 pb-2 border-b">
                      <div className="flex gap-1">
                        {cell.row.values.status === '등록'
                          ? <Button eventHandle={() => rejectHandle(cell.row.original.id)} color="sub">등록취소</Button>
                          : <Button eventHandle={() => approveHandle(cell.row.original.id)} color="sub">등록</Button>}
                        <Button eventHandle={() => modifyHandle(cell.row.original.id)} color="sub">정보수정</Button>
                        <Button eventHandle={() => deleteHandle(cell.row.original.id)} color="sub">삭제</Button>
                      </div>
                    </td>)
                } else if (cell.column.id === 'name') {
                  return (
                    <td {...cell.getCellProps()} className="pr-5 pl-5 pt-2 pb-2 border-b min-w-[300px]	"
                    >{cell.render("Cell")}</td>
                  )
                } else if (cell.column.id === 'datetime') {
                  return (
                    <td {...cell.getCellProps()} className="pr-5 pl-5 pt-2 pb-2 border-b min-w-[150px]	"
                    >{cell.render("Cell")}</td>
                  )
                } else {
                  return (
                    <td {...cell.getCellProps()} className="pr-5 pl-5 pt-2 pb-2 border-b min-w-[80px]	"
                    >{cell.render("Cell")}</td>
                  )
                }

              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
