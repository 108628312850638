import React, { useContext } from 'react';
import { FileApi } from '../../../../../../api/file';
import { CreateAlbumContext } from '../../../../../../context/CreateAlbumContext';


export const AddPDF = ({ chapterIdx, contentIdx }: any) => {
  const { contentAlbum, changeContentAlbum } = useContext(CreateAlbumContext);


  const getPDF = async (e) => {
    let formData: any = new FormData();
    const { files } = e.target;
    formData.append('file', files[0]);

    const fileData = (await FileApi.uploadFile(formData)).data.resultMap;
    console.log(fileData)
    const data = { ...contentAlbum };
    data.chapter[chapterIdx].content[contentIdx].file = fileData.upload_path + '/' + fileData.stored_name;
    console.log(data)
  }


  //내용 저장
  const inputContent = (e) => {
    const { name, value } = e.target;
    const data = { ...contentAlbum };
    data.chapter[chapterIdx].content[contentIdx][name] = value;
    changeContentAlbum(data);
  }



  return (
    <div>
      <div className='border-b pb-2'>
        <input
          type="text"
          className='border w-full p-1 h-10'
          name='name'
          onChange={inputContent}
          placeholder='콘텐츠 제목 입력'
          defaultValue={contentAlbum.chapter[chapterIdx].content[contentIdx].name} />
      </div>
      <input type="file" className='w-full p-1' onChange={getPDF} accept={'.pdf'} />
      <p className='mt-10 mb-10 leading-8'>
        · 업로드할 pdf 파일을 선택합니다.<br />
        · 업로드 크기는 10MB 이하로 제한됩니다.
      </p>
    </div>
  )
}
