import React, { useRef } from 'react'
import { Button } from './Button';

type textinputAttr = {
  seq?: number,
  submit: (text, seq?) => void;
  setState: () => void;
  defalutVal?: string;
}


export const EditComment = ({ seq, submit, setState, defalutVal }: textinputAttr) => {
  const inputComment = useRef<HTMLTextAreaElement>(null);
  const handleSubmit = () => {
    submit(inputComment.current.value.toString(), seq);
    inputComment.current.value = '';
    setState();
  }

  return (
    <>
      <div className='gap-3 overflow-hidden'>
        <textarea
          ref={inputComment}
          rows={3}
          className="resize-none p-2 border w-full"
          defaultValue={defalutVal}></textarea>
        <div className='float-right'>
          <Button eventHandle={handleSubmit}>수정</Button>
        </div>
      </div>
    </>
  )
}
