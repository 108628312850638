import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/utils/atoms/Button';
import { CreateAlbumContext } from '../../../../context/CreateAlbumContext';
import { UserContext } from '../../../../context/UserContext';
import CircleGray from '../../../../image/CheckCircleOutlineFilled.png';
import CirclePurple from '../../../../image/CheckCircleOutlineFilledPurple.png';
import { AlbumInfo } from '../../template/myContents/create/AlbumInfo';
import { ContentsInput } from '../../template/myContents/create/ContentsInput';
import { Publish } from '../../template/myContents/create/Publish';
import { ConfirmContext } from '../../../../context/ModalContext';
import Modal from 'react-modal';
import doc01 from '../../../../image/album-doc-01.png';
import doc02 from '../../../../image/album-doc-02.png';
import { checkGreek } from '../../../../utils/RegExp';



export const CreateAlbumPage = () => {


  const { step, contentAlbum, changeInfo } = useContext(CreateAlbumContext);
  const { user } = useContext(UserContext);
  const { openConfirm } = useContext(ConfirmContext);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();


  //json파일 처리
  const JSONfileRef = useRef<HTMLInputElement>(null); //파일입력창(히든)

  const loadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const json = JSON.parse(event.target?.result as string);
      const toText = JSON.stringify(json);
      const escaped = toText.replace(/'/g, "＇");
      const data = JSON.parse(escaped)
      changeInfo(data);
    };
    reader.readAsText(file);
  }

  const saveFile = () => {
    function download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }
    delete contentAlbum.thumbnail;
    download(JSON.stringify(contentAlbum), contentAlbum.name + '.json', 'text/plain');
  }


  const chackUserState = () => {
    user === null && openConfirm('해당 메뉴는 로그인 후 이용 가능합니다.', () => navigate('/album/list'));
  }

  const buttonHandle = () => {
    setIsOpen(true);
  }

  useEffect(() => {
    chackUserState();
  }, [user])




  return (
    <div className='bg-zinc-100 min-h-[100vh] pb-10'>
      <div className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl m-auto flex'>
        <div className='flex-auto w-1/4 pt-8'>
          <h3 className='font-bold text-lg'>앨범 제작</h3>
          <ul className='mt-5 mb-10'>
            <li className='mt-3 mb-3'>
              {step === 1
                ? <img src={CircleGray} alt="" className='inline' />
                : <img src={CirclePurple} alt="" className='inline' />}
              앨범 정보
            </li>
            <li className='mt-3 mb-3'>
              {step === 1 || step === 2
                ? <img src={CircleGray} alt="" className='inline' />
                : <img src={CirclePurple} alt="" className='inline' />}
              콘텐츠 입력
            </li>
            <li className='mt-3 mb-3'>
              <img src={CircleGray} alt="" className='inline' />
              동의 및 발행
            </li>
          </ul>
          <input
            type="file"
            ref={JSONfileRef}
            onChange={(e) => loadFile(e)}
            hidden />
          <div className='flex gap-2'>
            {
              step === 1
                ? <Button eventHandle={() => JSONfileRef.current.click()}>JSON 불러오기</Button>
                : <Button eventHandle={saveFile}>JSON 저장하기</Button>}
          </div>
        </div>
        <div className='flex-auto w-3/4 bg-white p-8'>
          {step === 1
            ? <AlbumInfo privateKey={''} />
            : step === 2
              ? <ContentsInput />
              : <Publish />}
        </div>
      </div>
      {(step === 1 || step === 2) && <aside>
        <button className='fixed right-[200px]  w-[60px] h-[60px] bg-[#9333EA] text-white shadow-sm rounded-full text-center leading-[60px] cursor-pointer' onClick={buttonHandle}>
          Help
        </button>
      </aside>}
      <Modal className='w-full absolute' isOpen={isOpen}>
        <div className='mt-[100px] w-[1000px] m-auto overflow-auto bg-white border p-10 text-center'>
          {step === 1 ? <img src={doc01} alt="앨범사용법"></img> : step === 2 && <img src={doc02} alt="앨범사용법"></img>}
          <Button eventHandle={() => { setIsOpen(false) }}>닫기</Button>
        </div>
      </Modal>
    </div>
  )
}
