import { Title } from '../../../../../components/utils/atoms/Title'
import { SubNav } from '../../../../../components/utils/molecules/SubNav'
import React, { useContext, useEffect, useState } from 'react'
import { boardMenuArr } from './NoticeListPage'
import { BoardApi } from '../../../../../api/board'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../../components/utils/atoms/Button'
import { UserContext } from '../../../../../context/UserContext';
import { BoardCommentArea } from '../../../../../components/utils/molecules/BoardCommentArea'
import { AlertContext, ConfirmContext } from '../../../../../context/ModalContext';

type contentAttr = {
  title: '';
  datetime: string;
  content: string;
  user_nickname: string;
  user_id: string;
  file: null | { name: string, path: string }[];
}
export const NoticeViewPage = () => {
  const { seq } = useParams();
  const { user } = useContext(UserContext);
  const { openConfirm } = useContext(ConfirmContext);
  const { openAlert } = useContext(AlertContext);



  const [notice, setNotice] = useState<contentAttr>({
    title: '',
    datetime: '',
    content: '',
    user_nickname: '',
    file: [],
    user_id: ''
  });


  const [comments, setComments] = useState([]);

  const [chageState, setChangeState] = useState(false);
  const navigate = useNavigate();

  const submit = (comment, reply) => {
    BoardApi.setComment(seq, { comment, reply });
    setChangeState(true);
  }

  const getContent = async () => {
    const content = (await (BoardApi.getNotice({ seq }))).data.resultMap;
    const comment = (await (BoardApi.getComment({ content: seq }))).data.resultList;
    setNotice(content);
    setComments(comment);
    setChangeState(false);
  }

  const deleteContent = () => {

    openAlert('게시글을 삭제하시겠습니까?',
      () => BoardApi.deleteNotice({ seq })
        .then(res => res.data.result === 'success'
          ? navigate('/board/notice/list')
          : openConfirm(res.data.msg)
        ));
  }


  const deleteComment = async (seq: number) => {
    BoardApi.deleteComment({ seq }).then(() => window.location.reload());
    setChangeState(true);
  }


  useEffect(() => {
    getContent();
  }, [chageState])

  return (
    <div className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl m-auto flex pt-5'>
      <SubNav menu={boardMenuArr} />
      <div className='pl-5 w-full'>
        <Title>공지사항</Title>
        <div>
          <div className=' border-b m-3 mt-10'>
            <b className='text-lg'>{notice.title}</b> <br />
            <span className=' text-gray-500 text-sm'>{notice.datetime}</span>
            <span className=' text-gray-500 text-sm ml-5'>{notice.user_nickname}</span>
          </div>
          <div className='m-3 min-h-[300px]'>
            <div className='mb-10' dangerouslySetInnerHTML={{ __html: notice.content }} />
          </div>
          <div className='m-3'>
            {notice.file !== null &&
              <><b>첨부파일</b>
                <div>
                  {notice.file.map((item) => <a href={'/' + item.path} download={item.name}>{item.name}</a>)}
                </div>
              </>}
          </div>
          <div className='flex justify-center gap-3 mb-[50px]'>
            <Button
              color='sub'
              eventHandle={() => navigate('/board/notice/list')}>
              목록
            </Button>
            {
              sessionStorage.getItem('loginState/user-id') === notice.user_id && <Button
                color='gray'
                eventHandle={() => navigate('/board/qna/modify/' + seq)}>
                수정
              </Button>}
            {(sessionStorage.getItem('loginState/user-id') === notice.user_id || (user !== null && user.type === 1)) && <Button
              color='gray'
              eventHandle={deleteContent}>
              삭제
            </Button>}
          </div>
          <div className='my-[100px]'>
            <BoardCommentArea
              board='notice'
              comments={comments}
              deleteComment={deleteComment}
              submit={(comment, reply) => submit(comment, reply)} />
          </div>
        </div>
      </div>
    </div>
  )
}
