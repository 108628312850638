import { ManageAlbumProvider } from '../context/ManageAlbumContext';
import { ManageAlbumPage } from '../components/views/page/manage/ManageAlbumPage';
import { ManageBoardPage } from '../components/views/page/manage/ManageBoardPage';
import { ManagePortalPage } from '../components/views/page/manage/ManagePortalPage';
import { ManageUserPage } from '../components/views/page/manage/ManageUserPage';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PortalPage } from '../components/views/page/PortalPage';
;

export const RouteManage = ({ user }) => {

  return (
    <Routes>
      <Route path='/user' element={user === null ? <PortalPage /> : <ManageUserPage />}></Route>
      <Route path='/board' element={<ManageBoardPage />}></Route>
      <Route
        path='/album'
        element={
          <ManageAlbumProvider>
            <ManageAlbumPage />
          </ManageAlbumProvider>}>
      </Route>
      <Route path='/portal' element={<ManagePortalPage />}></Route>
    </Routes>
  )
}
