const korean_char = /[ㄱ-ㅎ|ㅏ-ㅣ]/g;
const korean = /[가-힣]/g;
const english = /[a-zA-Z]/g;
const cap = /[A-Z]/;
const regExp01 = /[\{\}\[\]\/?\.\,\;\:|\)\*\~\`\!\^\-\_\+\<\>\@\#\$\%\&\\\=\(\'\"]/g;
const regExp02 = /[\{\}\[\]\/?\.\,\;\:|\)\~\`\!\^\+\<\>\@\#\$\%\&\\\=\(\'\"]/g;
const pwExp = /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()._+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()._+=]{8,12}$/;
const phoneNumber = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/;
const telNumber = /^\d{2,3}-\d{3,4}-\d{4}$/;
const email = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
const number = /[0-9]/g;
const greekPattern = /[\u0391-\u03A9\u03B1-\u03C9]+/g;

//글자 길이 확인
export const checkLength = (text: string, min: number, max: number) => {
  if (text.length < min || text.length > max) {
    return false;
  } else {
    return true;
  }
}

//영문, 숫자, 특수문자 중 2가지 조합 8~12자리
export const checkPw = (value: string) => {
  const result = pwExp.test(value);
  return result;
}

// 특수문자가 포함되지 않았으면 true 포함됐으면 false를 반환
export const checkAllSpecialChar = (value: string) => {
  const result = regExp01.test(value);
  return !result;
};

// *, -, _을 제외한 특수문자가 포함되지 않았으면 true 포함됐으면 false를 반환
export const checkSpecialChar = (value: string) => {
  const result = regExp02.test(value)
  return !result;
};

// 숫자가 포함되지 않았으면 true 포함됐으면 false를 반환
export const checkNumber = (value: string) => {
  const result = number.test(value);
  return !result;
}

// 핸드포번호 양식이면 true, 아니면 false를 반환
export const checkPhoneNumber = (value: string) => {
  const result = phoneNumber.test(value)
  return result;
};

// 전화번호 양식이면 true, 아니면 false를 반환 (폰번호도 true)
export const checkTelephoneNumber = (value: string) => {
  const result = telNumber.test(value)
  return result;
};

// 이메일 양식이면 true, 아니면 false를 반환
export const checkEmailAdress = (value: string) => {
  const result = email.test(value)
  return result;
};

// 한글이 있으면 true, 영어가 없으면 false를 반환
export const checkKor = (value: string) => {
  const result = korean.test(value)
  return result;
};

// 대문자가 있으면 true, 대문자가 없으면 false를 반환
export const checkCap = (value: string) => {
  const result = cap.test(value)
  return result;
};

// 한글이 있으면 true, 한글이 없으면 false를 반환 (자음or모음만 입력해도 true)
export const checkKorChar = (value: string) => {
  const result = korean_char.test(value)
  return result;
};

// 영어면 true, 영어+한글 섞여있으면 false를 반환
export const checkEng = (value: string) => {
  const result = english.test(value)
  return result;
};

export const checkGreek = (text: string) => {
  const result = greekPattern.test(text);
  return result;
}

//유튜브 url인지 확인
const youtube = /^(?:https?:\/\/)?(?:www\.)?(?:(?:youtube.com\/(?:(?:watch\?v=)|(?:embed\/))([a-zA-Z0-9-_]{11}))|(?:youtu.be\/([a-zA-Z0-9-_]{11})))/;
export const checkYoutubeUrl: any = (url: string) => {
  return url.match(youtube);
}

//네이버 url확인
const naver = /^https?:\/\/tv\.naver\.com\/v\/\d+/;
export const checkNaverUrl: any = (url: string) => {
  return url.match(naver);
}

//유튜브 url에서 id 가져오기
export const getYoutubeId: any = (url: string) => {
  const match = url.match(youtube);

  const videoId = match ? match[1] || match[2] : undefined;
  return videoId;
}

//인터넷 url인지 확인하기 
export const checkUr = (url: string) => {
  const urlRegex = /^(?:https?:\/\/)(?:[\w.-]+)(?::\d{2,5})?(?:\/[\w.-]*)*(?:\?[\w%&=]*)?(?:#[\w-]*)?$/;
  return urlRegex.test(url);
}