import axios from "axios";

export const FileApi = {


  uploadFile: async (object: FormData) => {
    try {
      const res = await axios.post(`/codeenator/api/file/upload.do?user_agent=web`, object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  }
}