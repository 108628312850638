
import { Button } from "../../../../components/utils/atoms/Button";
import React, { useContext } from "react";
import { useTable } from "react-table";
import { ManageApi } from "../../../../api/manage";
import { AlertContext, ConfirmContext } from "../../../../context/ModalContext";

interface TableAttr {
  columns: any[];
  data: any[];
  get: () => void;
}

export const BoardTable = ({ get, columns, data }: TableAttr) => {
  const { openConfirm } = useContext(ConfirmContext);
  const { openAlert } = useContext(AlertContext);


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });


  const changeHandle = (val) => {
    const seq = val.original.seq;
    const state = val.original.is_secret;
    openAlert(`게시글을 ${val.original.is_secret === false ? '비활성화' : '활성화'} 하시겠습니까?`,
      () => { ManageApi.contentEnableChange(seq, { enable: state }).then(res => openConfirm(res.data.msg)).then(() => get()) });

  }
  const deleteHandle = (val) => {
    const seq = val.original.seq;
    openAlert('게시글을 삭제하시겠습니까?',
      () => { ManageApi.deleteContent(seq).then(res => openConfirm(res.data.msg)).then(() => get()) });
  }

  return (
    <table {...getTableProps()} className="w-full text-center text-sm">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="pt-3 pb-3 border-b-2 border-b-gray-300">{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                if (cell.column.id === 'title') {
                  return (
                    <td {...cell.getCellProps()} className="pr-5 pl-5 pt-2 pb-2 border-b min-w-[300px]	"
                    >{cell.render("Cell")}</td>
                  )
                }
                else if (cell.column.id === 'manage') {
                  return (
                    <td className="pr-5 pl-5 pt-2 pb-2 border-b w-[150px]">
                      <div className="flex gap-1 justify-center">
                        <Button eventHandle={() => changeHandle(row)} color="sub">{row.original.is_secret === false ? '비활성화' : '활성화'}</Button>
                        <Button eventHandle={() => deleteHandle(row)} color="sub">삭제</Button>
                      </div>
                    </td>)
                } else {
                  return (
                    <td {...cell.getCellProps()} className="pr-5 pl-5 pt-2 pb-2 border-b min-w-[100px]	"
                    >{cell.render("Cell")}</td>
                  )
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
