import axios from "axios";

export const PortalAPI = {
  getPopup: async () => {
    try {
      const res = await axios.get(`/codeenator/api/portal/getPopup.do?user_agency=web`);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  getBanner: async () => {
    try {
      const res = await axios.get(`/codeenator/api/portal/getBanner.do?user_agency=web`);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  getAlbumList: async () => {
    try {
      const res = await axios.get(`/codeenator/api/portal/getAlbumList.do?user_agency=web`);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  getNewAlbumList: async () => {
    try {
      const res = await axios.get(`/codeenator/api/portal/getNewAlbumList.do?user_agency=web`);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  getAlbumCommentList: async () => {
    try {
      const res = await axios.get(`/codeenator/api/portal/getAlbumCommentList.do?user_agency=web`);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  getAlbumCount: async () => {
    try {
      const res = await axios.get(`/codeenator/api/portal/getAlbumCount.do?user_agency=web`);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
}