
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/utils/atoms/Button';
import logo from '../../../../image/logo.png';


export const WelcomePage = () => {
  const navigate = useNavigate();
  const nickname = sessionStorage.getItem('codee-nick');
  useEffect(() => {
    nickname === null && navigate('/');
  }, [])
  return (
    <div className='text-center mt-[23vh] border w-[800px] pt-6 pb-10 shadow-md m-auto rounded'>
      <img src={logo} alt="로고" className='m-auto mb-3 h-6' />
      <h2 className='text-4xl'>회원가입이 완료되었습니다.</h2>
      <div className='text-lg mt-12 mb-8'>
        <span className=' text-[#7E38E1] text-2xl'>{nickname}</span>님 <br />
        코디네이터가 되신것을 환영합니다.<br />
        다양한 앨범을 직접 만들고 공유해보세요
      </div>
      <Button color='sub' eventHandle={() => navigate('/')}>메인화면으로 가기</Button>
      <div className='inline-block w-3'></div>
      <Button eventHandle={() => navigate('/user/login')}>로그인하기</Button>
    </div>
  )
}