import { createContext, useState } from "react";

export const CommentContext = createContext(null);
export const CommentProvider = ({ children }: any) => {
  const [commentList, setCommentList] = useState([]);

  const getCommentList = (comment) => {
    setCommentList(prev => ([...comment]));
  }

  return (
    <CommentContext.Provider value={{ commentList, getCommentList }}>
      {children}
    </CommentContext.Provider>
  )
}