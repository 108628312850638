import React, { useEffect, useState } from 'react'
import { BannerSwiper } from '../../../components/utils/molecules/BannerSwiper';
import { CategoryCard } from '../../../components/utils/atoms/CategoryCard';
import category01 from '../../../image/category-IT.png';
import category02 from '../../../image/category-personal-groth.png';
import category03 from '../../../image/category-travel.png';
import category04 from '../../../image/category-investment.png';
import category05 from '../../../image/category-hobby.png';
import category06 from '../../../image/category-language.png';
import category07 from '../../../image/category-food.png';
import category08 from '../../../image/category-etc.png';
import { PortalTitle } from '../../../components/utils/atoms/PortalTitle';
import { AlbumCard } from '../../../components/utils/atoms/AlbumCard';
import logo from '../../../image/logo.png';
import styles from './PortalPage.module.css';
import { PortalAPI } from '../../../api/portal';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/utils/atoms/Button';
import { AlbumComposition, CommentComposition } from '../../../interface/album';

interface potalComposition {
  banner: [],
  count: { listen_count: number, album_count: number },
  comment: [],
  album: [],
  newAlbum: []
}

interface PopupComposition {
  enable_popup: boolean,
  popup_width: number,
  popup_content: string,
  popup_height: number
}


export const PortalPage = () => {
  const navigate = useNavigate();

  const [potalData, setPortalData] = useState<potalComposition>({
    banner: [],
    count: { listen_count: 0, album_count: 0 },
    comment: [],
    album: [],
    newAlbum: []
  });
  const [popupLayer, setPopupLayer] = useState<PopupComposition>({
    "enable_popup": false,
    "popup_width": 0,
    "popup_content": "",
    "popup_height": 0
  });

  const setPortal = async () => {
    const album = (await PortalAPI.getAlbumList()).data.resultList;
    const newAlbum = (await PortalAPI.getNewAlbumList()).data.resultList;
    const banner = (await PortalAPI.getBanner()).data.resultList;
    const comment = (await PortalAPI.getAlbumCommentList()).data.resultList;
    const count = (await PortalAPI.getAlbumCount()).data.resultMap;

    setPortalData(prev => ({ ...prev, album, newAlbum, banner, comment, count }));
  }

  const setPopup = () => {
    const hidePopup = localStorage.getItem('hidePopup');
    if (hidePopup) {
      const hidePopupDate = new Date(hidePopup);
      if (hidePopupDate > new Date()) {
        setPopupLayer(prev => ({ ...prev, enable_popup: false }))
      } else {
        localStorage.removeItem('hidePopup');
      }
    } else {
      (PortalAPI.getPopup()).then(res => setPopupLayer(res.data.resultMap))
    }
  }

  const handleClick = (id: string) => {
    navigate('/album/view/' + id);
  }

  const showAlbumList = (param: string) => {
    navigate('/album/list/' + param);
  }

  const todayHidePopup = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    localStorage.setItem('hidePopup', tomorrow.toString());
    setPopupLayer(prev => ({ ...prev, enable_popup: false }))

  }

  useEffect(() => {
    // console.log(potalData)
    setPortal();
    setPopup();
  }, []);

  return (
    <div>
      <section className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl m-auto pt-5'>
        <BannerSwiper imageArray={potalData.banner} listView={1} />
      </section>
      <section className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl  m-auto mt-10  px-1 '>
        <PortalTitle text="베스트 앨범 구경하기" />
        <div className='flex flex-wrap overflow-hidden labtop:w-[full] labtop:h-[1627px] tablet:h-[1218px]'>
          {
            potalData.album.map((album: AlbumComposition, index) =>
              index < 8 && <AlbumCard
                img={'/codeenator/' + album.thumbnail}
                key={album.id}
                callbackFx={() => handleClick(album.id)}
                mainText={album.name}
                subText={album.user_nickname}
                mainTag={album.user_tag !== "" ? album.user_tag.split(",") : null}
                subTag={['🙍‍♀️ ' +
                  ((album.listen_count < 10) ? '10명 이하'
                    : (album.listen_count >= 10 && album.listen_count) < 50 ? '50명 이하'
                      : (album.listen_count >= 50 && album.listen_count) < 100 ? '100명 이하'
                        : (album.listen_count >= 100 && album.listen_count) < 500 ? '500명 이하'
                          : (album.listen_count >= 500 && album.listen_count) < 1000 ? '100명 이하'
                            : '1000명 이상')
                  , '💜 ' + album.recommend_count]} />
            )
          }
        </div>
      </section>
      <section className={`desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl  m-auto mt-10 laptop:mt-14`}>
        <PortalTitle text="인기 카테고리 ( IT / 취미 / 여행 )" />
        <div className='flex flex-wrap overflow-hidden labtop:w-[full] labtop:h-[1627px] tablet:h-[1218px]'>
          {
            potalData.album.map((album: AlbumComposition) =>
              album.category === ("IT" || "취미" || "여행") && <AlbumCard
                img={'/codeenator/' + album.thumbnail}
                key={album.id}
                callbackFx={() => handleClick(album.id)}
                mainText={album.name}
                subText={album.user_nickname}
                mainTag={(album.user_tag !== "") ? album.user_tag.split(",") : null}
                subTag={['🙍‍♀️ ' +
                  ((album.listen_count < 10) ? '10명 이하'
                    : (album.listen_count >= 10 && album.listen_count) < 50 ? '50명 이하'
                      : (album.listen_count >= 50 && album.listen_count) < 100 ? '100명 이하'
                        : (album.listen_count >= 100 && album.listen_count) < 500 ? '500명 이하'
                          : (album.listen_count >= 500 && album.listen_count) < 1000 ? '100명 이하'
                            : '1000명 이상')
                  , '💜 ' + album.recommend_count]} />
            )
          }
        </div>
      </section>
      <section className={`desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl  m-auto mt-10 laptop:mt-14`}>
        <PortalTitle text="신규 앨범 구경하기" />
        <div className='flex flex-wrap overflow-hidden labtop:w-[full] labtop:h-[1627px] tablet:h-[1218px]'>
          {
            potalData.newAlbum.map((album: AlbumComposition, index) =>
              index < 4 && <AlbumCard
                img={'/codeenator/' + album.thumbnail}
                key={album.id}
                callbackFx={() => handleClick(album.id)}
                mainText={album.name}
                subText={album.user_nickname}
                mainTag={(album.user_tag !== "") ? album.user_tag.split(",") : null}
                subTag={['🙍‍♀️ ' +
                  ((album.listen_count < 10) ? '10명 이하'
                    : (album.listen_count >= 10 && album.listen_count) < 50 ? '50명 이하'
                      : (album.listen_count >= 50 && album.listen_count) < 100 ? '100명 이하'
                        : (album.listen_count >= 100 && album.listen_count) < 500 ? '500명 이하'
                          : (album.listen_count >= 500 && album.listen_count) < 1000 ? '100명 이하'
                            : '1000명 이상')
                  , '💜 ' + album.recommend_count]} />
            )
          }
        </div>
      </section>
      <section className=' bg-zinc-100 pt-7 pb-7 mt-10'>
        <div className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl  m-auto pb-2 px-1'>
          <PortalTitle text="카테고리별로 확인하기" />
        </div>
        <div>
          <div className={`flex flex-wrap desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl  m-auto`}>
            <CategoryCard img={category01} handleClick={() => showAlbumList('IT')} textTop={`웹개발, 앱개발, DB,  \n보안, AI 등`} textBottom={'IT A부터 Z까지 \n배우기'} />
            <CategoryCard img={category02} handleClick={() => showAlbumList('자기계발')} textTop={'명상, 영상편집,  \n자격증 등'} textBottom={'자기계발을 통해\n마음의 양식 쌓기'} />
            <CategoryCard img={category03} handleClick={() => showAlbumList('여행')} textTop={'여행팁, 코스, 추천장소 등'} textBottom={'여행 컨텐츠 모아보기'} />
            <CategoryCard img={category04} handleClick={() => showAlbumList('경제')} textTop={'제2의 월급, 주식, 경제소식,  \n부동산 등'} textBottom={'경제지식부터 든든한 통장 만들기까지'} />
            <CategoryCard img={category05} handleClick={() => showAlbumList('취미')} textTop={'베이킹, 요리, 운동, 인테리어'} textBottom={'취미생활백서'} />
            <CategoryCard img={category06} handleClick={() => showAlbumList('외국어')} textTop={'영어, 중국어, 제2외국어 등'} textBottom={'외국어 능력 향상시키기'} />
            <CategoryCard img={category07} handleClick={() => showAlbumList('맛집')} textTop={'한식, 양식, 카페 등'} textBottom={'먹잘알들의\n맛집추천'} />
            <CategoryCard img={category08} handleClick={() => showAlbumList('기타')} textTop={''} textBottom={'기타 컨텐츠'} />
          </div>
        </div>
      </section>
      <section className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl  m-auto mt-32'>
        <img src={logo} alt='logo' className='inline-block h-6 mb-1' />에서는 지금
        <div className={styles.infoSection}>
          <div className={styles.firstDiv}>
            <p><span>{potalData.count.album_count}개</span>의 앨범이 생성되었습니다.</p>
            <p>컨텐츠가 오늘 <span> {potalData.count.listen_count === null ? '0' : potalData.count.listen_count}번</span>조회되었습니다.</p>
          </div>
          <div className={styles.secondDiv}>
            <h3 className={styles.boardTitle}>후기 모음</h3>
            <div className={`${styles.board}`}>
              {potalData.comment.map((comment: CommentComposition, index) =>
                <div className={`${styles.memo} border shadow-sm`} key={index}>
                  <p className={styles.date}>{comment.datetime}</p>
                  <p className={styles.albumTitle}>{comment.album_name}</p>
                  <p className={styles.albumComment}>{comment.comment}</p>
                  <p className={styles.user}>{comment.user_nickname} 님</p>
                </div>)}
            </div>
          </div>
        </div>
      </section>
      {
        popupLayer !== null && popupLayer.enable_popup === true &&
        <div className={`fixed py-[80px] top-[10%] right-[10%]  bg-white z-10 p-5 box-border shadow-md `} style={{ width: popupLayer.popup_width + 'px', height: popupLayer.popup_height + 'px' }}>
          <div className='h-full overflow-auto'>
            <div className='mb-10' dangerouslySetInnerHTML={{ __html: popupLayer.popup_content }} />
          </div>
          <div className='flex gap-3 justify-center pt-4'>
            <Button eventHandle={() => todayHidePopup()} color='sub'>하루동안 보지않기</Button>
            <Button eventHandle={() => setPopupLayer(prev => ({ ...prev, enable_popup: false }))}>닫기</Button>
          </div>
        </div>
      }
    </div>
  )
}
