import { createContext, useState } from "react";

export const ManageAlbumContext = createContext(null);
export const ManageAlbumProvider = ({ children }: any) => {
  const [albumList, setAlbumList] = useState([]);

  const getAlbumList = (album) => {
    setAlbumList(prev => ([...album]));
  }

  return (
    <ManageAlbumContext.Provider value={{ albumList, getAlbumList }}>
      {children}
    </ManageAlbumContext.Provider>
  )
}