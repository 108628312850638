import { SubNav } from '../../../../components/utils/molecules/SubNav';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { manageMenuArr } from './ManageUserPage';
import { AlbumTable } from '../../template/manage/AlbumTable';
import { ManageAlbumContext } from '../../../../context/ManageAlbumContext';
import { ManageApi } from '../../../../api/manage';
import { AlbumInfo } from './updateComponent/AlbumInfo';
import { PagiNation } from '../../../../components/utils/atoms/PagiNation';
import { DialogContext } from '../../../../context/ModalContext';
import Modal from 'react-modal';




export const ManageAlbumPage = () => {
  const { albumList, getAlbumList } = useContext(ManageAlbumContext);
  const [page, setPage] = useState({
    count: 1,
    currentPage: 1,
    endPage: 1,
    next: false,
    prev: false,
    startCount: 0,
    startPage: 1,
    totalPage: 1
  });

  const [id, setId] = useState(null);
  const [album, setAlbum] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const getAlbumData = async () => {
    const resultList = (await (ManageApi.getAlbumList({ page: page.currentPage }))).data.resultList;
    const resultMap = (await (ManageApi.getAlbumList({ page: page.currentPage }))).data.resultMap;
    getAlbumList(resultList);
    setPage(resultMap);
  }

  useEffect(() => {
    getAlbumData();
  }, [page.currentPage]);

  const columns = useMemo(() => columnData, []);

  const data = useMemo(() => albumList, [albumList]);

  const rejectHandle = (id) => {
    ManageApi.modifyStatus(id, { status: '등록취소' }).then(() => getAlbumData());
  }
  const approveHandle = (id) => {
    ManageApi.modifyStatus(id, { status: '등록' }).then(() => getAlbumData());
  }
  const updateHandle = (id) => {
    const getAlbum = async () => {
      const album = (await (ManageApi.getAlbum({ id }))).data.resultMap;
      setId(id);
      setAlbum(album);
      setIsOpen(true)
    }
    getAlbum();
    return;
  }

  const handlePageChange = (page) => {
    setPage((prev): any => ({ ...prev, currentPage: page }));
  }

  const deleteHandle = (id) => {
    ManageApi.deleteAlbum({ id }).then(() => getAlbumData());
  }


  return (
    <>
      <div className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl m-auto flex pt-5'>
        <SubNav menu={manageMenuArr} />
        <div className='pl-5 w-full'>
          <AlbumTable
            columns={columns}
            data={data}
            rejectHandle={rejectHandle}
            approveHandle={approveHandle}
            modifyHandle={updateHandle}
            deleteHandle={deleteHandle} />
        </div>
      </div>
      <PagiNation
        activePage={page.currentPage}
        itemsCountPerPage={10}
        totalItemsCount={page.count}
        pageRangeDisplayed={5}
        prevPageText={"‹"}
        nextPageText={"›"}
        firstPageText={"‹‹"}
        lastPageText={"››"}
        onChange={handlePageChange} />
      <Modal className='w-full absolute' isOpen={isOpen}>
        <div className='mt-[100px] w-[800px] m-auto h-[80vh] overflow-auto bg-white border p-10'>
          <AlbumInfo id={id} album={album} closeHandle={() => setIsOpen(false)} />
        </div>
      </Modal>
    </>
  )
}

const columnData = [
  {
    Header: '앨범명',
    accessor: 'name',
  },
  {
    Header: '회원',
    accessor: 'user_id',
  },
  {
    Header: '관심도',
    accessor: 'listen_count',
  },
  {
    Header: '생성일',
    accessor: 'datetime',
  },
  {
    Header: '상태',
    accessor: 'status',
  },
  {
    Header: '기능',
    accessor: 'manage',
  }
];
