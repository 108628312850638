import { Button } from '../../../../../../components/utils/atoms/Button'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { checkNaverUrl, checkYoutubeUrl } from '../../../../../../utils/RegExp'
import { VideoContent } from '../../../album/VideoContent';
import { CreateAlbumContext } from '../../../../../../context/CreateAlbumContext';
import { ConfirmContext } from '../../../../../../context/ModalContext';



export const AddVideo = ({ chapterIdx, contentIdx }: any) => {
  //다이어로그
  const { openConfirm } = useContext(ConfirmContext);
  const { contentAlbum, changeContentAlbum } = useContext(CreateAlbumContext);
  const [checkLayer, setCheckLayer] = useState(false);

  //데이터 입력
  const inputContent = (e) => {
    const { name, value } = e.target;
    const data = { ...contentAlbum };
    data.chapter[chapterIdx].content[contentIdx][name] = value;
    changeContentAlbum(data);
  }

  //url 체크
  const checkUrl = () => {
    const youtube = checkYoutubeUrl(contentAlbum.chapter[chapterIdx].content[contentIdx].content !== undefined ? contentAlbum.chapter[chapterIdx].content[contentIdx].content : contentAlbum.chapter[chapterIdx].content[contentIdx].link);
    const naver = checkNaverUrl(contentAlbum.chapter[chapterIdx].content[contentIdx].content !== undefined ? contentAlbum.chapter[chapterIdx].content[contentIdx].content : contentAlbum.chapter[chapterIdx].content[contentIdx].link);
    if (youtube === null && naver === null) {
      openConfirm('url이 올바르지 않습니다. 확인해주세요')
    } else {
      openConfirm('url을 사용하실 수 있습니다.', () => setCheckLayer(true))
      const data = { ...contentAlbum };
      data.chapter[chapterIdx].content[contentIdx].val = true;
    }
  }


  return (
    <>
      <div>
        <div className='border-b pb-2'>
          <input type="text"
            onChange={inputContent}
            className='border w-full p-1 h-10'
            placeholder='콘텐츠 제목 입력'
            name='name'
            defaultValue={contentAlbum.chapter[chapterIdx].content[contentIdx].name} />
        </div>
        <div className='flex mt-1 mb-1 gap-2 pt-1'>
          <input
            type="text"
            className='border w-full p-1'
            placeholder='동영상 페이지 url을 입력해주세요'
            onChange={inputContent}
            name='content'
            defaultValue={contentAlbum.chapter[chapterIdx].content[contentIdx].content !== undefined ? contentAlbum.chapter[chapterIdx].content[contentIdx].content : contentAlbum.chapter[chapterIdx].content[contentIdx].link} />
          <Button eventHandle={() => checkUrl()}>URL 확인</Button>
        </div>
        <p className='mt-10 mb-10 leading-8'>
          · 동영상 사이트에서 연결 링크를 복사해 옵니다 (유튜브, 네이버tv) <br />
          · URL확인을 눌러 링크를 확인해보실 수 있습니다.
        </p>
        <div className='mt-5 mb-5 text-center'>
        </div>
        <div>
        </div>
      </div>
      {
        checkLayer && <div className='fixed top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] w-[500px]'>
          <div className='w-full border bg-white'>
            <div className='m-3'>
              <div className='text-center pb-3'>미리보기</div>
              <VideoContent videoLink={contentAlbum.chapter[chapterIdx].content[contentIdx].content !== undefined ? contentAlbum.chapter[chapterIdx].content[contentIdx].content : contentAlbum.chapter[chapterIdx].content[contentIdx].link} height={'300px'} />
              <div className='m-auto text-center pt-3'>
                <Button eventHandle={() => setCheckLayer(false)} color='sub'>닫기</Button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
