import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, HashRouter } from "react-router-dom";
import { Header } from './components/utils/molecules/Header';
import { Footer } from './components/utils/molecules/Footer';
import { Router } from './routes';
import { UserProvider } from './context/UserContext';
import Modal from 'react-modal';
import { AlertProvider, DialogProvider, PromptProvider } from './context/ModalContext';
import { ConfirmProvider } from './context/ModalContext';

const App = () => {



  return (
    <HashRouter>
      <DialogProvider>
        <PromptProvider>
          <AlertProvider>
            <ConfirmProvider>
              <UserProvider>
                <Header />
                <div className='min-h-screen pt-14'>
                  <Router />
                </div>
                <Footer />
              </UserProvider>
            </ConfirmProvider>
          </AlertProvider>
        </PromptProvider>
      </DialogProvider>
    </HashRouter>
  );
}

//모달이 열렸을 때 #root의 엘리먼트를 숨겨줌
Modal.setAppElement('#root')

export default App;
