import React, { useContext, useEffect, useState } from 'react'
import { Button } from '../atoms/Button';
import { ReactComponent as HeartFilled } from '../../../image/heart-filled.svg';
import { ReactComponent as Heart } from '../../../image/heart.svg';
import { checkEmailAdress } from '../../../utils/RegExp';
import { AlbumApi } from '../../../api/album';
import { AlbumContext } from '../../../context/AlbumContext';
import { UserContext } from '../../../context/UserContext';
import { Tag } from '../atoms/Tag';
import { ConfirmContext, ModalContext } from '../../../context/ModalContext';



export type AlbumAttr = {
  info: {
    id: string,
    name: string,
    introduction: string,
    thumbnail: string,
    user_nickname: string,
    category: string,
    user_tag: string,
    chapter: any,
    enable_recommend: number
  }
}

export type ChapterAttr = {
  name: string,
  id: string,
  content: ContentTypeAttr[]
};

export type ContentTypeAttr = {
  type: number,
  name: string
}


export const AlbumInfo = ({ info }: AlbumAttr) => {

  const { openPrompt, closePrompt } = useContext(ModalContext);

  const { openConfirm } = useContext(ConfirmContext);

  const { getAlbum } = useContext(AlbumContext);
  const { user } = useContext(UserContext)



  //@Dialog
  const urlCopy = () => {
    navigator.clipboard.writeText(window.location.href);
    openConfirm('url이 복사되었습니다');
  }


  const urlEmail = () => {
    openPrompt('전송할 이메일을 입력하세요', () => sendEmail);
  }

  const sendEmail = (email) => {
    const isEmail = checkEmailAdress(email);
    const url = window.location.href;
    isEmail === false ? openConfirm() : AlbumApi.shareAlbum(info.id, { email, url }).then((res) => {
      openConfirm('해당 링크가 메일로 전송되었습니다.');
      closePrompt();
    });
  }

  const handleLike = (id: string) => {
    AlbumApi.recommendAlbum(id, { status: info.enable_recommend === 0 ? true : false }).then(res => res.data.result !== "success" ? openConfirm() : getAlbum())
  }


  return (
    <div className='flex tablet:flex-col'>
      <div className='flex-grow h-96 p-5  tablet:h-52 tablet:m-auto'>
        <img src={'/codeenator/' + info.thumbnail} alt="" className='h-full' />
      </div>
      <div className='w-3/5 p-5 relative tablet:w-full'>
        <div className='absolute top-4 right-5 cursor-pointer' onClick={() => handleLike(info.id)}>
          {user !== null ? info.enable_recommend === 0
            ? <Heart width="40" height="40" fill="red" />
            : <HeartFilled width="40" height="40" fill="red" />
            : <></>}
        </div>
        <h4 className='text-sm'>{info !== undefined && info.category}</h4>
        <h2 className='text-2xl mb-3 font-bold'>{info !== undefined && info.name}</h2>
        <p className='mb-2 max-h-[100px] overflow-auto tablet:max-h-full'>{info !== undefined && info.introduction}</p>
        <div className=' text-xl'>
          <span className='text-[#9333EA]'>{info !== undefined && info.user_nickname}</span> 코디네이터 <br />
        </div>
        <div>
          {info.user_tag !== '' ? info.user_tag.split(',').map(item => <Tag key={item} text={'# ' + item} />) : null}
        </div>
        <div className='absolute bottom-3 left-0 flex gap-3 tablet:flex-col tablet:static tablet:my-3'>
          <div>
            <Button eventHandle={urlCopy} size="wide" color='sub'>링크 복사</Button>


          </div>
          <div>
            {user !== null &&
              <Button eventHandle={urlEmail} size="wide" color="gray">링크 이메일 전송</Button>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
