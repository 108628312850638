import React, { useContext, useEffect, useRef, useState } from 'react'
import { AddFile } from './AddFile';
import { AddPDF } from './AddPDF';
import { AddText } from './AddText';
import { AddUrl } from './AddUrl';
import { AddVideo } from './AddVideo';
import { CreateAlbumContext } from '../../../../../../context/CreateAlbumContext';


export const AddContent = ({ chapterIdx, contentIdx }) => {
  const { contentAlbum, changeContentAlbum } = useContext(CreateAlbumContext);

  const handleChage = (e) => {
    const data = { ...contentAlbum }
    data.chapter[chapterIdx].content[contentIdx].type = e.target.value;

    changeContentAlbum(data);
  }


  return (
    <div className='w-[full] bg-[#eee] shadow-sm border rounded-sm top-[10vh] p-2 pt-1'>
      <div className='flex gap-4 mt-3 mb-3'>
        <div>
          <input onChange={
            (e) => handleChage(e)}
            type="radio"
            className='translate-y-[3px] mr-1'
            value={1}
            checked={contentAlbum.chapter[chapterIdx].content[contentIdx].type.toString() === '1' && true} />
          <label htmlFor="v">동영상</label>
        </div>
        <div>
          <input onChange={
            (e) => handleChage(e)}
            type="radio"
            className='translate-y-[3px] mr-1'
            value={2}
            checked={contentAlbum.chapter[chapterIdx].content[contentIdx].type.toString() === '2' && true} />
          <label htmlFor="pdf">PDF파일</label>
        </div>
        <div>
          <input onChange={
            (e) => handleChage(e)}
            type="radio"
            className='translate-y-[3px] mr-1'
            value={3}
            checked={contentAlbum.chapter[chapterIdx].content[contentIdx].type.toString() === '3' && true} />
          <label htmlFor="url">Page Link</label>
        </div>
        <div>
          <input onChange={
            (e) => handleChage(e)}
            type="radio"
            className='translate-y-[3px] mr-1'
            value={4}
            checked={contentAlbum.chapter[chapterIdx].content[contentIdx].type.toString() === '4' && true} />
          <label htmlFor="file">일반 파일</label>
        </div>
        <div>
          <input onChange={
            (e) => handleChage(e)}
            type="radio"
            className='translate-y-[3px] mr-1'
            value={5}
            checked={contentAlbum.chapter[chapterIdx].content[contentIdx].type.toString() === '5' && true} />
          <label htmlFor="text">텍스트 입력</label>
        </div>
      </div>
      <div>
        {contentAlbum.chapter[chapterIdx].content[contentIdx].type.toString() === '1' ? <AddVideo chapterIdx={chapterIdx} contentIdx={contentIdx} />
          : contentAlbum.chapter[chapterIdx].content[contentIdx].type.toString() === '2' ? <AddPDF chapterIdx={chapterIdx} contentIdx={contentIdx} />
            : contentAlbum.chapter[chapterIdx].content[contentIdx].type.toString() === '3' ? <AddUrl chapterIdx={chapterIdx} contentIdx={contentIdx} />
              : contentAlbum.chapter[chapterIdx].content[contentIdx].type.toString() === '4' ? <AddFile chapterIdx={chapterIdx} contentIdx={contentIdx} />
                : contentAlbum.chapter[chapterIdx].content[contentIdx].type.toString() === '5' && <AddText chapterIdx={chapterIdx} contentIdx={contentIdx} />}
      </div>
    </div>
  )
}
