import { checkPw } from '../../../../utils/RegExp';
import React, { useContext, useRef, useState } from 'react';
import { Button } from '../../../../components/utils/atoms/Button';
import logo from '../../../../image/logo.png';
import { Confirm } from '../../../../components/utils/atoms/Dialog';
import { UserApi } from '../../../../api/user';
import { SubNav } from '../../../../components/utils/molecules/SubNav';
import { userNavArr } from './ModifyinfoPage';
import { ConfirmContext } from '../../../../context/ModalContext';

export const ModifyPwPage = () => {
  const currentPwRef = useRef<HTMLInputElement>(null);
  const newPwRef = useRef<HTMLInputElement>(null);
  const newPwCheckRef = useRef<HTMLInputElement>(null);

  const [pwVal, setPwVal] = useState<boolean | null>(null);
  const [pwCheck, setPwCheck] = useState<boolean | null>(null);

  const { openConfirm } = useContext(ConfirmContext);



  const inputNewPw = () => {
    const new_password = newPwRef.current.value.toString();

    const isPw = checkPw(new_password);
    (isPw === false) ? setPwVal(false) : setPwVal(true);
  }

  //pw-pwChk Check
  const inputNewPwCheck = () => {
    const new_password = newPwRef.current.value.toString();
    const new_password_check = newPwCheckRef.current.value.toString();
    (new_password === new_password_check) ? setPwCheck(true) : setPwCheck(false);

  }


  const changePassword = () => {
    const current_password = currentPwRef.current.value.toString();
    const new_password = newPwRef.current.value.toString();
    (!pwVal || !pwCheck) ? openConfirm('입력값을 확인해주세요')
      : UserApi.modifyPw({ current_password, new_password })
        .then(res => {
          res.data.result ? openConfirm(res.data.msg) : openConfirm(res.data.msg);
        });
  }

  return (
    <div className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl m-auto flex pt-5'>
      <SubNav menu={userNavArr} />
      <div className='flex m-auto mt-[20vh]'>
        <div className='self-center shadow-md m-auto w-[500px] rounded bg-white align-center p-4 pt-5'>
          <div>
            <img src={logo} alt='logo' className='inline-block h-6' />
            <span className='float-right'>Password</span>
          </div>
          <div className='flex mt-3 mb-3'>
            <span className='w-[130px] leading-10'>현재 비밀번호</span>
            <input
              type="password"
              className='w-[388px] rounded border p-1 h-10'
              ref={currentPwRef} />
          </div>
          <div className='flex mt-3 mb-3'>
            <span className='w-[130px] leading-10'>새 비밀번호</span>
            <input
              type="password"
              className={`w-[388px] rounded border p-1 h-10 focus:outline-0 ${pwVal && ' border-green-700'} ${pwVal === false && ' border-red-700'}`}
              ref={newPwRef}
              onChange={inputNewPw}
              placeholder="8~12자리 영문+숫자+특수문자 중 2개 조합" />
          </div>
          <div className='flex mt-3 mb-3'>
            <span className='w-[130px] leading-10'>새 비밀번호 확인</span>
            <input
              type="password"
              className={`w-[388px] rounded border p-1 h-10 focus:outline-0 ${pwCheck && ' border-green-700'} ${pwCheck === false && ' border-red-700'}`}
              ref={newPwCheckRef}
              onChange={inputNewPwCheck}
              onKeyUp={(e) => e.key === 'Enter' && changePassword()} />
          </div>
          <div className='flex flex-col mt-10 gap-3'>
            <Button eventHandle={() => changePassword()} size="full" color='sub'>비밀번호 변경</Button>
          </div>
        </div>
      </div>
    </div>
  )
}
