import React, { useContext, useRef, useState } from 'react'
import { FileApi } from '../../../../../../api/file';
import { CreateAlbumContext } from '../../../../../../context/CreateAlbumContext';
import { ConfirmContext } from '../../../../../../context/ModalContext';

export const AddFile = ({ chapterIdx, contentIdx }: any) => {
  const { contentAlbum, changeContentAlbum } = useContext(CreateAlbumContext);
  const { openConfirm } = useContext(ConfirmContext);

  const fileRef = useRef(null);

  //파일을 가져오는 api
  const getFile = async (e) => {
    let formData: any = new FormData();
    const { files } = e.target;
    console.log(files)
    if (files && files[0]) {

      var maxSize = 10 * 1024 * 1024;
      var fileSize = files[0].size;

      if (fileSize > maxSize) {
        if (fileRef.current) {
          fileRef.current.value = '';
        }
        openConfirm("첨부파일 사이즈는 10MB 이내로 등록 가능합니다.");
        return;
      }
    }
    formData.append('file', files[0]);

    const fileData = (await FileApi.uploadFile(formData)).data.resultMap;
    const data = { ...contentAlbum };

    data.chapter[chapterIdx].content[contentIdx].file = fileData.upload_path + '/' + fileData.stored_name;
  }


  //내용 저장
  const inputContent = (e) => {
    const { name, value } = e.target;
    const data = { ...contentAlbum };
    data.chapter[chapterIdx].content[contentIdx][name] = value;
    changeContentAlbum(data);
  }



  return (
    <div>
      <div className='border-b pb-2'>
        <input type="text" className='border w-full p-1 h-10' onChange={inputContent} name='name' placeholder='콘텐츠 제목 입력' defaultValue={contentAlbum.chapter[chapterIdx].content[contentIdx].name} />
      </div>
      <div className='border-b py-2 w-full flex'>
      </div>
      <div className='border-b  mt-2 pb-2 mb-2'>
        <h5>파일 첨부</h5>
        <div>
          <input type="file" className='w-full p-1' onChange={getFile} ref={fileRef} />
        </div>
      </div>
      <p className='mt-10 mb-10 leading-8'>
        · 파일 업로드보다는 구글 드라이브나 드롭박스 사용을 추천합니다.<br />
        · 업로드 할 수 있는 파일의 크기는 20MB이하입니다.
      </p>
    </div>
  )
}
