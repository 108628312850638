import React, { useContext, useRef } from 'react'
import logo from '../../../../image/logo.png';
import { useNavigate } from 'react-router-dom';
import { UserApi } from '../../../../api/user';
import { Button } from '../../../../components/utils/atoms/Button';
import Link from '../../../../components/utils/atoms/Link';
import { ConfirmContext } from '../../../../context/ModalContext';

export const FindIdPage = () => {
  const navigate = useNavigate();
  const { openConfirm } = useContext(ConfirmContext);


  const emailRef = useRef<HTMLInputElement>(null);
  const findId = () => {

    const email = emailRef.current.value.toString();
    if (email === '') {
      openConfirm('이메일을 입력해주세요');
      return;
    };

    UserApi.findId({ email }).then(res => {
      res.data.result === 'success' ? openConfirm(res.data.msg) : openConfirm(res.data.msg);
    });

  }
  return (
    <div className='flex min-h-[80vh]'>
      <div className='self-center shadow-md m-auto w-[450px] rounded bg-white align-center p-4 pt-5'>
        <div>
          <img src={logo} alt='logo' className='inline-block h-6' />
          <span className='float-right'>Find ID</span>
        </div>
        <input type="text" className='w-full border m-1 p-1 h-10' ref={emailRef} placeholder="가입 시 등록한 이메일을 입력해주세요" />
        <div className='flex flex-col mt-5 gap-3'>
          <Button eventHandle={() => findId()} size="full" color='sub'>아이디 찾기</Button>
          <Button eventHandle={() => navigate('/user/login')} size="full" color='gray' >로그인화면으로 이동</Button>
        </div>
        <div className='flex gap-2 text-sm float-right mt-2'>
          <Link route="/user/findPw">비밀번호 찾기</Link>
        </div>
      </div>
    </div>
  )
}
