import React, { useContext, useRef, useState } from 'react'
import logo from '../../../../image/logo.png';
import { Button } from '../../../../components/utils/atoms/Button';
import { useNavigate } from 'react-router-dom';
import Link from '../../../../components/utils/atoms/Link';
import { UserApi } from '../../../../api/user';
import { checkAllSpecialChar, checkCap, checkEmailAdress, checkEng, checkKorChar, checkLength, checkNumber, checkPw } from '../../../../utils/RegExp';
import { Confirm } from '../../../../components/utils/atoms/Dialog';
import { ConfirmContext } from '../../../../context/ModalContext';

type ValidationAttr = {
  id: boolean,
  email: boolean,
  nickName: boolean,
  pw: boolean,
  pwCheck: boolean,
  terms: boolean
}

export const JoinPage = () => {
  const navigate = useNavigate();
  const { openConfirm } = useContext(ConfirmContext);


  const [validation, setValidation] = useState<ValidationAttr>({
    id: false,
    email: false,
    nickName: false,
    pw: false,
    pwCheck: false,
    terms: false
  });

  const [user, setUser] = useState({
    id: '',
    email: '',
    password: '',
    nickname: ''
  });

  //ID check
  const inputId = useRef<HTMLInputElement>(null);

  const checkId = () => {
    const id = inputId.current.value.toString();
    const iskor = checkKorChar(id);
    const isCap = checkCap(id);
    const isLength = checkLength(id, 6, 12);

    setUser((prev) => ({
      ...prev, id
    }));
    id === '' ? openConfirm('아이디를 입력해주세요.') :
      (iskor || !isLength || isCap) ? openConfirm('아이디는 6~12자리의 영문(소문자) or 영문(소문자)+숫자만 사용하실 수 있습니다.')
        : UserApi.checkId({ id }).then(res => {
          if (res.data.result === 'success') {
            setValidation((prev): any => ({
              ...prev, id: true
            }));
            openConfirm(res.data.msg);
          } else {
            openConfirm(res.data.msg);
          }
        });
  }

  //Email input
  const inputEmail = useRef<HTMLInputElement>(null);
  const [sendCipher, setSendCipher] = useState<string>('인증번호 전송');

  const checkEmail = () => {
    const email = inputEmail.current.value.toString();
    const isEmail = checkEmailAdress(email);
    setSendCipher('전송중');

    setUser((prev) => ({
      ...prev, email
    }));
    if (email === '') {
      openConfirm('이메일을 입력해주세요.');
      setSendCipher('인증번호 전송');

      return;
    }
    if (isEmail === false) {
      openConfirm('이메일 형식으로 입력해주세요.');
      setSendCipher('인증번호 전송');

      return;
    }
    setSendCipher('전송중');

    UserApi.getCipher({ email }).then(res => {
      if (res.data.result === 'success') {
        openConfirm(res.data.msg);
        setSendCipher('전송 완료');
      } else {
        openConfirm(res.data.msg);
        setSendCipher('인증번호 전송');
      }
    })
  }

  //Email check
  const inputCipher = useRef<HTMLInputElement>(null);
  const [cipher, setCipher] = useState<string>('인증번호 확인');

  const checkCipher = () => {
    const email = inputEmail.current.value.toString();
    const cipher = inputCipher.current.value.toString();
    cipher === '' ? openConfirm('인증코드를 입력해주세요.')
      : UserApi.checkCipher({ email, cipher }).then(res => {
        if (res.data.result === 'success') {
          setValidation((prev): any => ({
            ...prev, email: true,
          }));
          openConfirm(res.data.msg);
          setCipher('인증완료')
        } else {
          openConfirm(res.data.msg);
        }
      })
  }

  //nickName check
  const inputNickname = useRef<HTMLInputElement>(null);

  const checkNickname = () => {
    const nickname = inputNickname.current.value.toString();
    const isSpeChar = checkAllSpecialChar(nickname);
    const isLength = checkLength(nickname, 0, 12);

    setUser((prev) => ({
      ...prev, nickname
    }));

    nickname === '' ? openConfirm('닉네임을 입력해주세요.') :
      isSpeChar === false ? openConfirm('닉네임에는 한글/영어/숫자만 사용하실 수 있습니다.') :
        isLength === false ? openConfirm('닉네임은 12자리 이하로 설정해주세요') :
          UserApi.checkNick({ nickname }).then(res => {
            if (res.data.result === 'success') {
              setValidation((prev): any => ({
                ...prev, nickName: true
              }));
              openConfirm(res.data.msg);
            } else {
              openConfirm(res.data.msg);
            }
          });
  }

  //pw-value Check
  const inputPassword = useRef<HTMLInputElement>(null);
  const inputPasswordCheck = useRef<HTMLInputElement>(null);

  const [pwVal, setPwVal] = useState<boolean | null>(null);
  const [pwCheck, setPwCheck] = useState<boolean | null>(null);

  const inputPw = () => {
    const password = inputPassword.current.value.toString();

    const isPw = checkPw(password);
    if (isPw === false) {
      setPwVal(false);
      setValidation((prev): any => ({
        ...prev, pw: false
      }))
    } else {
      setPwVal(true);
      setValidation((prev): any => ({
        ...prev, pw: true
      }))
    }
  }

  //pw-pwChk Check
  const inputPwCheck = () => {
    const password = inputPassword.current.value.toString();
    const passwordCheck = inputPasswordCheck.current.value.toString();

    setUser((prev) => ({
      ...prev, password
    }));

    (password === passwordCheck) ? setPwCheck(true) : setPwCheck(false);
    setValidation((prev): any => ({
      ...prev, pwCheck: true
    }))
  }

  //terms
  const checkTerm = (e) => {
    const checked: boolean = e.target.checked;
    checked ? setValidation((prev): any => ({
      ...prev, terms: true
    })) : setValidation((prev): any => ({
      ...prev, terms: false
    }))
  }

  //join
  const join = () => {
    if (validation.id === false) {
      openConfirm('아이디를 확인해주세요.');
      return;
    }
    if (validation.email === false) {
      openConfirm('이메일을 확인해주세요.');
      return;
    }
    if (validation.nickName === false) {
      openConfirm('닉네임을 확인해주세요.');
      return;
    }
    if (validation.pw === false) {
      openConfirm('비밀번호를 확인해주세요.');
      return;
    }
    if (validation.terms === false) {
      openConfirm('약관에 동의해주세요.');
      return;
    }
    UserApi
      .join({ id: user.id, password: user.password, email: user.email, nickname: user.nickname })
      .then(res => {
        if (res.data.result === 'success') {
          sessionStorage.setItem('codee-nick', user.nickname);
          navigate('/user/welcome');
        } else {
          openConfirm(res.data.msg);
        }
      });
  }


  return (
    <>
      <div className='flex min-h-[80vh]'>
        <div className='self-center shadow-md border m-auto w-[550px] rounded bg-white align-center p-4 pt-5'>
          <div className='mb-10'>
            <div>
              <img src={logo} alt='logo' className='inline-block h-6' />
              <span className='float-right text-white bg-[#220053] pr-14 pl-14 '>Join</span>
            </div>
            <div className='flex mt-3 mb-3'>
              <span className='w-[130px] leading-10'>아이디</span>
              <input type="text" className='w-[250px] rounded border mr-2 p-1 h-10' ref={inputId} placeholder='6~12자리 영문or영문+숫자' />
              <div className='w-[130px]'>
                <Button eventHandle={() => { checkId() }} color="gray" size='full'>아이디 중복확인</Button>
              </div>
            </div>
            <div className='flex mt-3 mb-3'>
              <span className='w-[130px] leading-10'>E-mail</span>
              <input type="text" className='w-[250px] rounded border mr-2 p-1 h-10' ref={inputEmail} />
              <div className='w-[130px]'>
                <Button eventHandle={() => checkEmail()} color="gray" size='full'>{sendCipher}</Button>
              </div>
            </div>
            <div className='flex mt-3 mb-3'>
              <span className='w-[130px] leading-10'>인증코드</span>
              <input type="text" className='w-[250px] rounded border mr-2 p-1 h-10' ref={inputCipher} />
              <div className='w-[130px]'>
                <Button eventHandle={() => checkCipher()} color="gray" size='full'>{cipher}</Button>
              </div>
            </div>
            <div className='flex mt-3 mb-3'>
              <span className='w-[130px] leading-10'>닉네임</span>
              <input type="text" className='w-[250px] rounded border mr-2 p-1 h-10' ref={inputNickname} placeholder={'문자 or 숫자만 사용 가능합니다'} />
              <div className='w-[130px]'>
                <Button eventHandle={() => checkNickname()} color="gray" size='full'>닉네임 중복확인</Button>
              </div>
            </div>
            <div className='flex mt-3 mb-3'>
              <span className='w-[130px] leading-10'>비밀번호</span>
              <input
                type="password"
                className={`w-[388px] rounded border p-1 h-10 focus:outline-0 ${pwVal && ' border-green-700'} ${pwVal === false && ' border-red-700'}`}
                ref={inputPassword}
                onChange={inputPw}
                placeholder="8~12자리 영문+숫자+특수문자 중 2개 조합" />
            </div>
            <div className='flex mt-3 mb-3'>
              <span className='w-[130px] leading-10'>비밀번호 확인</span>
              <input
                type="password"
                className={`w-[388px] rounded border p-1 h-10 focus:outline-0 ${pwCheck && ' border-green-700'} ${pwCheck === false && ' border-red-700'}`}
                onChange={inputPwCheck}
                ref={inputPasswordCheck} />
            </div>
          </div>
          <div className='mb-10'>
            서비스 이용약관
            <div className='border h-[200px] overflow-auto mt-2 mb-2'>
              제20조(개인정보보호)  <br />
              ① 사업자는 개인정보의 수집ㆍ이용, 제공 등 개인정보의 처리 및 보호와 관련하여 「개인정보 보호법」을 준수합니다. <br />
              ② 개인정보의 열람, 정정, 처리정지 등 이용자의 권리는 「개인정보 보호법」에 따라 보호받습니다. <br /><br />

              제9조(미성년자 등의 회원가입과 법정대리인 등의 취소 등) <br />
              ① 만 19세 미만의 이용자가 법정대리인의 동의를 받지 아니하고, 사이버몰의 회원으로 가입을 한 경우에 이용자ㆍ법정대리인 또는 승계인은 사이버몰 이용계약을 취소할 수 있습니다. 다만, 「민법」 등에 따라 미성년자가 취소할 수 없는 경우에는 그러하지 않습니다.<br />
              ② 제1항본문의 경우에 사업자는 이용자의 법정대리인이 추인하기 전까지 사이버몰 이용계약을 철회할 수 있습니다. 다만, 사업자가 회원이 미성년자라는 사실을 알았을 경우에는 철회할 수 없습니다.<br /><br />

              ③ 제1항 또는 제2항에 따라 사이버몰 이용계약이 취소 또는 철회된 경우에 사업자는 회원으로부터 받은 대금을 환급하며, 위약금 등을 청구하지 않습니다. 다만, 사업자가 악의인 경우에 대금을 받은 날로부터 환급할 때까지의 기간에 대한 이자를 더하여 환급하며, 회원에게 손해가 있는 경우에 그 손해에 대해 배상하여야 합니다.<br /><br />

              ④ 제1항 또는 제2항에 따라 사이버몰 이용계약이 취소 또는 철회된 경우에 사업자는 그 회원의 정보를 지체없이 삭제합니다. 다만, 「전자상거래 등에서의 소비자보호에 관한 법률」 등에 따라 법정기간 동안 정보를 보존하여야 하는 경우에는 그러하지 않습니다.<br /><br />

              제10조(아이디 및 비밀번호 관리의무 등) ① 회원은 아이디와 비밀번호를 선량한 관리자의 주의의무로 관리하며, 제3자에게 이용허락할 수 없습니다. 다만, 사업자가 동의한 경우에는 그러하지 않습니다.<br />
              ② 제1항본문을 위반한 경우에 사업자는 회원에게 발생한 손해에 대해 책임을 지지 않습니다. 다만, 회원의 손해발생에 대해 사업자에게 책임이 있는 경우에 사업자는 그 범위내에서 책임을 부담합니다.<br /><br />

              ③ 회원은 아이디 및 비밀번호가 도용되거나 제3자에 의해 사용되고 있음을 알게 된 경우에 즉시 사업자에게 그 사실을 통지하고, 사업자의 안내에 따라야 합니다.<br /><br />

              ④ 제3항의 경우, 회원이 사업자에게 그 사실을 통지하지 않거나 통지한 경우에도 사업자의 안내에 따르지 않아 발생한 회원의 손해에 대해 사업자는 책임을 지지 않습니다. 다만, 사업자에게 책임있는 경우에는 그 범위내에서 책임을 부담합니다.<br /><br />

              ⑤ 회원은 도용 등을 방지하기 위해 주기적으로 비밀번호를 변경하며, 사업자는 회원에게 비밀번호의 변경을 권고할 수 있습니다.<br /><br />

              제11조(회원에 대한 통지) ① 개별 회원에 대한 통지를 하는 경우, 사업자는 회원이 지정한 전자우편주소로 그 내용을 전송합니다.<br />
              ② 전체 회원에게 통지할 경우, 사업자는 회원이 쉽게 알 수 있도록 사이버몰의 게시판 등에 7일 이상 그 내용을 게시합니다. 다만, 회원의 사이버몰 이용계약과 관련하여 영향을 미치는 사항에 대하여는 제1항에 따른 통지를 합니다.<br /><br />

              ③ 제1항 및 제2항단서에도 불구하고, 회원이 지정한 전자우편주소가 없는 경우 또는 회원이 지정한 전자우편주소로 통지할 수 없는 경우에 사업자는 제2항본문에서 정한 방법으로 통지할 수 있습니다. 이 경우, 사업자가 회원의 전화번호를 알고 있는 때에는 그 내용을 확인할 수 있는 방법을 전화 또는 문자로 안내합니다.
            </div>
            <input type="checkbox" name="term" id="term" className=' translate-y-[1px]' onChange={checkTerm} />
            <label htmlFor="term" className='p-2'>상기 약관에 동의합니다(필수)</label>
          </div>
          <div className='flex mb-3 flex-col mt-10 gap-3'>
            <Button eventHandle={() => join()} size="full" color='sub' >회원가입</Button>
          </div>
        </div>
      </div>
      {/* <Confirm msg='해당 아이디는 사용 가능합니다' closeHandle={() => { }} /> */}
    </>
  )
}
