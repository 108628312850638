import React from 'react';
import logo from '../../../../image/logo.png';
import contents from '../../../../image/contents.png';
import album from '../../../../image/album.png';
import arrow from '../../../../image/Arrow-white.png';
import img01 from '../../../../image/intro-img-01.png';
import img02 from '../../../../image/intro-img-02.png';
import img03 from '../../../../image/intro-img-03.png';
import img04 from '../../../../image/intro-img-04.png';

export const IntroducePage = () => {
  return (
    <>
      <section>
        <h2 className='font-semibold text-lg text-center mt-5 mb-3'>
          What is
          <img src={logo} alt='logo' className=' inline-block h-7' />
          ?
        </h2>
        <div className='bg-[#7E38E1] h-[300px] py-8 tablet:h-[350px]'>
          <div className='text-white text-center'>
            Codeenator는 <br className='hidden tablet:block' />인터넷에 공유된 <br className='hidden tablet:block' />다양한 형식의 컨텐츠(유튜브, 블로그, 파일)를 <br />
            원하는 주제로 묶어서<br className='hidden tablet:block' /> 앨범을 만들어 공유하는 서비스입니다.
          </div>
          <div className='flex gap-3 my-7 justify-center items-center'>
            <img src={contents} alt="이미지" className='tablet:w-[120px]' />
            <img src={arrow} alt="화살표" className='h-6 mr-3' />
            <img src={album} alt="이미지" className='tablet:w-[120px]' />
          </div>
        </div>
      </section>
      <section>
        <div className='desktop:max-w-7xl m-auto mt-12 text-center text-xl'>
          Coordinate : 조직화하다, 편성하다.<br />
          +<br />
          Code : 성향을 뜻하는 한국식 표현<br /><br />
          =<br />
          Codeenator란 ? <br />
          의미나 맥락이 맞는 컨텐츠를 앨범처럼 엮어낸다는 의미!<br />
        </div>

      </section>
      <section>
        <div className='desktop:max-w-7xl flex gap-12 m-auto mt-12 text-center tablet:flex-col'>
          <div className='flex-1 border-[2px] border-[#7e38e1] border-dashed  p-4 rounded-md'>
            <img src={img01} alt='이미지' className='m-auto' />
            <h3 className='text-xl'>참여와 이용</h3>
            <p>소비자로써 앨범을 열람하고, 공급자로써 앨범을 출판</p>
          </div>
          <div className='flex-1 border-[2px] border-[#7e38e1] border-dashed p-4 rounded-md'>
            <img src={img02} alt='이미지' className='m-auto my-14' />
            <h3 className='text-xl'>링크와 생성</h3>
            <p>기존 컨텐츠를 연결하고 새롭게 작성된 컨텐츠를 조합</p>
          </div>
          <div className='flex-1 border-[2px] border-[#7e38e1] border-dashed p-4 rounded-md'>
            <img src={img03} alt='이미지' className='m-auto my-2' />
            <h3 className='text-xl'>열람/공유 Free</h3>
            <p>자유로운 앨범 보기와 만들기</p>
          </div>
        </div>
      </section>
      <section>
        <div className='desktop:max-w-7xl gap-16 m-auto my-[150px] text-center'>
          <h3 className='text-xl'>이렇게 이용할 수 있어요!</h3>
          <div className='flex tablet:flex-col'>
            <div className='flex-1'>
              <img src={img04} alt="이미지" className=' inline-block' />
            </div>
            <div className='flex-1'>
              <div className='flex flex-col text-left'>
                <div className=' mt-10 target:m-2 p-2'>
                  <h3 className='text-lg tablet:text-center tablet:mb-2'>앨범 펼치기</h3>
                  <p>
                    보고싶은 앨범을 찾아 클릭하면 누구나 앨범 내 컨텐츠를 볼 수 있습니다
                    원하는 주제에 맞는 자료를 동영상, 문서, pdf, 파일, 텍스트 등의 다양한 컨텐츠로 즐길 수 있습니다.
                    컨텐츠별로 챕터와 책갈피가 있어 좀더 편리하게 이용할 수 있습니다.
                    웹 브라우저는 물론 모바일 앱을 통해서도 플레이 할 수 있습니다.
                  </p>
                </div>
                <div className=' mt-10 target:m-2 p-2'>
                  <h3 className='text-lg tablet:text-center tablet:mb-2'>앨범 만들기</h3>
                  <p>
                    앨범 만들기는 회원에게만 오픈되는 메뉴입니다.
                    이미 존재하는 다양한 콘텐츠들과 나의 의견을 엮어서 하나의 앨범을 만들어 정보를 전달할 수 있습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
