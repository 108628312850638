import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from '../../../../../components/utils/atoms/Button';
import { Title } from '../../../../../components/utils/atoms/Title';
import { categorys } from '../../../../../utils/variable';
import { CategoryItem } from '../../../../../components/utils/atoms/CategoryItem';
import { FileApi } from '../../../../../api/file';
import { ManageApi } from '../../../../../api/manage';
import { ConfirmContext } from '../../../../../context/ModalContext';

export const AlbumInfo = ({ id, album, closeHandle }) => {

  const { openConfirm } = useContext(ConfirmContext);

  const inputName = useRef<HTMLInputElement>(null);
  const inputIntroduction = useRef<HTMLTextAreaElement>(null);
  const [category, setCategory] = useState<string>('');
  const userTag = useRef<HTMLInputElement>(null);
  const adminTag = useRef<HTMLInputElement>(null);

  const [file, setFIle] = useState('');

  const submit = () => {
    const name = inputName.current.value.toString();
    const introduction = inputIntroduction.current.value.toString();
    const user_tag = userTag.current.value.toString();
    const admin_tag = adminTag.current.value.toString();


    ManageApi.modifyAlbum(id, { name, introduction, category, user_tag, admin_tag, thumbnail: file });
    closeHandle();
    openConfirm('앨범정보가 수정되었습니다.');
  }

  const getThumbnail = (e) => {
    let formData: any = new FormData();
    const { files } = e.target;
    formData.append('file', files[0]);
    FileApi.uploadFile(formData).then(res => {
      setFIle(res.data.resultMap.path);
    });

  }

  useEffect(() => {
    album !== null && setCategory(album.category);
    album !== null && setFIle(album.thumbnail);
  }, [album])



  return (
    <>
      <Title>앨범 정보</Title>
      <div className='p-3'>
        <h3 className='mt-5 mb-2'>앨범 제목</h3>
        <input type="text" className='border w-full h-10 p-2' ref={inputName} maxLength={100} placeholder={'최대 100글자'} defaultValue={album !== null ? album.name : ''} />
      </div>
      <div className='p-3 mt-5'>
        <h3 className='mb-2'>앨범 소개</h3>
        <textarea ref={inputIntroduction} rows={2} className='border resize-none w-full h-20 p-2' maxLength={500} placeholder='최대 500글자' defaultValue={album !== null ? album.introduction : ''} ></textarea>
      </div>
      <div className='p-3 mt-5 flex'>
        <div className='w-2/3'>
          <h3 className='mb-2'>썸네일</h3>
          <input type="file" onChange={(e) => getThumbnail(e)} />
          {file !== '' && <p>{file}</p>}
        </div>
        <div className='border  w-1/3  h-44'>
          {file !== '' && <img src={'/codeenator/' + file} alt="" className='w-full  h-44' />}
        </div>
      </div>
      <div className='p-3 mt-5'>
        <h3 className='mb-2'>카테고리</h3>
        <div>
          {categorys.map((item) => <CategoryItem clickItem={() => setCategory(item)} selected={category}>{item}</CategoryItem>)}
        </div>
      </div>
      <div className='p-3 mt-5'>
        <h3 className='mb-2'>태그</h3>
        <input type="text" className='border resize-none w-full h-10 p-2' ref={userTag} placeholder={'ex) 트랜드,취미,KPOP '} defaultValue={album !== null ? album.user_tag : ''} />
      </div>
      <div className='p-3 mt-5'>
        <h3 className='mb-2'>서비스태그</h3>
        <input type="text" className='border resize-none w-full h-10 p-2' ref={adminTag} placeholder={'ex) 트랜드,취미,KPOP '} defaultValue={album !== null ? album.admin_tag : ''} />
      </div>
      <div className='mt-24 text-center'>
        <Button eventHandle={() => submit()} color="sub">수정</Button>
      </div>
    </>
  )
}
