
import { Button } from "../../../../components/utils/atoms/Button";
import React from "react";
import { useTable } from "react-table";

interface TableAttr {
  columns: any[];
  data: any[];
  submitHandle: (val, seq) => void;
  editHandle: (text, val, seq) => void;
}

export const CommentTable = ({ columns, data, submitHandle, editHandle }: TableAttr) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const submit = (val) => {
    submitHandle(val.row.original.album, val.row.original.seq);
  }
  const editSubmit = (val) => {
    editHandle(val.row.original.reply.comment, val.row.original.album, val.row.original.reply.seq);
  }


  return (
    <table {...getTableProps()} className="w-full text-center text-md">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="pt-3 pb-3 border-b-2 border-b-gray-300">{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                if (cell.column.id === 'reply.datetime') {
                  return (
                    <td className="pr-5 pl-5 pt-2 pb-2 border-b">
                      {cell.value === undefined ?
                        <Button
                          eventHandle={() => submit(cell)}
                          color="sub">답글 등록</Button> :
                        <Button
                          eventHandle={() => editSubmit(cell)}
                          color="sub">답글 편집</Button>}
                    </td>)
                } else {
                  return (
                    <td {...cell.getCellProps()} className="pr-5 pl-5 pt-2 pb-2 border-b min-w-[150px]	"
                    >{cell.render("Cell")}</td>
                  )
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
