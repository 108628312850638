import React, { useRef } from 'react'
import { Button } from './Button';

type textinputAttr = {
  seq?: number,
  submit: (text, seq?) => void;
}


export const InputCommnet = ({ seq, submit }: textinputAttr) => {
  const inputComment = useRef<HTMLTextAreaElement>(null);
  const handleSubmit = () => {
    submit(inputComment.current.value.toString(), seq);
    inputComment.current.value = '';
  }

  return (
    <>
      <div className='gap-3 overflow-hidden'>
        <textarea
          ref={inputComment}
          rows={3}
          className="resize-none p-2 border w-full"></textarea>
        <div className='float-right'>
          <Button eventHandle={handleSubmit} color='sub'>등록</Button>
        </div>
      </div>
    </>
  )
}
