import React from 'react'
import Link from './Link'

export const Filter = ({ category, propsHandle }) => {


  const onFilter = (value: string | number) => {
    propsHandle(value);
  }

  return (
    <div className='float-left'>
      <ul>
        {category.map((item) => (
          <li key={item.value} className='float-left m-2 cursor-pointer' onClick={() => onFilter(item.value)}>{item.title}</li>
        ))}
      </ul>
    </div>
  )
}
