import React, { useContext, useEffect, useRef, useState } from 'react';
import { FileApi } from '../../../../../api/file';
import { Button } from '../../../../../components/utils/atoms/Button';
import { CategoryItem } from '../../../../../components/utils/atoms/CategoryItem';
import { Confirm } from '../../../../../components/utils/atoms/Dialog';
import { Title } from '../../../../../components/utils/atoms/Title';
import { CreateAlbumContext } from '../../../../../context/CreateAlbumContext';
import { categorys } from '../../../../../utils/variable';
import { ConfirmContext } from '../../../../../context/ModalContext';
import { checkGreek } from '../../../../../utils/RegExp';
import styles from './AlbumInfo.module.css';

export const AlbumInfo = ({ privateKey }) => {

  const { contentAlbum, changeInfo, changeStep } = useContext(CreateAlbumContext);
  const { openConfirm } = useContext(ConfirmContext);



  const inputName = useRef<HTMLInputElement>(null);
  const inputIntroduction = useRef<HTMLTextAreaElement>(null);
  const inputTag = useRef<HTMLInputElement>(null);
  const [category, setCategory] = useState<string>('');
  const [tag, setTag] = useState<string>(contentAlbum.tag !== null ? contentAlbum.tag : '');

  const [file, setFile] = useState({ upload: null, temp: null });


  //태그상태 체크
  const checkText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const match = e.target.value.match(/,{2,}/g);

    //,과 /를 제외한 특수문자가 들어가면 입력을 막음
    if (/^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\s|,|/]*$/.test(e.target.value) === false || match) {
      return;
    }
    else {
      //띄어쓰기나 /가 입력되는 경우 쉼표로 변경
      const value = e.target.value.replace(/(\/|\s)+/g, ',');
      const finalValue = value.replace(/^,/, "");

      //맨 첫글자가 ,면 지움
      if (value[0] === ',') {
        setTag((prevValue) => prevValue.slice(1));
        return;
      }
      const count = finalValue.split(',').length - 1;


      if (count > 2) {
        return;
      }
      setTag(prev => finalValue);
    }
  }

  const [pk, setPk] = useState('');
  const [selectedValue, setSelectedValue] = useState('public');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);

  };

  useEffect(() => {
    console.log(privateKey)
    setSelectedValue(privateKey === '' ? 'public' : 'private');
    setPk(privateKey);
  }, [privateKey])


  useEffect(() => {
    if (selectedValue === 'public') {
      setPk('');
    }
  }, [selectedValue]);

  const generateAlphaNumericCode = () => {
    if (selectedValue === 'private') {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let code = '';
      for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        code += characters[randomIndex];
      }
      setPk(code);
    }
  }

  const goNextStep = () => {
    const name = inputName.current.value.toString();
    const introduction = inputIntroduction.current.value.toString();
    const tag = inputTag.current.value.toString();

    if (selectedValue === "private" && pk === '') {
      openConfirm('키를 발급해주세요');
      return;
    }

    if (name !== '' && introduction !== '' && category !== '') {
      if (checkGreek(name) || checkGreek(introduction) || checkGreek(category)) {
        openConfirm('그리스문자는 입력이 불가합니다.');
        return;
      }
      changeInfo({ name, introduction, thumbnail: file.upload, category, tag, pk: pk });
      changeStep(2);
    }
    else {
      openConfirm('제목, 소개글, 카테고리는 필수요소입니다. ');
    }
  }

  const getThumbnail = (e) => {
    if (e.target.files[0] === undefined) {
      return;
    }
    let formData: any = new FormData();
    const { files } = e.target;
    formData.append('file', files[0]);
    FileApi.uploadFile(formData).then(res => {
      res.data.result === 'error' ? openConfirm('해당 썸네일은 사용하실 수 없습니다. 사진 크기는 10MB로 제한됩니다.') : setFile((pre) => ({ ...pre, upload: res.data.resultMap.upload_path + '/' + res.data.resultMap.stored_name, temp: res.data.resultMap.temp_path + '/' + res.data.resultMap.stored_name }));
    });

  }


  useEffect(() => {
    contentAlbum.category !== '' && setCategory(contentAlbum.category);
    contentAlbum.thumbnail !== '' && setFile({ ...file, upload: contentAlbum.thumbnail });
    contentAlbum.tag !== '' && setTag(contentAlbum.user_tag);
    console.log(contentAlbum)
  }, [contentAlbum])



  return (
    <div className='relative'>
      <Title>앨범 정보</Title>
      <div className='p-3'>
        <h3 className='mt-5 mb-2'>앨범 제목</h3>
        <input type="text"
          className='border w-full h-10 p-2'
          ref={inputName}
          maxLength={100}
          placeholder={'최대 100글자'}
          defaultValue={contentAlbum !== null ? contentAlbum.name : ''} />
      </div>
      <div className='p-3 mt-5'>
        <h3 className='mb-2'>앨범 소개</h3>
        <textarea
          ref={inputIntroduction}
          rows={2}
          className='border resize-none w-full h-20 p-2'
          maxLength={500} placeholder='최대 500글자'
          defaultValue={contentAlbum !== null ? contentAlbum.introduction : ''} ></textarea>
      </div>
      <div className='p-3 mt-5 flex'>
        <div className='w-2/3'>
          <h3 className='mb-2'>썸네일</h3>
          <input type="file" onChange={(e: React.ChangeEvent<HTMLInputElement>) => getThumbnail(e)} />
        </div>
        <div className='border  w-1/3  h-44'>
          {(file.temp === null && file.upload === null)
            ? <></>
            : (file.temp !== null && file.temp !== undefined)
              ? <img src={'/codeenator' + file.temp} alt="" className='w-full  h-44' />
              : (file.temp === null && file.upload !== null)
              && <img src={'/codeenator' + file.upload} alt="" className='w-full  h-44' />
          }
        </div>
      </div>
      <div className='p-3 mt-5'>
        <h3 className='mb-2'>카테고리</h3>
        <div>
          {categorys.map((item) =>
            <CategoryItem key={item} clickItem={() => setCategory(item)} selected={category}>{item}</CategoryItem>)}
        </div>
      </div>
      <div className='p-3 mt-5'>
        <h3 className='mb-2'>태그(최대 3개)</h3>
        <input type="text"
          ref={inputTag}
          className='border resize-none w-full h-10 p-2'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => checkText(e)} placeholder={'ex)  트랜드,취미,KPOP '}
          defaultValue={contentAlbum !== null ? contentAlbum.user_tag : ''} />
      </div>
      <div className='p-3 mt-5'>
        <h3 className='mb-2'>공개여부 (비공개 선택 시 key를 발급해주세요)</h3>
        <label><input type="radio" value="public" onChange={handleChange} name='auth' className={`mr-2 ${styles.radioButton}`} checked={selectedValue === 'public'} />공개</label>
        <label><input type="radio" value="private" onChange={handleChange} name='auth' className={`ml-5 mr-2 ${styles.radioButton}`} checked={selectedValue === 'private'} />비공개</label>
        <button className={styles.keyButton} onClick={generateAlphaNumericCode}>key  발급</button>
        <div>{selectedValue === 'private' && `발급 키 :  ${pk}`}</div>
      </div>
      <div className='mt-24 text-center '>
        <Button eventHandle={() => goNextStep()} color='sub'>저장 후 다음 이동</Button>
      </div>
    </div>
  )
}
