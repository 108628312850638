import React, { ReactNode } from 'react';
import styles from './Title.module.css';

type TitleAttr = {
  children: ReactNode;
}

export const Title = ({ children }: TitleAttr) => {
  return (
    <div className={styles.box}>{children}</div>
  )
}
