import { SubNav } from '../../../../components/utils/molecules/SubNav';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { menuArr } from './MyAlbumListPage';
import { CommentTable } from '../../template/myContents/CommentTable';
import { AlbumApi } from '../../../../api/album';
import { Prompt, PromptEdit } from '../../../../components/utils/atoms/Dialog';
import { CommentContext } from '../../../../context/CommentContext';
import { PagiNation } from '../../../../components/utils/atoms/PagiNation';
import { ModalContext } from '../../../../context/ModalContext';


export const MyCommentListPage = () => {
  const { commentList, getCommentList } = useContext(CommentContext);
  const [changeState, setChangeState] = useState(false);
  const [page, setPage] = useState({
    count: 1,
    currentPage: 1,
    endPage: 1,
    next: false,
    prev: false,
    startCount: 0,
    startPage: 1,
    totalPage: 1
  });

  const { openPrompt } = useContext(ModalContext);


  const getComment = async () => {
    const commentRes = (await (AlbumApi.getMyAlbumCommentList(page.currentPage))).data.resultList;
    (getCommentList(commentRes));
    setChangeState(false);
  }

  useEffect(() => {
    getComment();
  }, [changeState]);

  //@Dialog
  const openPromptBox = (message: string, id: string, seq: number, submit?: () => void) => {
    openPrompt(message, (text) => submitReply(text, id, seq))

    return;
  }



  const openEditPromptBox = (message: string, text: string, id: string, seq: number, callback?: () => void,) => {
    openPrompt(message, (text) => editReply(text, id, seq), () => deleteReply(seq))
    return;
  }

  const columnData = [
    {
      Header: '앨범명',
      accessor: 'album_name',
    },
    {
      Header: '닉네임',
      accessor: 'user_nickname',
    },
    {
      Header: '등록일',
      accessor: 'datetime',
    },
    {
      Header: '답글',
      accessor: 'comment',
    },
    {
      Header: '내 답글',
      accessor: 'reply.comment',
    },
    {
      Header: '기능',
      accessor: 'reply.datetime',
    },
  ];

  const columns = useMemo(() => columnData, []);

  const data = useMemo(() => commentList, [commentList]);

  const submitHandle = (id, seq) => {
    openPromptBox('답글 등록', id, seq);
    setChangeState(true);
  };

  const submitReply = (text, id, seq) => {
    AlbumApi.setComment(id, { comment: text, reply: seq });
    setChangeState(true);
  }

  const deleteReply = (seq) => {
    AlbumApi.deleteComment(seq);
    setChangeState(true);
  }

  const editHandle = (text, id, seq) => {
    openEditPromptBox('답글상태', text, id, seq);
    setChangeState(true);
  };

  const editReply = (text, id, seq) => {
    AlbumApi.modifyComment(seq, { comment: text });
    setChangeState(true);
  }
  const handlePageChange = (page) => {
    setPage(prev => ({ ...prev, currentPage: page }))
  }

  return (
    <>
      <div className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl m-auto flex pt-5'>
        <SubNav menu={menuArr} />
        <div className='pl-5 w-full'>
          <CommentTable
            columns={columns}
            data={data}
            submitHandle={(id, seq,) => submitHandle(id, seq)}
            editHandle={editHandle} />
        </div>
      </div>
      <PagiNation
        activePage={page.currentPage}
        itemsCountPerPage={20}
        totalItemsCount={page.count}
        pageRangeDisplayed={5}
        prevPageText={"‹"}
        nextPageText={"›"}
        firstPageText={"‹‹"}
        lastPageText={"››"}
        onChange={handlePageChange} />
    </>
  )
}
