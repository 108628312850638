import { EditorComponent } from '../../../../../../components/utils/atoms/Editor'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { CreateAlbumContext } from '../../../../../../context/CreateAlbumContext';

export const AddText = ({ chapterIdx, contentIdx }: any) => {
  const { contentAlbum, changeContentAlbum } = useContext(CreateAlbumContext);

  const handleChange = (text: string) => {
    const data = { ...contentAlbum };
    data.chapter[chapterIdx].content[contentIdx].content = text;
    changeContentAlbum(data);
  }

  //내용 저장
  const inputContent = (e) => {
    const { name, value } = e.target;
    const data = { ...contentAlbum };
    data.chapter[chapterIdx].content[contentIdx][name] = value;
    changeContentAlbum(data);
  }


  return (
    <>
      <div className='border-b pb-2'>
        <input
          type="text"
          className='border w-full p-1 h-10'
          placeholder='콘텐츠 제목 입력'
          onChange={inputContent}
          name="name"
          defaultValue={contentAlbum.chapter[chapterIdx].content[contentIdx].name} />
      </div>
      <div className='h-[300px] bg-white'>
        <EditorComponent
          content={contentAlbum.chapter[chapterIdx].content[contentIdx].content !== undefined ? contentAlbum.chapter[chapterIdx].content[contentIdx].content : contentAlbum.chapter[chapterIdx].content[contentIdx].link}
          defaultValue={contentAlbum.chapter[chapterIdx].content[contentIdx].content !== undefined ? contentAlbum.chapter[chapterIdx].content[contentIdx].content : contentAlbum.chapter[chapterIdx].content[contentIdx].link}
          onChage={handleChange} />
      </div>
    </>
  )
}
