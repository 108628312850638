import axios from "axios";

export const AlbumApi = {
  getAlbumList: async (params: {
    page?: number | null,
    category?: string | null,
    sort_option?: number | null,
    search_option?: number | null,
    search_text?: string | null,
  }) => {
    try {
      const res = await axios.get(`/codeenator/api/album/getAlbumList.do?user_agent=web`, { params });
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  getListenAlbumList: async () => {
    try {
      const res = await axios.get(`/codeenator/api/album/getListenAlbumList.do?user_agent=web`);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  setAlbum: async (object: { name: string, introduction: string, chapter: any[], thumbnail: any, category: string, tag: string }) => {
    try {
      const res = await axios.post(`/codeenator/api/album/setAlbum.do?user_agent=web`, object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  modifyAlbum: async (id: string, object: { name: string, introduction: string, chapter: any[], thumbnail: any, category: string, tag: string }) => {
    try {
      const res = await axios.post(`/codeenator/api/album/modifyAlbum.do?user_agent=web&id=${id}`, object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  getAlbum: async (params: { id: string, pk?: string }) => {
    try {
      const res = await axios.get(`/codeenator/api/album/getAlbum.do?user_agent=web`, { params });
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  getContent: async (params: { id: string }) => {
    try {
      const res = await axios.get(`/codeenator/api/album/getContent.do?user_agent=web`, { params });
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  shareAlbum: async (id, object: { email: string, url: string }) => {
    try {
      const res = await axios.post(`/codeenator/api/album/shareLink.do?id=${id}&user_agent=web`, object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  recommendAlbum: async (id: string, object: {
    status: boolean
  }) => {
    try {
      const res = await axios.post(`/codeenator/api/album/recommendAlbum.do?user_agent=web&id=${id}`, object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  getMyAlbumList: async (params: {
    user_agent: string,
    page?: number | null,
    sort_option?: number | null,
    filter?: '등록취소' | '검토중' | '등록' | null,
  }) => {
    try {
      const res = await axios.get(`/codeenator/api/album/getMyAlbumList.do`, { params });
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  deleteAlbum: async (params: {
    id: string
  }) => {
    try {
      const res = await axios.get(`/codeenator/api/album/deleteAlbum.do?user_agent=web`, { params });
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  getComment: async (params: {
    album: string,
    page: number,
  }) => {
    try {
      const res = await axios.get(`/codeenator/api/album/getCommentList.do?user_agent=web`, { params });
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  setComment: async (id: string, object: {
    comment: string,
    reply?: string,
  }) => {
    try {
      const res = await axios.post(`/codeenator/api/album/setComment.do?user_agent=web&album=${id}`, object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  modifyComment: async (seq: number, object: {
    comment: string,
  }) => {
    try {
      const res = await axios.post(`/codeenator/api/album/modifyComment.do?user_agent=web&seq=${seq}`, object);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
  deleteComment: async (seq: number) => {
    try {
      const res = await axios.get(`/codeenator/api/album/deleteComment.do?user_agent=web&seq=${seq}`);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  getMyAlbumCommentList: async (page?: number) => {
    try {
      const res = await axios.get(`/codeenator/api/album/getMyAlbumCommentList.do?user_agent=web&page=${page}`);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },

  deleteListenAlbum: async (id?: string) => {
    try {
      const res = await axios.get(`/codeenator/api/album/deleteListenAlbum.do?user_agent=web&id=${id}`);
      return res;
    } catch (error) {
      console.log(`에러 발생 : ${error}`);
    }
  },
}