import React from 'react';
import styles from './LoadingSpinner.module.css';



export const LoadingSpinner = () => {
  return (
    <div className={styles.loading}>
      <div className={styles.spinner}></div>
    </div>
  )
}
