import { Title } from '../../../../../components/utils/atoms/Title'
import { SubNav } from '../../../../../components/utils/molecules/SubNav'
import React, { useContext, useRef, useState } from 'react'
import { Button } from '../../../../../components/utils/atoms/Button'
import { boardMenuArr } from '../notice/NoticeListPage'
import { EditorComponent } from '../../../../../components/utils/atoms/Editor'
import { useNavigate } from 'react-router-dom'
import { BoardApi } from '../../../../../api/board'
import { FileApi } from '../../../../../api/file'
import { AlertContext } from '../../../../../context/ModalContext'


export const QnaWritePage = () => {

  const inputTitle = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { openAlert } = useContext(AlertContext);

  const [content, setContent] = useState('');
  const handleChange = (content: any) => {
    setContent(content);
  }
  const [files, setFIles] = useState([]);

  const submit = () => {
    openAlert('게시글을 등록하시겠습니까?', submitContent);
  }

  const submitContent = () => {
    const title = inputTitle.current.value.toString();
    const fileArr = files.map(file => file.path);
    BoardApi.setQuestion({ title, content, file: fileArr });
    navigate('/board/qna/list')
  }

  const getFile = (e) => {
    let formData: any = new FormData();
    const { files } = e.target;
    formData.append('file', files[0]);
    FileApi.uploadFile(formData).then(res => {
      setFIles(prev => ([...prev, res.data.resultMap]));
    });
  }

  return (
    <>
      <div className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl m-auto flex pt-5'>
        <SubNav menu={boardMenuArr} />
        <div className='pl-5 w-full'>
          <Title>QnA</Title>
          <input type="text" className=' border w-full my-4 h-10 rounded p-1' placeholder='제목을 입력하세요' ref={inputTitle} />
          <div className=' h-[400px]'>
            <EditorComponent content={content} onChage={handleChange} />
          </div>
          <div className='flex gap-10'>
            <h4>파일 첨부</h4>
            <div>
              <input type="file" onChange={(e) => getFile(e)} />
              {files.length !== 0 &&
                <ul className='mt-3 h-[50px] overflow-y-auto'>
                  {files.map(file => <li className='float-left mr-5 cursor-pointer'>{file.name}</li>)}
                </ul>}
            </div>
          </div>
          <div className='text-center mt-5'>
            <Button eventHandle={submit} color="sub" >질문 작성</Button>
          </div>
        </div>
      </div>
    </>
  )
}
