import { Button } from '../../../../components/utils/atoms/Button';
import React from 'react';

export type VideoAttr = {
  urlLink: string;
}
export const URLContent = ({ urlLink }: VideoAttr) => {

  return (
    <>

      <div className='m-auto text-center'>
        <h3 className='text-xl'>새 탭에서 링크 열기<br /><br /></h3>
        <div className=' break-all'>
          {urlLink}
        </div>
      </div >
      <div className='mt-5 text-center'>
        <Button eventHandle={() => window.open(urlLink)} color="sub">go</Button>
      </div>
    </>
  )
}
