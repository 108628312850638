import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BoardApi } from "../api/board";

export const BoardContext = createContext(null);
export const BoardProvider = ({ children }: any) => {

  const { seq } = useParams();

  //qna
  const [qnaContent, setQnaContent] = useState(null);
  const [qnaCommentList, setQnaCommentList] = useState([]);


  const getQnaContent = async () => {
    const updatedContent = (await (BoardApi.getQuestion({ seq }))).data.resultMap;
    const updatedComment = (await (BoardApi.getComment({ content: seq }))).data.resultList;
    return { content: updatedContent, comment: updatedComment }
  }

  useEffect(() => {
    const data = getQnaContent();
    data.then((res) => {
      setQnaContent(res.content);
      setQnaCommentList(res.comment);
    })
  }, [])

  return (
    <BoardContext.Provider
      value={{ qnaContent, qnaCommentList }}>
      {children}
    </BoardContext.Provider>
  )
}