import React, { useEffect, useState } from 'react';
import { VideoContent } from '../../template/album/VideoContent'
import { AlbumNav } from '../../../../components/utils/molecules/AlbumNav';
import { PDFContent } from '../../template/album/PDFContent';
import hamburger from '../../../../image/hamburger.png';
import { URLContent } from '../../template/album/URLContent';
import { FileContent } from '../../template/album/FileContent';
import { AlbumHomeNav } from '../../../../components/utils/atoms/AlbumHomeNav';
import { AlbumApi } from '../../../../api/album';
import { useNavigate, useParams } from 'react-router-dom';
import { TextContent } from '../../template/album/TextContent';


export const AlbumContents = () => {
  const { id } = useParams();
  const { contentId } = useParams();
  const [album, setAlbum] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [content, setContent] = useState(null);
  const [fetch, setFetch] = useState(false);

  const navigate = useNavigate();



  const openNav = () => {
    setNavState(true);
  }

  const [navState, setNavState] = useState<boolean>(false);

  const getAlbum = async () => {
    const albumContent = (await (AlbumApi.getAlbum({ id }))).data.resultMap;
    setAlbum(prev => ({ ...albumContent }))
    let contentIds = [];

    albumContent.chapter.forEach((chapter) => {
      chapter.content.forEach((content) => {
        contentIds.push(content);
      });
    });
    setChapters(contentIds);
    console.log(chapters)
    !fetch && setFetch(true);

  }

  const getFirstContent = async () => {
    const contentRes = (await ((await AlbumApi.getContent({ id: contentId })).data.resultMap));
    setContent(prev => ({ ...contentRes }))
  }

  const getContent = async (id: string) => {
    const contentRes = (await ((await AlbumApi.getContent({ id })).data.resultMap));
    setContent(prev => ({ ...contentRes }))
    !fetch && setFetch(true);
  }
  const toPrevContent = (contentId: string) => {
    setFetch(false);
    navigate(`/album/contents/${id}/${contentId}`);
    getContent(contentId);
  }
  const toNextContent = (contentId: string) => {
    setFetch(false);
    navigate(`/album/contents/${id}/${contentId}`);
    getContent(contentId);
  }


  useEffect(() => {
    getAlbum();
    getFirstContent();
  }, [fetch]);

  return (
    <>
      <div className='fixed w-full top-0'>
        {navState === true
          ? <AlbumNav
            info={album}
            contents={chapters}
            handleNav={toNextContent}
            onClose={() => setNavState(false)} />
          : <button className="w-7 float-right right-2 top-2 absolute"
            onClick={() => openNav()}>
            <img src={hamburger} alt='hamburger' />
          </button>}
        <AlbumHomeNav
          albumId={id}
          albumTitle={album !== null ? album.name : ''}
          contentTitle={content !== null ? content.name : ''} />
        {(content !== null && content.prev !== null)
          && <button
            className='text-lg border m-2 w-20 h-8 border-white text-white fixed  top-[36px] right-24 rounded-full cursor-pointer bg-black'
            onClick={() => toPrevContent(content.prev)}>PREV</button>}
        {(content !== null && content.next !== null)
          && <button
            className='text-lg border m-2 w-20 h-8 border-white text-white fixed top-[36px] right-0 rounded-full cursor-pointer bg-black'
            onClick={() => toNextContent(content.next)}>NEXT</button>}
      </div>
      <div className='mt-7'>
        {content !== null && content.type === 1 && <VideoContent videoLink={content.link} height='94vh' />}
        {content !== null && content.type === 2 && <PDFContent pdf={content.link} />}
        {content !== null && content.type === 3 && <URLContent urlLink={content.link} />}
        {content !== null && content.type === 4 && <FileContent file={content} />}
        {content !== null && content.type === 5 && <TextContent text={content.link} />}
      </div>
    </>
  )
}
