import { VideoAttr } from '../../../components/views/template/album/VideoContent';
import React from 'react'
import ReactPlayer from 'react-player'


//npm i react-player
export const Youtube = ({ videoLink, height }: VideoAttr) => {
  // const handleVideo = () => {
  //   alert('동영상 끝');
  // }
  return (
    <>
      <div className='player-wrapper m-auto'>
        <ReactPlayer
          className='react-player'
          url={videoLink}    // 플레이어 url
          width='100%'         // 플레이어 크기 (가로)
          height={height}        // 플레이어 크기 (세로)
          playing={false}        // 자동 재생 on
          muted={true}          // 자동 재생 on
          controls={true}       // 플레이어 컨트롤 노출 여부
          light={false}         // 플레이어 모드
          pip={true}            // pip 모드 설정 여부
          poster={''}   // 플레이어 초기 포스터 사진
        // playbackRate={playbackRate}  // 배속기능
        // onEnded={() => handleVideo()}  // 플레이어 끝났을 때 이벤트
        />
      </div>
    </>
  )
}
