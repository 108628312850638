import { SubNav } from '../../../../components/utils/molecules/SubNav'
import React, { useEffect, useState } from 'react'
import { AlbumCard } from '../../../../components/utils/atoms/AlbumCard'
import { Title } from '../../../../components/utils/atoms/Title';
import { Search } from '../../../../components/utils/atoms/Search';
import { Filter } from '../../../../components/utils/atoms/Filter';
import { PagiNation } from '../../../../components/utils/atoms/PagiNation';
import { useNavigate, useParams } from 'react-router-dom';
import { AlbumApi } from '../../../../api/album';

export const AlbumListPage = () => {
  const navigate = useNavigate();
  const { category } = useParams();

  const [params, setParams] = useState({
    page: 1,
    category: category,
    sort_option: null,
    search_option: 1,
    search_text: null,
  });
  const [albumList, setAlbumList] = useState(null);
  const [page, setPage] = useState({
    count: 1,
    currentPage: 1,
    endPage: 1,
    next: false,
    prev: false,
    startCount: 0,
    startPage: 1,
    totalPage: 1
  });

  //앨범 불러오기
  const getAlbumList = async () => {
    const resultList = (await (AlbumApi.getAlbumList(params))).data.resultList;
    const resultMap = (await (AlbumApi.getAlbumList(params))).data.resultMap;
    setAlbumList(resultList);
    setPage(resultMap);
  }

  //파라미터, 페이지 조정
  const handlePageChange = (page) => {
    setParams((prev): any => ({ ...prev, page: page }));
    setPage(prev => ({ ...prev, currentPage: page }))
  }

  const handleView = (id: string) => {
    navigate('/album/view/' + id);
  }

  const handleCategory = (item: string) => {
    setParams((prev): any => ({ ...prev, category: item }));
  }

  const handleFilter = (item: string) => {
    setParams((prev): any => ({ ...prev, sort_option: item }));
  }

  const handleOption = (option, keyword) => {
    setParams((prev): any => ({ ...prev, search_option: option, search_text: keyword }))
  }

  useEffect(() => {
    getAlbumList();
  }, [page.currentPage, params.category, params.sort_option]);


  return (
    <div className='m-auto flex pt-5 mb-12 desktop:max-w-7xl  laptop:max-w-4xl tablet:max-w-3xl tablet:flex-col '>
      < SubNav menu={NavArr} propsHandle={handleCategory} />
      <div className='w-full ml-3 tablet:ml-0'>
        <Title>앨범 리스트</Title>
        <div className='my-3 overflow-hidden tablet:mx-[2px]'>
          <Filter category={sortOption} propsHandle={handleFilter} />
          <Search options={searchOption} propsHandle={handleOption} />
        </div>
        <div className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl flex flex-wrap clear-both'>
          {
            albumList !== null && albumList.map((album: any) =>
              <AlbumCard
                img={`/codeenator${album.thumbnail}`}
                key={album.id}
                mainText={album.name}
                subText={album.user_nickname}
                mainTag={album.user_tag !== "" ? album.user_tag.split(",") : null}
                subTag={['🙍‍♀️ ' +
                  ((album.listen_count < 10) ? '10명 이하'
                    : (album.listen_count >= 10 && album.listen_count) < 50 ? '50명 이하'
                      : (album.listen_count >= 50 && album.listen_count) < 100 ? '100명 이하'
                        : (album.listen_count >= 100 && album.listen_count) < 500 ? '500명 이하'
                          : (album.listen_count >= 500 && album.listen_count) < 1000 ? '100명 이하'
                            : '1000명 이상')
                  , '💜 ' + album.recommend_count]}
                callbackFx={() => handleView(album.id)} />
            )
          }
        </div>
        <PagiNation
          activePage={page.currentPage}
          itemsCountPerPage={20}
          totalItemsCount={page.count}
          pageRangeDisplayed={5}
          prevPageText={"‹"}
          nextPageText={"›"}
          firstPageText={"‹‹"}
          lastPageText={"››"}
          onChange={handlePageChange} />
      </div>
    </div >
  )
}

const NavArr = [
  {
    text: '전체',
    val: null
  },
  {
    text: 'IT',
    val: 'IT'
  },
  {
    text: '자기계발',
    val: '자기계발'
  },
  {
    text: '여행',
    val: '여행'
  },
  {
    text: '경제',
    val: '경제'
  },
  {
    text: '취미',
    val: '취미'
  },
  {
    text: '외국어',
    val: '외국어'
  },
  {
    text: '맛집',
    val: '맛집'
  },
  {
    text: '기타',
    val: '기타'
  }
];

const sortOption = [{
  'title': '인기순',
  "value": 1,
}, {
  'title': '이름순',
  "value": 2,
}, {
  'title': '최신순',
  "value": 3,
}];

const searchOption = [{
  'title': '제목+태그',
  "value": 1,
}, {
  'title': '제목',
  "value": 2,
}, {
  'title': '태그',
  "value": 3,
}];