import { useState } from 'react';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export type PDFAttr = {
  pdf: string;
}
export const PDFContent = ({ pdf }: PDFAttr) => {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const handleDownload = (url) => {
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const downloadUrl = URL.createObjectURL(blob);
        window.open(downloadUrl, '_blank');
      })
  }

  const screenSize = window.screen.width;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      {/* <Document
        file="document.pdf"
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}>
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        <span onClick={() => pageNumber > 1 ? setPageNumber(pageNumber - 1) : null}>
          &lt;
        </span>
        <span>Page {pageNumber} of {numPages}</span>
        <span onClick={() => pageNumber < numPages ? setPageNumber(pageNumber + 1) : null}>
          &gt;
        </span>
      </p> */}
      {screenSize > 1024 ? <embed src={'/codeenator' + pdf} width={'100%'} height={'800px'} /> : <div onClick={handleDownload}>{pdf}</div>}
    </div>
  )
}
