import { AlbumContents } from '../components/views/page/album/AlbumContents';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AlbumHistoryPage } from '../components/views/page/album/AlbumHistoryPage';
import { AlbumListPage } from '../components/views/page/album/AlbumListPage';
import { AlbumViewPage } from '../components/views/page/album/AlbumViewPage';
import { AlbumProvider } from '../context/AlbumContext';


export const RouteAlbum = () => {
  const location = useLocation();
  useEffect(() => {
    const header = document.querySelector('header');
    const footer = document.querySelector('footer');
    location.pathname.startsWith('/album/contents') && header.classList.add("no-header");
    location.pathname.startsWith('/album/contents') && footer.classList.add("no-header");
    return () => {
      header.classList.remove("no-header");
      footer.classList.remove("no-header");
    };
  }, [location]);
  return (
    <Routes>
      <Route path='/list/:category?' element={<AlbumListPage />} > </Route>
      <Route path='/history' element={<AlbumHistoryPage />}></Route>
      <Route path='/view/:id' element={
        <AlbumProvider>
          <AlbumViewPage />
        </AlbumProvider>
      }></Route>
      <Route path='/contents/:id/:contentId' element={<AlbumContents />}></Route>
    </Routes>
  )
}
