import React, { useContext, useEffect, useState } from 'react'
import { InputCommnet } from '../atoms/InputCommnet'
import { EditComment } from '../atoms/EditComment';
import { UserContext } from '../../../context/UserContext';
import { AlbumComment, AlbumContext } from '../../../context/AlbumContext';

type CommentAttr = {
  board: 'album' | 'qna' | 'notice',
  writer: string,
  submit: (text) => void;
  editSubmitHandle?: (text, seq) => void;
  comment: AlbumComment
}

export const Comment = ({ board, writer, comment, editSubmitHandle, submit }: CommentAttr) => {
  const { user } = useContext(UserContext);
  const [commentInfo, setCommentInfo] = useState(comment);
  const { deleteComment } = useContext(AlbumContext);

  const [replyInfo, setReplyInfo] = useState((comment !== null && comment.reply !== null)
    ? comment.reply.map(item => ({ ...item, mode: 'view' })) : null);

  const handleEdit = () => {
    setCommentInfo((prev => ({ ...prev, mode: 'edit' })))
  };
  const handleState = () => {
    setCommentInfo((prev => ({ ...prev, mode: 'view' })))
    let reply = [...replyInfo];
    reply.map(item => item.mode = 'view');
    setReplyInfo(prev => ([...reply]));
  }

  const handleReplyState = (item, index) => {
    let reply = [...replyInfo];
    reply[index].mode = 'edit';
    setReplyInfo(prev => ([...reply]));
  }

  const deleteReply = (seq) => {
    deleteComment(seq)
    let reply = [...replyInfo];
  }

  useEffect(() => {
    comment.reply !== null && setReplyInfo(prev => comment.reply.map(item => ({ ...item, mode: 'view' })))
  }, [comment]);

  return (
    <div className='border shadow-sm p-2 mt-3'>
      <div className='relative mb-5'>
        {
          ((commentInfo.mode === 'view' && sessionStorage.getItem('loginState/user-id') === comment.user_id))
          && <div className='flex gap-2 absolute right-3 text-grey '>
            {board === 'album' && <span onClick={() => handleEdit()} className='cursor-pointer'>수정</span>}
            {<span onClick={() => deleteComment(comment.seq)} className='cursor-pointer'>삭제</span>}
          </div>
        }
        {
          commentInfo.mode === 'view' && <>
            <p className='text-gray-400 text-sm'>{comment.datetime}</p>
            <p className='font-semibold block mb-2'>{comment.user_nickname}</p>
            <p>{comment.comment}</p>
          </>
        }
        {
          commentInfo.mode === 'edit' && <>
            <EditComment
              seq={comment.seq}
              submit={editSubmitHandle}
              setState={handleState}
              defalutVal={comment.comment} />
          </>
        }
      </div>
      {
        user !== null && (((board === 'album' && sessionStorage.getItem('loginState/user-id')) === writer) || (board === 'notice' && user.type === 1) || (board === 'qna'))
        && <InputCommnet seq={comment.seq} submit={submit} />
      }
      {replyInfo !== null && replyInfo.map((item, index) => {
        return (
          <div className='relative mt-4 mb-2 bg-zinc-100 p-3 rounded shadow' key={item.seq}>
            {item.mode === 'view' && <>
              {
                sessionStorage.getItem('loginState/user-id') === comment.user_id
                && <div className='flex gap-2 absolute right-3 text-grey '>
                  {board === 'album' && <span onClick={() => handleReplyState(item, index)} className='cursor-pointer'>수정</span>
                  }
                  <span onClick={() => deleteReply(item.seq)} className='cursor-pointer'>삭제</span>
                </div>
              }
              <p className='text-gray-400 text-sm'>{item.datetime}</p>
              <p className='font-semibold block mb-2'>{item.user_nickname}</p>
              <p>{item.comment}</p></>}
            {(item.mode === 'edit' && board === 'album')
              && <EditComment
                seq={item.seq}
                submit={editSubmitHandle}
                setState={handleState}
                defalutVal={item.comment} />}
          </div>
        )
      })}
    </div>
  )
}
