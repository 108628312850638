import { useEffect, useRef, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { FileApi } from '../../../api/file';
import MarkDown from './MarkDown';

export const EditorComponent = ({ ...props }: any) => {
  const quillRef = useRef(null)


  const imageHandler = (content) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.addEventListener('change', async (e: any) => {

      const editor = quillRef.current.getEditor();
      const range = editor.getSelection(true);


      let formData: any = new FormData();
      const { files } = e.target;
      formData.append('file', files[0]);
      const filePath = (await FileApi.uploadFile(formData)).data.resultMap.temp_path;
      const fileName = (await FileApi.uploadFile(formData)).data.resultMap.stored_name;

      editor.insertEmbed(range.index, "image", process.env.REACT_APP_API_BASE_URL + '/codeenator' + filePath + '/' + fileName);
      editor.setSelection(range.index + 1);

    })
  }


  const [value, setValue] = useState('');

  const handleChange = (content: any) => {
    setValue(content)
    props.onChage(content);
  }

  useEffect(() => {
    props.defaultValue !== '' && setValue(props.defaultValue);
    props.defaultValue !== '' && props.onChage(props.defaultValue);
  }, [props.defaultValue])

  return (
    <div style={{ height: "300px" }}>
      {/* <ReactQuill
        style={{ height: "300px" }}
        theme="snow"
        modules={modules}
        formats={formats}
        defaultValue={props.defaultValue}
        value={value}
        ref={quillRef}
        onChange={
          (content) => handleChange(content)} /> */}
      <MarkDown value={value} handleChange={handleChange} />
    </div>
  )
}