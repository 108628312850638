import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AlbumCard.module.css';
import { Tag } from './Tag';
import urlBtn from '../../../image/AddLinkFilled.png';
import infoBtn from '../../../image/InfoFilled.png';
import editBtn from '../../../image/EditFilled.png';
import deleteBtn from '../../../image/DeleteFilled.png';
import key from '../../../image/key.png';
import lock from '../../../image/lock.png';

type AlbumCardProps = {
  img: string;
  mainText: string;
  subText?: string;
  state?: string;
  mainTag?: string[] | undefined;
  subTag?: string[] | undefined;
  pk?: string | null;
  callbackFx?: () => void;
  handlePrivateBtn?: () => void;
  handleCopyBtn?: () => void;
  handleInfoBtn?: () => void;
  handleEditBtn?: () => void;
  handleDelBtn?: () => void;
}


export const AlbumCard = ({ img, mainText, subText, mainTag, subTag, callbackFx }: AlbumCardProps) => {

  return (
    <div className={`shadow-lg border p-2 rounded cursor-pointer ${styles.card}`} onClick={callbackFx && callbackFx}>
      <img src={img} alt='thumbnail' className='h-[200px] w-[100%]' />
      <p className={styles.title}>{mainText}</p>
      <p className={styles.writer}>{subText}<span> 코디네이터</span></p>
      <div className={styles.tag}>
        <div className={styles.mainTag}>
          {mainTag === null ? <></> : mainTag.map(item => <Tag key={item} text={'# ' + item} />)}
        </div>
        <br />
        {subTag !== undefined && subTag.map(item => <Tag key={item} text={item} color='sub' />)}
      </div>
    </div>
  )
}

export const AlbumManageCard = ({ img, pk, mainText, subText, mainTag, subTag, callbackFx, state = '검토중', handlePrivateBtn, handleCopyBtn, handleInfoBtn, handleEditBtn, handleDelBtn }: AlbumCardProps) => {


  return (
    <div className={`shadow-lg p-2 rounded ${styles.card}`} >
      <div className='cursor-pointer h-full' onClick={callbackFx && callbackFx}>
        <img src={img} alt='thumbnail' className='h-[200px] w-[100%]' />
        {state === '검토중' ? <div className={styles.yellowTag}>검토중</div> : state === '등록' ? <div className={styles.greenTag}>등록</div> : <div className={styles.redTag}>등록취소</div>}
        <p className={styles.title}>{mainText}</p>
        <p className={styles.writer}>{subText}<span> </span></p>
        <div className={styles.mycardTag}>
          <div className={styles.mainTag}>
            {mainTag === null ? <></> : mainTag.map(item => <Tag key={item} text={'# ' + item} />)}
          </div>
          <br />
          {subTag !== undefined && subTag.map(item => <Tag key={item} text={item} color='sub' />)}
        </div>
      </div>
      {pk === "" ? <></> : <img src={lock} className='absolute bottom-[10px] left-[10px] w-[30px]' alt='lock' />}
      <div className={styles.buttonConatiner}>
        {/* <button onClick={handlePrivateBtn}><img src={key} className="w-[94%] opacity-60 hover:scale-105" alt="privateBtn" title='private 전환' /></button> */}
        <button onClick={handleCopyBtn}><img src={urlBtn} className=" hover:scale-105" alt="urlBtn" title='url복사' /></button>
        <button onClick={handleInfoBtn}><img src={infoBtn} className=" hover:scale-105" alt="infoBtn" title='JSON다운로드' /></button>
        <button onClick={handleEditBtn}><img src={editBtn} className=" hover:scale-105" alt="infoBtn" title='앨범수정' /></button>
        <button onClick={handleDelBtn}><img src={deleteBtn} className=" hover:scale-105" alt="deleteBtn" title='앨범 삭제' /></button>
      </div>
    </div>
  )
}
