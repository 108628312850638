import { checkAllSpecialChar, checkEmailAdress, checkLength } from '../../../../utils/RegExp';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/utils/atoms/Button';
import logo from '../../../../image/logo.png';
import { SubNav } from '../../../../components/utils/molecules/SubNav';
import { Confirm, Prompt } from '../../../../components/utils/atoms/Dialog';
import { UserApi } from '../../../../api/user';
import { ConfirmContext, ModalContext } from '../../../../context/ModalContext';
import { UserContext } from '../../../../context/UserContext';

//nav
export const userNavArr = [
  {
    text: '회원정보 변경',
    url: '/user/modify/info'
  },
  {
    text: '비밀번호 변경',
    url: '/user/modify/pw'
  }
]
type ValidationAttr = {
  email: boolean,
  nickName: boolean
}

export const ModifyinfoPage = () => {


  const inputEmail = useRef<HTMLInputElement>(null);
  const { openPrompt } = useContext(ModalContext);

  const { openConfirm } = useContext(ConfirmContext);


  const [validation, setValidation] = useState<ValidationAttr>({
    email: true,
    nickName: true
  });


  //setUserInfo
  useEffect(() => {
    UserApi.getUser().then(res => {
      inputEmail.current.value = res.data.resultMap.email;
      inputNickname.current.value = res.data.resultMap.nickname;
    });
  }, [UserContext]);


  //changeInfo
  const chageEmail = () => {
    validation.email === true && setValidation(prev => ({ ...prev, email: false }));
  }
  const chageNick = () => {
    validation.email === true && setValidation(prev => ({ ...prev, nickName: false }));
  }


  const openPromptBox = () => {

    if (validation.email === true && validation.nickName === true) {
      openPrompt('기존 비밀번호를 입력해주세요', (password: string) => {
        UserApi.checkUserPw({ password }).then(res => {
          if (res.data.result === 'success') {

            UserApi.modifyUser({ email, nickname }).then(ret => {
              if (ret.data.result === 'success') {
                openConfirm(ret.data.msg);
              } else {
                openConfirm(ret.data.msg);
              }

            });
          } else {
            openConfirm(res.data.msg);
          }
        })

      })
    } else {
      openConfirm('닉네임 혹은 인증번호를 인증해주세요');
    }

    const email = inputEmail.current.value.toString();
    const nickname = inputNickname.current.value.toString();



    return;
  }

  //nickName check
  const inputNickname = useRef<HTMLInputElement>(null);
  const checkNickname = () => {
    const nickname = inputNickname.current.value.toString();
    const isSpeChar = checkAllSpecialChar(nickname);
    const isLength = checkLength(nickname, 0, 12);
    nickname === '' ? openConfirm('닉네임을 입력해주세요.') :
      isSpeChar === false ? openConfirm('닉네임에는 한글/영어/숫자만 사용하실 수 있습니다.') :
        isLength === false ? openConfirm('닉네임은 12자리 이하로 설정해주세요') :
          UserApi.checkNick({ nickname }).then(res => {
            if (res.data.result === 'success') {
              setValidation(prev => ({ ...prev, nickName: true }));
              openConfirm(res.data.msg);
            } else {
              openConfirm(res.data.msg);
            }
          });
  }

  //send cipher
  const [sendCipher, setSendCipher] = useState<string>('인증번호 전송');
  const checkEmail = () => {
    const email = inputEmail.current.value.toString();
    const isEmail = checkEmailAdress(email); if (email === '') {
      openConfirm('이메일을 입력해주세요.');
      setSendCipher('인증번호 전송');

      return;
    }
    if (isEmail === false) {
      openConfirm('이메일 형식으로 입력해주세요.');
      setSendCipher('인증번호 전송');

      return;
    }

    setSendCipher('전송중');

    UserApi.getCipher({ email }).then(res => {
      if (res.data.result === 'success') {
        openConfirm(res.data.msg);
        setSendCipher('전송 완료');
      } else {
        openConfirm(res.data.msg);
        setSendCipher('인증번호 전송');
      }
    })
  }

  //Email check
  const inputCipher = useRef<HTMLInputElement>(null);
  const [cipherText, setCipherText] = useState<string>('인증번호 확인');

  const checkCipher = () => {
    const email = inputEmail.current.value.toString();
    const cipher = inputCipher.current.value.toString();
    cipher === '' ? openConfirm('인증코드를 입력해주세요.')
      : UserApi.checkCipher({ email, cipher }).then(res => {
        if (res.data.result === 'success') {
          setValidation(prev => ({ ...prev, email: true }));
          openConfirm(res.data.msg);
          setCipherText('인증완료')
        } else {
          openConfirm(res.data.msg);
        }
      })
  }
  return (
    <div className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl m-auto flex pt-5'>
      <SubNav menu={userNavArr} />
      <div className='flex m-auto mt-[20vh]'>
        <div className='self-center shadow-md m-auto w-[500px] rounded bg-white align-center p-4 pt-5'>
          <div>
            <img src={logo} alt='logo' className='inline-block h-6' />
            <span className='float-right'>MyPage</span>
          </div>
          <div className='flex mt-3 mb-3'>
            <span className='w-[130px] leading-10'>E-mail</span>
            <input type="text" className='w-[250px] rounded border mr-2 p-1 h-10' onChange={chageEmail} ref={inputEmail} />
            <div className='w-[130px]'>
              <Button eventHandle={() => checkEmail()} color="gray" size='full'>{sendCipher}</Button>
            </div>
          </div>
          <div className='flex mt-3 mb-3'>
            <span className='w-[130px] leading-10'>인증번호</span>
            <input type="text" className='w-[250px] rounded border mr-2 p-1 h-10' ref={inputCipher} />
            <div className='w-[130px]'>
              <Button eventHandle={() => checkCipher()} color="gray" size='full'>{cipherText}</Button>
            </div>
          </div>
          <div className='flex mt-3 mb-3'>
            <span className='w-[130px] leading-10'>닉네임</span>
            <input type="text" className='w-[250px] rounded border mr-2 p-1 h-10' onChange={chageNick} ref={inputNickname} />
            <div className='w-[130px]'>
              <Button eventHandle={() => checkNickname()} color="gray" size='full'>중복확인</Button>
            </div>
          </div>
          <div className='flex flex-col mt-10 gap-3'>
            <Button eventHandle={() => openPromptBox()} size="full" color='sub'>회원정보 변경</Button>
          </div>
        </div>
      </div>
    </div>
  )
}
