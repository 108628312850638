import React, { useContext, useEffect, useRef, useState } from 'react'
import { manageMenuArr } from './ManageUserPage'
import { SubNav } from '../../../../components/utils/molecules/SubNav'
import { EditorComponent } from '../../../../components/utils/atoms/Editor'
import { Title } from '../../../../components/utils/atoms/Title'
import { FileApi } from '../../../../api/file';
import { Button } from '../../../../components/utils/atoms/Button'
import { ManageApi } from '../../../../api/manage'
import { ConfirmContext } from '../../../../context/ModalContext';

type Banner = {
  link: string;
  file: string;
}

export const ManagePortalPage = () => {
  const { openConfirm } = useContext(ConfirmContext);



  const [banner, setBanner] = useState<Banner[]>([{
    link: '',
    file: ''
  }, {
    link: '',
    file: ''
  }, {
    link: '',
    file: ''
  }]);
  const [albumCount, setAlbumCount] = useState(5);
  const [albumSort, setAlbumSort] = useState(1);
  const [usePopup, setUsePopup] = useState();
  const [popupW, setPopupW] = useState(0);
  const [popupH, setPopupH] = useState(0);
  const [content, setContent] = useState('');

  const inputBannerUrl1 = useRef<HTMLInputElement>(null);
  const inputBannerUrl2 = useRef<HTMLInputElement>(null);
  const inputBannerUrl3 = useRef<HTMLInputElement>(null);
  const inputWidth = useRef<HTMLInputElement>(null);
  const inputHeight = useRef<HTMLInputElement>(null);

  const handleBannerImg = async (e, index: number) => {

    let formData: any = new FormData();
    const { files } = e.target;
    formData.append('file', files[0]);
    const res = await FileApi.uploadFile(formData);
    setBanner(prev =>
      prev.map((item, i) => {
        if (index === i) {
          return { ...item, file: res.data.resultMap.path }
        } else {
          return item;
        }
      }))
  }

  const handleBannerUrl = async () => {
    const url1 = inputBannerUrl1.current.value.toString();
    const url2 = inputBannerUrl2.current.value.toString();
    const url3 = inputBannerUrl3.current.value.toString();

    setBanner(prev =>
      prev.map((item, i) => {
        if (i === 0) {
          return { ...item, link: url1 }
        } else if (i === 1) {
          return { ...item, link: url2 }
        } else if (i === 2) {
          return { ...item, link: url3 }
        }
      }))
  }

  const handleCount = (e) => {
    setAlbumCount(e.target.value);
  }

  const handleSort = (e) => {
    setAlbumSort(e.target.value);
  }

  const handlePopup = (e) => {
    setUsePopup(e.target.value);
  }

  const handleChangeW = () => {
    const w = Number(inputWidth.current.value);
    setPopupW(w);

  }

  const handleChangeH = () => {
    const h = Number(inputHeight.current.value);
    setPopupH(h);
  }

  const handleContent = (content: any) => {
    const escapedText = content !== undefined && content.replace(/'/g, "\\'");
    setContent(escapedText);
  }

  const handleFileUploadEditor = async (e: any) => {
    let formData = new FormData();
    const { files } = e.target;
    formData.append('file', files[0]);
  }

  const submitPortal = async () => {
    ManageApi.setSetting({
      banner: banner,
      album_count: albumCount,
      album_sort: albumSort,
      popup_enable: usePopup,
      popup_width: popupW,
      popup_height: popupH,
      popup_content: content,
    });
    openConfirm('저장되었습니다.');

  }


  const getSetting = async () => {
    const res = (await ManageApi.getSetting()).data.resultMap;
    setAlbumCount(prev => res.album_count);
    setAlbumSort(prev => res.album_sort);
    setUsePopup(prev => res.enable_popup);
    setPopupW(prev => res.popup_width);
    setPopupH(prev => res.popup_height);
    setContent(prev => res.popup_content);
    setBanner(prev => res.banner);
  }

  useEffect(() => {
    // getSetting();
  }, [])

  return (
    <>
      <div className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl m-auto flex pt-5 mb-[100px]'>
        <SubNav menu={manageMenuArr} />
        <div className='w-full p-2'>
          <Title>포털페이지 관리</Title>
          <div className='p-4 border-b border-zinc-400'>
            <h2 className='text-lg'>배너 편집</h2>
            <div className='flex mt-3 mb-3'>
              <h3 className='w-[100px]'>이미지1</h3>
              <div className='flex gap-5'>
                <div className='flex-3'>
                  <input type="file"
                    onChange={(e) => handleBannerImg(e, 0)} />
                  <input type="text"
                    onChange={() => handleBannerUrl()}
                    ref={inputBannerUrl1}
                    className='border w-full h-10 p-1 mt-2'
                    // defaultValue={banner[0].link}
                    placeholder='배너와 연결할 url' /></div>
                <div className='flex-1'>
                  <p>선택된 이미지</p>
                  <div className='w-[128px] h-[50px] overflow-hidden'>
                    <img src={'/codeenator/' + banner[0].file} alt="" className='w-[100%] h-[100%] object-fill' />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex mt-3 mb-3'>
              <h3 className='w-[100px]'>이미지2</h3>
              <div className='flex gap-5'>
                <div className='flex-3'>
                  <input type="file"
                    onChange={(e) => handleBannerImg(e, 1)} />
                  <input type="text"
                    onChange={() => handleBannerUrl()}
                    ref={inputBannerUrl2}
                    className='border w-full h-10 p-1 mt-2'
                    // defaultValue={banner[1].link}
                    placeholder='배너와 연결할 url' />
                </div>
                <div className='flex-1'>
                  <p>선택된 이미지</p>
                  <div className='w-[128px] h-[50px] overflow-hidden'>
                    <img src={'/codeenator/' + banner[1].file} alt="" className=' w-[100%]  h-[100%] object-fill' />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex mt-3 mb-3'>
              <h3 className='w-[100px]'>이미지3</h3>
              <div className='flex gap-5'>
                <div className='flex-3'>
                  <input type="file"
                    onChange={(e) => handleBannerImg(e, 2)} />
                  <input type="text"
                    onChange={() => handleBannerUrl()}
                    ref={inputBannerUrl3}
                    // defaultValue={banner[2].link}
                    className='border w-full h-10 p-1 mt-2'
                    placeholder='배너와 연결할 url' />
                </div>
                <div className='flex-1'>
                  <p>선택된 이미지</p>
                  <div className='w-[128px] h-[50px] overflow-hidden'>
                    <img src={'/codeenator/' + banner[2].file} alt="" className=' w-[100%] h-[100%] object-fill' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='p-4 border-b border-zinc-400'>
            <div className='flex mt-3 mb-3'>
              <h3 className='w-[100px]  leading-10'>메인 앨범</h3>
              <div>
                <select
                  className='border h-10 rounded-sm'
                  onChange={(e) => handleCount(e)} value={albumCount}>
                  <option value="4">4</option>
                  <option value="8">8</option>
                  <option value="12">12</option>
                  <option value="16">16</option>
                </select>
              </div>
            </div>
            <div className='flex mt-3 mb-3  leading-10'>
              <h3 className='w-[100px]'>노출 기준</h3>
              <div>
                <select
                  className='border h-10 rounded-sm'
                  onChange={(e) => handleSort(e)} value={albumSort}>
                  <option value="1">추천순</option>
                  <option value="2">이름순</option>
                  <option value="3">등록순</option>
                </select>
              </div>
            </div>
          </div>
          <div className='mt-5'>
            <div className='flex mb-3 leading-10 w-[800px]'>
              <div className='flex-1 flex'>
                <h3 className='w-[100px]'>공지 활성화</h3>
                <div>
                  <select
                    className='border h-10 rounded-sm w-[100px]'
                    onChange={(e) => handlePopup(e)} value={usePopup}>
                    <option value='true'>활성</option>
                    <option value='false'>비활성</option>
                  </select>
                </div>
              </div>
              <div className='flex-1 flex'>
                <label htmlFor="width" >너비(px)</label>
                <input
                  className='border px-1 ml-3 w-[100px]'
                  ref={inputWidth}
                  onChange={handleChangeW}
                  value={popupW}
                  type="number"
                  name="width" />
              </div>
              <div className='flex-1 flex'>
                <label htmlFor="height">높이(px)</label>
                <input
                  className='border px-1 ml-3 w-[100px]'
                  ref={inputHeight}
                  onChange={handleChangeH}
                  value={popupH}
                  type="number"
                  name="height" />
              </div>
            </div>
            <EditorComponent
              content={content}
              onChage={handleContent}
              defaultValue={content !== undefined ? content : content} />
            <input type="file" className='input-image-file' onChange={(e) => handleFileUploadEditor(e)} hidden />
          </div>
        </div>
      </div >
      <div className='text-center m-5'>
        <Button eventHandle={submitPortal}>저장</Button>
      </div>
    </>
  )
}
