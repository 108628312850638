import React, { useEffect, useState } from 'react'
import { PDFContent } from './PDFContent';

export type FileAttr = {
  file: any;
}
export const FileContent = ({ file }: FileAttr) => {

  const [fileType, setFileType] = useState('not-image');

  const checkFileType = () => {
    const extension = file.link.split('.').pop(); // 파일 경로에서 .을 찾아 마지막 문자열 추출
    if (extension === 'png' || extension === 'jpg' || extension === 'jpeg' || extension === 'gif' || extension === 'jfif') {
      setFileType('image');
    } else if (extension === 'pdf') {
      setFileType('pdf');
    }
    else {
      return;
    }
  }

  const handleDownload = (url) => {
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const downloadUrl = URL.createObjectURL(blob);
        window.open(downloadUrl, '_blank');
      })
  }

  useEffect(() => {
    checkFileType();
  }, [])

  return (
    <div className='text-center m-3'>
      <p className=' text-lg'>파일명 : {file.name}</p>
      {fileType === 'image' &&
        <div>
          <img src={`/codeenator${file.link}`} alt="미리보기" className='m-auto' />
          <span>(미리보기)</span>
        </div>}
      <button className='block p-2 font-bold underline mb-5 m-auto' onClick={() => handleDownload(`/codeenator${file.link}`)}>파일 다운로드(클릭)</button>
      {
        fileType === 'pdf' &&
        <div>
          <span className='mb-3'>(미리보기)</span>
          <PDFContent pdf={file.link} />
        </div>
      }
    </div >
  )
}
