import { userComposition } from '@/interface/user';
import { UserApi } from "../api/user";
import { createContext, useState } from "react";

export const UserContext = createContext(null);


export const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState<userComposition | null>(null);
  const [userState, changeUserState] = useState(false);


  const removeUser = () => {
    setUser(null);
    sessionStorage.removeItem('loginState/user');
    sessionStorage.removeItem('loginState/user-id');
  }


  const getUserInfo = async () => {
    const res = (await UserApi.getUser()).data;
    if (res.result === 'success') {
      user === null && setUser(res.resultMap)
    } else {
      removeUser();
    }
  }

  const logout = async () => {
    const res = (await UserApi.logout()).data;
    if (res.result === 'success') {
      removeUser();
      return 'success'
    } else {
      return 'fail'
    }
  }

  return (
    <UserContext.Provider value={{ user, userState, setUser, changeUserState, getUserInfo, logout }}>
      {children}
    </UserContext.Provider>
  )
}