import { Title } from '../../../../../components/utils/atoms/Title'
import React, { useContext, useEffect, useState } from 'react'
import { Button } from '../../../../../components/utils/atoms/Button';
import { CreateAlbumContext } from '../../../../../context/CreateAlbumContext';
import { ConfirmContext } from '../../../../../context/ModalContext';
import { useParams } from 'react-router-dom';

export const Publish = () => {
  const { openConfirm } = useContext(ConfirmContext);
  const { changeStep, modify, publish } = useContext(CreateAlbumContext);
  const [submitType, setSubmitType] = useState('');
  const path = useParams();
  const { id } = useParams();


  const [term01, setTerm01] = useState(false);
  const [term02, setTerm02] = useState(false);
  const [termAll, setTermAll] = useState(false);
  const handleSubmit = () => {
    let albumId: string;
    if (id !== undefined) {
      console.log(id)
      if (id.length === 6) {
        albumId = id;
      } else if (id.length === 12) {
        albumId = id.slice(0, 6);
      }
    }
    !term01 || !term02
      ? openConfirm('동의여부를 확인해주세요.')
      : submitType === '수정' ? modify(albumId) : publish();
  }



  const setTerm = () => {
    setTermAll(prev => {
      if (termAll === true) {
        setTerm01(false)
        setTerm02(false)
      } else {

        setTerm01(true)
        setTerm02(true)
      }
      return !prev
    });
  }


  useEffect(() => {
    path['*'].includes('modify') ? setSubmitType('수정') : setSubmitType('Publish')
  }, [])

  return (
    <>
      <Title>동의 및 발행</Title>
      <div className='p-3 mt-8 leading-9'>
        <ul>
          <li> · 제출하는 앨범이 이전에 코디네이터가 등록한 앨범과 유사할 때 등록이 거절될 수 있습니다. </li>
          <li> · 사회적으로 좋지 않은 영향을 끼칠 수 있는 컨텐츠가 있는 앨범은 등록이 거절 됩니다. </li>
          <li> · 제품의 홍보 목적으로 만들어 진 앨범은 등록이 거절될 수 있습니다.</li>
        </ul>
        <div className='text-center mt-4'>
          <input type="checkbox" name="term1" id="term1" className='translate-y-[2px] mr-1' onChange={() => setTerm01(prev => !prev)} checked={term01 ? true : false} />
          <label htmlFor="term1">상기 내용에 동의합니다</label>
        </div>
      </div>
      <div className='p-3 mt-8 leading-9'>
        <ul>
          <li> · 저작권 문제가 있는 컨텐츠 공유시 그에 대한 책임은 코디네이터에 있으며 본 서비스에서는 책임을 지지 아니합니다.</li>
          <li> · 개인정보나 주요 기밀 정보를 누설하는 컨텐츠 공유시 그에 대한 책임은 코디네이터에 있으며 본 서비스에서는 책임을 <br /> &nbsp; 지지 아니합니다.  </li>
          <li> · 상기 문제로 외부 요청이 있을 시 앨범은 등록 이후라도 삭제 될 수 있습니다.</li>
        </ul>
        <div className='text-center mt-4'>
          <input type="checkbox" name="term2" id="term2" className='translate-y-[2px] mr-1' onChange={() => setTerm02(prev => !prev)} checked={term02 ? true : false} />
          <label htmlFor="term2">상기 내용에 동의합니다</label>
        </div>
      </div>
      <div className='text-center mt-7'>
        <input type="checkbox" name="termAll" id="termAll" className='translate-y-[2px] mr-1' checked={(term01 && term02) ? true : false} onChange={setTerm} />
        <label htmlFor="termAll">상기 내용에 모두 동의합니다</label>
      </div>
      <div className='mt-24 flex gap-2 justify-center'>
        <Button eventHandle={() => changeStep(2)} color="sub">이전단계로 이동</Button>
        <Button eventHandle={() => handleSubmit()} color="sub">{submitType}</Button>
      </div>
    </>
  )
}
