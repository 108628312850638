
import React from "react";
import { useTable } from "react-table";

interface TableAttr {
  columns: any[];
  data: any[];
  clickHandle: (seq) => void;
}

export const Table = ({ columns, data, clickHandle }: TableAttr) => {

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <table {...getTableProps()} className="w-full text-center text-md">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="pt-3 pb-3 border-b-2 border-b-gray-300">{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                if (cell.column.id === 'title') {
                  return <td {...cell.getCellProps()} className="pr-5 pl-5 pt-2 pb-2 border-b min-w-[300px]	cursor-pointer"
                    onClick={() => {
                      clickHandle(row.original.seq);
                    }
                    }>{cell.render("Cell")}</td>
                } else {
                  return <td {...cell.getCellProps()} className="pr-5 pl-5 pt-2 pb-2 border-b min-w-[50px]	"
                    onClick={() => {

                    }
                    }>{cell.render("Cell")}</td>
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;