import { SubNav } from '../../../../components/utils/molecules/SubNav';
import React, { useEffect, useMemo, useState } from 'react';
import { UserTable } from '../../template/manage/UserTable';
import { ManageApi } from '../../../../api/manage';
import { PagiNation } from '../../../../components/utils/atoms/PagiNation';
import { Search } from '../../../../components/utils/atoms/Search';
import { Filter } from '../../../../components/utils/atoms/Filter';




export const ManageUserPage = () => {

  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState({
    count: 1,
    currentPage: 1,
    endPage: 1,
    next: false,
    prev: false,
    startCount: 0,
    startPage: 1,
    totalPage: 1
  });
  const [params, setParams] = useState({
    page: 1,
    sort_option: null,
    search_option: 1,
    search_text: null,
  });

  const handlePageChange = (page) => {
    setParams((prev): any => ({ ...prev, page: page }));
    setPage(prev => ({ ...prev, currentPage: page }))
  }

  const getList = async () => {
    const result = (await ManageApi.getUserList(params)).data;
    setUserList(result.resultList);
    setPage(result.resultMap)
  }

  const handleFilter = (item) => {
    setParams((prev): any => ({ ...prev, sort_option: item }));
  }

  const handleOption = (option, keyword) => {
    setParams((prev): any => ({ ...prev, search_option: option, search_text: keyword }))
  }

  useEffect(() => {
    getList();
  }, [params]);


  const columns = useMemo(() => columnData, []);

  const data = useMemo(() => userList, [userList]);



  return (
    <>
      <div className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl m-auto flex pt-5'>
        <SubNav menu={manageMenuArr} />
        <div className='pl-5 w-full'>
          <h3>총 회원 {page.count}명</h3>
          <Filter category={sortOption} propsHandle={handleFilter} />
          <Search options={searchOption} propsHandle={handleOption} />
          <UserTable
            get={getList}
            columns={columns}
            data={data} />
        </div>
      </div >
      <PagiNation
        activePage={page.currentPage}
        itemsCountPerPage={10}
        totalItemsCount={page.count}
        pageRangeDisplayed={5}
        prevPageText={"‹"}
        nextPageText={"›"}
        firstPageText={"‹‹"}
        lastPageText={"››"}
        onChange={handlePageChange} />
    </>
  )
}

export const manageMenuArr = [
  {
    url: '/manage/user',
    text: '회원 관리'
  },
  {
    url: '/manage/board',
    text: '게시글 관리'
  },
  {
    url: '/manage/album',
    text: '앨범 관리'
  },
  {
    url: '/manage/portal',
    text: '포털페이지 관리'
  }
];

const columnData = [
  {
    Header: '닉네임',
    accessor: 'nickname',
  },
  {
    Header: '아이디',
    accessor: 'id',
  },
  {
    Header: '가입일',
    accessor: 'datetime',
  },
  {
    Header: '로그인 수',
    accessor: 'login_count',
  },
  {
    Header: '앨범 구독 수',
    accessor: 'listen_count',
  },
  {
    Header: '앨범 제작',
    accessor: 'album_count',
  },
  {
    Header: '로그',
    accessor: 'comment',
  },
  {
    Header: '기능',
    accessor: 'manage',
  },
];

const sortOption = [{
  'title': '접속순',
  "value": 1,
}, {
  'title': '이름순',
  "value": 2,
}, {
  'title': '앨범수 순',
  "value": 3,
}];

const searchOption = [{
  'title': '제목+태그',
  "value": 1,
}, {
  'title': '제목',
  "value": 2,
}, {
  'title': '태그',
  "value": 3,
}];