import * as React from 'react';
import { useNavigate } from 'react-router-dom';


interface LinkProps {
  route?: string;
  url?: string;
  children: any;
}

export default function Link({ ...props }: LinkProps) {
  const navigate = useNavigate();
  const handleClick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    props.route && navigate(props.route);

    props.url && window.open(props.url);
  }

  return (
    <div onClick={handleClick} className="cursor-pointer" >{props.children}</div>
  );
}

