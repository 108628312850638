import React, { useRef } from 'react'
import Link from '../atoms/Link';

export const SubNav = ({ menu, propsHandle }: any) => {
  const selectOption = useRef<HTMLSelectElement>(null);
  const handleEvent = (item: string) => {
    propsHandle(item);
  }
  const handleSelectEvent = () => {
    const option = selectOption.current.value.toString();
    propsHandle(option);
  }

  return (
    <nav className={`min-w[150px] h-full border shadow rounded-sm w-40 tablet:w-full border-none`} >
      <ul className='tablet:hidden'>
        {menu.map((item, index) => (
          <li className="p-2 cursor-pointer" key={item + index}>
            {item.url ? <Link route={item.url}>{item.text}</Link> : <span onClick={() => handleEvent(item.val)}>{item.text}</span>}
          </li>
        ))}
      </ul>
      <div className='hidden tablet:block'>
        <select name="" id="" className=' tablet: border ml-[2px] my-[5px] h-10 w-[99%]' ref={selectOption} onChange={() => handleSelectEvent()}>
          {menu.map((item, index) => (
            <option value={item.url} key={index}>{item.text}</option>
          ))}
        </select>
      </div>
    </nav >
  )
}
