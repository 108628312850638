import React, { useContext } from 'react'
import { Button } from '../../../../../../components/utils/atoms/Button'
import { CreateAlbumContext } from '../../../../../../context/CreateAlbumContext';

export const AddUrl = ({ chapterIdx, contentIdx }: any) => {
  const { contentAlbum, changeContentAlbum } = useContext(CreateAlbumContext);


  //video 저장
  const inputContent = (e) => {
    const { name, value } = e.target;
    const data = { ...contentAlbum };
    data.chapter[chapterIdx].content[contentIdx][name] = value;
    changeContentAlbum(data);
  }

  const openPage = () => {
    window.open(contentAlbum.chapter[chapterIdx].content[contentIdx].content !== undefined ? contentAlbum.chapter[chapterIdx].content[contentIdx].content : contentAlbum.chapter[chapterIdx].content[contentIdx].link);
  }

  return (
    <div>
      <div className='border-b pb-2'>
        <input type="text"
          onChange={inputContent}
          name="name"
          className='border w-full p-1 h-10'
          placeholder='콘텐츠 제목 입력'
          defaultValue={contentAlbum.chapter[chapterIdx].content[contentIdx].name} />
      </div>
      <div className='flex mt-1 mb-1 gap-2 pt-1'>
        <input type="text"
          onChange={inputContent}
          name="content" className='border w-[75%] p-1'
          placeholder='페이지 url을 입력해주세요'
          defaultValue={contentAlbum.chapter[chapterIdx].content[contentIdx].content !== undefined ? contentAlbum.chapter[chapterIdx].content[contentIdx].content : contentAlbum.chapter[chapterIdx].content[contentIdx].link} />
        <Button eventHandle={openPage}>새 탭으로 URL 확인</Button>
      </div>
      <p className='mt-10 mb-10 leading-8'>
        · 연결할 페이지의 링크를 복사한 후 입력란에 붙여 넣습니다.<br />
        · notion, confluence, evernote, onenote 등 노트나 위키 링크를 활용해도 좋습니다.
      </p>
    </div>
  )
}
