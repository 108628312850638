
import { Button } from "../../../../components/utils/atoms/Button";
import React, { useContext, useRef, useState } from "react";
import { useTable } from "react-table";
import { ManageApi } from "../../../../api/manage";
import { AlertContext, ConfirmContext, ModalContext } from "../../../../context/ModalContext";


interface TableAttr {
  columns: any[];
  data: any[];
  get: () => void;
}

export const UserTable = ({ get, columns, data }: TableAttr) => {
  const { openPrompt } = useContext(ModalContext);
  const { openConfirm } = useContext(ConfirmContext);
  const { openAlert } = useContext(AlertContext);


  const [emailDialog, setEmailDialog] = useState<boolean>(false);
  const [seq, setSeq] = useState<number>();


  const handleInitPw = (val) => {
    const seq = val.original.seq;
    openAlert('초기화하시겠습니까?', () => { ManageApi.initPw({ seq, enable_email: true }).then(res => openConfirm(res.data.msg)) });
  }


  const sendEmailDialogOpen = (val) => {
    const seq = val.original.seq;
    setEmailDialog(true);
    setSeq(seq);
  }

  const inputTitle = useRef<HTMLInputElement>(null);
  const inputText = useRef<HTMLTextAreaElement>(null);

  const handleEmail = () => {
    const title = inputTitle.current.value.toString();
    const content = inputText.current.value.toString();
    ManageApi.sendEmail(seq, { title, content }).then(res => {
      openConfirm(res.data.msg);
      setEmailDialog(false);
    })
  }

  const setLogDialogOpen = (val) => {
    const seq = val.original.seq;
    const originComment = val.original.comment;
    console.log(val)

    openPrompt('로그 확인', (comment) => {
      ManageApi.setUserComment(seq, { comment }).then(res => openConfirm(res.data.msg)).then(() => get())
    }, originComment)
  }

  const handleDeleteUser = (val) => {
    const seq = val.original.seq;
    const id = val.original.id;
    const nickname = val.original.nickname;

    openAlert(`${id}(${nickname})님의 계정을 삭제하시겠습니까?`, () => { ManageApi.deleteUser({ seq }).then(res => openConfirm(res.data.msg)).then(() => get()) });

  }
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <>
      <table {...getTableProps()} className="w-full text-center text-sm">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className="pt-3 pb-3 border-b-2 border-b-gray-300">{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column.id === 'manage') {
                    return (
                      <td className="pr-5 pl-5 pt-2 pb-2 border-b ">
                        <div className="flex gap-1">
                          <Button eventHandle={() => handleInitPw(row)} color="sub">비번초기화</Button>
                          <Button eventHandle={() => sendEmailDialogOpen(row)} color="sub">이메일</Button>
                          <Button eventHandle={() => handleDeleteUser(row)} color="sub">삭제</Button>
                        </div>
                      </td>)
                  } else if (cell.column.id === 'comment') {
                    return (
                      <td {...cell.getCellProps()} className="pr-5 pl-5 pt-2 pb-2 border-b min-w-[130px]	">
                        {row.original.comment === null || row.original.comment === ''
                          ? <Button eventHandle={() => setLogDialogOpen(row)} color="gray">로그입력</Button>
                          : <Button eventHandle={() => setLogDialogOpen(row)} color="sub">로그확인</Button>}
                      </td>
                    )
                  } else if (cell.column.id === 'login_count' || cell.column.id === 'album_count' || cell.column.id === 'listen_count') {
                    return (
                      <td {...cell.getCellProps()} className="pr-5 pl-5 pt-2 pb-2 border-b min-w-[80px]	"
                      >{cell.render("Cell")}</td>
                    )
                  } else {
                    return (
                      <td {...cell.getCellProps()} className="pr-5 pl-5 pt-2 pb-2 border-b min-w-[130px]	"
                      >{cell.render("Cell")}</td>
                    )
                  }

                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {emailDialog ? <div className="fixed border bg-white w-[700px] h-[400px] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] p-3">
        <input
          type="text"
          ref={inputTitle}
          className="resize-none border w-full p-1"
          placeholder="제목을 입력해주세요" />
        <textarea
          className="resize-none border w-full p-1 h-[280px] my-2"
          ref={inputText}
          placeholder="내용을 입력해주세요" ></textarea>
        <div className="flex gap-2  justify-center">
          <Button eventHandle={() => setEmailDialog(false)} color="gray">취소</Button>
          <Button eventHandle={() => handleEmail()} color="sub">메일 전송</Button>
        </div>
      </div> : <></>}

    </>
  );
}
