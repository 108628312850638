import { AlbumApi } from "../api/album";
import { createContext, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "../components/utils/atoms/Dialog";
import { AlertContext } from "./ModalContext";

export const AlbumContext = createContext(null);
export const AlbumProvider = ({ children }: any) => {
  const { id } = useParams();
  const [album, setAlbum] = useState<any>(null);
  const navigate = useNavigate();
  const [comment, setComment] = useState<AlbumComments>(null);
  const [page, setPage] = useState({
    count: 1,
    currentPage: 1,
    endPage: 1,
    next: false,
    prev: false,
    startCount: 0,
    startPage: 1,
    totalPage: 1
  });

  const { openAlert } = useContext(AlertContext);

  //앨범 보기
  const getAlbum = async () => {
    let albumId: string, albumPk: string;
    if (id.length === 6) {
      albumId = id;
    } else if (id.length === 12) {
      albumId = id.slice(0, 6);
      albumPk = id.slice(-6);
    } else {
      navigate('/album/list');
      return;
    }

    try {
      const albumRes = await AlbumApi.getAlbum({ id: albumId, pk: albumPk });
      const { result, resultMap } = albumRes.data;

      if (result === "failed") {
        navigate('/album/list');
        return;
      }

      setAlbum(resultMap);
    } catch (error) {
      console.error('Error while fetching album:', error);
      navigate('/album/list');
    }
  }




  //코멘트 
  const getComment = async () => {
    const res = (await (AlbumApi.getComment({ album: id, page: page.currentPage })));
    setComment(prev => (res.data.resultList))
    setPage(prev => res.data.resultMap);
  }


  const deleteComment = (seq: number) => {
    openAlert('삭제하시겠습니까?', () => AlbumApi.deleteComment(seq).then(() => window.location.reload()));
  }
  const commentSubmit = (text: string, id: string) => {
    AlbumApi.setComment(album.id, { comment: text, reply: id }).then(() => getComment());
  }
  const commentEdit = (text: string, seq: number) => {
    AlbumApi.modifyComment(seq, { comment: text }).then(() => getComment());
  }

  return (
    <AlbumContext.Provider
      value={{ page, getAlbum, album, comment, getComment, deleteComment, commentSubmit, commentEdit }}>
      {children}
    </AlbumContext.Provider>
  )
}




export type AlbumComment = {
  seq: number;
  user_id: string;
  user_nickname: string;
  comment: string;
  reply: {
    seq: number;
    user_id: string;
    user_nickname: string;
    comment: string;
    datetime: string;
    mode: string;
  }[];
  datetime: string;
  mode: string;
};


export type AlbumComments = AlbumComment[];