import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from '../../../../../components/utils/atoms/Button';
import { Title } from '../../../../../components/utils/atoms/Title';
import addIcon from '../../../../../image/AddFilled.png';
import deleteIcon from '../../../../../image/DeleteFilled.png';
import down from '../../../../../image/arrow-under.png';
import up from '../../../../../image/arrow-up.png';
import { AddContent } from './content/AddContent';
import { CreateAlbumContext } from '../../../../../context/CreateAlbumContext';
import PDF from '../../../../../image/PictureasPdfFilled.png';
import Video from '../../../../../image/VideocamFilled.png';
import Link from '../../../../../image/LinkFilled.png';
import Text from '../../../../../image/text.png';
import Cloud from '../../../../../image/CloudDownloadFilled.png';
import { checkGreek, checkNaverUrl, checkYoutubeUrl } from '../../../../../utils/RegExp';
import { ConfirmContext } from '../../../../../context/ModalContext';

export const ContentsInput = () => {
  const { openConfirm } = useContext(ConfirmContext);
  const { contentAlbum, changeContentAlbum, changeChapter, changeStep } = useContext(CreateAlbumContext);
  const [checkVal, setCheckVal] = useState(false);


  // @@@@@@@@@@챕터 관리 @@@@@@@@@@@@
  //챕터 추가
  const addChapter = () => {

    const data = { ...contentAlbum };
    const updatedChapterArray = [
      ...data.chapter,
      {
        name: '',
        content: []
      }
    ];

    changeChapter(updatedChapterArray);

  }

  //챕터 삭제
  const deleteChapter = (i) => {
    const chapterArr = [...contentAlbum.chapter];
    const data = chapterArr.filter(((_, index) => index !== i))
    changeChapter(data)
  }

  const inputChapterName = (e, i) => {

    const data = { ...contentAlbum };
    data.chapter[i].name = e.target.value;

    changeContentAlbum(data);
  }

  //챕터 아래로 이동
  const downChapter = (index) => {
    const selectChapter = contentAlbum.chapter.splice(index, 1)[0];
    contentAlbum.chapter.splice(index + 1, 0, selectChapter)
    changeChapter(contentAlbum.chapter);
  }

  //챕터 위로 이동
  const upChapter = (index) => {
    const selectChapter = contentAlbum.chapter.splice(index, 1)[0];
    contentAlbum.chapter.splice(index - 1, 0, selectChapter);
    changeChapter(contentAlbum.chapter);
  }

  //@@@@@@@@@콘텐츠 관리@@@@@@@@@@
  const addContent = (i) => {
    const data = [...contentAlbum.chapter]

    data[i].content.push({
      type: '1',
      name: '',
      content: '',
      show: true,
      val: false,
    });
    changeChapter(data);
  }

  const changeLayerState = (_, chapterIdx, contentIdx) => {

    const data = [...contentAlbum.chapter]
    data[chapterIdx].content[contentIdx].show === true
      ? data[chapterIdx].content[contentIdx].show = false
      : data[chapterIdx].content[contentIdx].show = true;

    changeChapter(data)
  }

  const deleteItem = (_, chapterIdx, contentIdx) => {

    const chapterArr = [...contentAlbum.chapter];
    const data = chapterArr.map((chapterItem, idx) =>
      idx === chapterIdx
        ? {
          ...chapterItem,
          content: chapterItem.content.filter(
            (_, idx) => idx !== contentIdx
          )
        }
        : chapterItem
    )
    changeChapter(data);

  }

  //콘텐츠 위로 이동
  const upContent = (chapterIdx, contentIdx) => {
    const selectChapter = contentAlbum.chapter[chapterIdx].content.splice(contentIdx, 1)[0];
    contentAlbum.chapter[chapterIdx].content.splice(contentIdx - 1, 0, selectChapter);
    changeChapter(contentAlbum.chapter);
  }

  //콘텐츠 아래로 이동
  const downContent = (chapterIdx, contentIdx) => {
    const selectChapter = contentAlbum.chapter[chapterIdx].content.splice(contentIdx, 1)[0];
    contentAlbum.chapter[chapterIdx].content.splice(contentIdx + 1, 0, selectChapter);
    changeChapter(contentAlbum.chapter);
  }

  //다음 스텝 이동
  const goNextStep = () => {
    if (contentAlbum.chapter.length === 0) {
      //챕터가 생성되었는지 확인
      openConfirm('아무 요소도 입력되지 않았습니다.');
      return;
    } else {
      const updatedAlbum = contentAlbum.chapter.map(chapterItem => {
        if (chapterItem.name.includes("'")) {
          chapterItem.name = chapterItem.name.replaceAll("'", "");
        }
        if (chapterItem.name === '') {
          //제목 입력되었는지 확인
          openConfirm('챕터의 제목을 입력해주세요.');
          return { ...chapterItem, val: false };
        } else if (checkGreek(chapterItem.name)) {
          openConfirm('그리스문자는 입력이 불가합니다.');
          return { ...chapterItem, val: false };
        } else {
          chapterItem.val = true;
          if (chapterItem.content.length === 0) {
            //콘텐츠가 안들어간 챕터가 있는지 확인
            openConfirm('콘텐츠가 입력되지 않은 챕터가 있습니다.');
            return { ...chapterItem, val: false };
          }
          //챕터에 문제가 없다면 콘텐츠를 돌아가며 확인
          const updatedContent = chapterItem.content.map(contentItem => {
            if (contentItem.name.includes("'")) {
              contentItem.name = contentItem.name.replaceAll("'", "\\'");
            }
            if (contentItem.name === '' && contentItem.content === '' && contentItem.file === '') {
              // 제목이나 콘텐츠가 미입력 된 요소가 있는지 확인
              openConfirm('콘텐츠 입력값을 확인해주세요');
              return { ...contentItem, val: false };
            } else if (checkGreek(contentItem.name)) {
              openConfirm('그리스문자는 입력이 불가합니다.');
              return { ...chapterItem, val: false };
            } else {
              if (contentItem.type === '1') {
                const youtube = checkYoutubeUrl(contentItem.content);
                const naver = checkNaverUrl(contentItem.content);

                if (youtube === null && naver === null) {
                  openConfirm('콘텐츠 입력값을 확인해주세요');
                  return { ...contentItem, val: false };
                } else {
                  return { ...contentItem, val: true };
                }
              }
              if (contentItem.type === '4') {
                delete contentItem.content;
              }
              if (contentItem.type === '5') {

                if (checkGreek(contentItem.content)) {
                  openConfirm('그리스문자는 입력이 불가합니다.');
                  return { ...contentItem, val: false };
                }
                const escapedText = contentItem.content.replaceAll("'", "\\'");
                return { ...contentItem, content: escapedText, val: true };
              }

              return { ...contentItem, content: contentItem.content, val: true };
            }
          });

          return { ...chapterItem, content: updatedContent };
        }
      });

      // context에 업데이트
      changeChapter(updatedAlbum)


      const hasFalseValInAlbum = updatedAlbum.some(item => item.val === false);
      const hasFalseValInContent = updatedAlbum.some(item => item.content.some(contentItem => contentItem.val === false));

      // val값이 전부 true면 다음 단계 , 그렇지않으면 수정해야하는 부분을 표시해줌
      (hasFalseValInAlbum || hasFalseValInContent) ? setCheckVal(true) : changeStep(3);

    }


  }


  return (
    <div className='relative'>
      <Title>콘텐츠 입력</Title>
      <button className='float-right mt-10 mr-5 mb-3' onClick={() => addChapter()}>
        <img src={addIcon} alt="플러스아이콘" className='inline-block' />
        챕터 추가하기
      </button>
      {contentAlbum.chapter.map((chapter, i) => {
        return (
          <>
            <div className='clear-both m-5 border-2 p-3 flex-col' key={i} >
              <button onClick={() => upChapter(i)}>↑ 위로 이동</button>
              <button className='ml-4' onClick={() => downChapter(i)}>↓ 아래로 이동</button>
              <button className='mb3 float-right' onClick={() => deleteChapter(i)}>
                <img src={deleteIcon} alt="플러스아이콘" className='inline-block' />
                챕터 삭제
              </button>
              <button className='mb-3 mr-3 float-right' onClick={() => addContent(i)}>
                <img src={addIcon} alt="플러스아이콘" className='inline-block' />
                콘텐츠 추가
              </button>
              <div className='flex clear-both'>
                <div className='border border-dashed p-1 w-full'>
                  <input type="text"
                    onChange={(e) => inputChapterName(e, i)}
                    placeholder='챕터의 제목을 입력해주세요'
                    value={chapter.name}
                    className='w-full boder p-1' />
                </div>
                <div className='ml-2 flex gap-3'>
                </div>
              </div>
              {chapter.content.map((item, index) => {
                return (
                  <>
                    <div className='border-b'>
                      <div className='p-2 flex'>
                        <div>
                          {item.type.toString() === '1' ? <img className='w-[20px] mt-[3px] mr-3' src={Video} alt="Video" />
                            : item.type.toString() === '2' ? <img className='w-[20px] mt-[3px] mr-3' src={PDF} alt="PDF" />
                              : item.type.toString() === '3' ? <img className='w-[20px] mt-[3px] mr-3' src={Link} alt="Link" />
                                : item.type.toString() === '4' ? <img className='w-[20px] mt-[3px] mr-3' src={Cloud} alt="Cloud" />
                                  : <img className='w-[20px] mt-[3px] mr-3' src={Text} alt="Text" />}</div>
                        <div className={`${(item.val === false && checkVal === true) ? 'text-red-600' : 'text-black'} `}>{item.name === '' ? '제목을 입력해주세요' : item.name}</div>
                        <div className='flex gap-3 ml-auto'>
                          <button className='inline-block w-6 border px-1' onClick={() => upContent(i, index)}>↑</button>
                          <button className='inline-block w-6 border px-1' onClick={() => downContent(i, index)}>↓</button>
                          <button className='inline-block w-6 border px-1' onClick={() => changeLayerState(item, i, index)}>
                            {
                              item.show
                                ? <><img src={up} alt="윗방향화살표" className='inline-block' /> </>
                                : <><img src={down} alt="아래방향화살표" className='inline-block' /> </>
                            }

                          </button>
                          <button className='inline-block w-6 border p-[1px]' onClick={() => deleteItem(item, i, index)}>
                            <img src={deleteIcon} alt="삭제아이콘" className='inline-block' /> </button>
                        </div>
                      </div>
                      {item.show &&
                        <AddContent chapterIdx={i} contentIdx={index} />}
                    </div >
                  </>
                )
              })}
            </div >
          </>
        )
      })}
      <div className='mt-24 flex gap-2 justify-center'>
        <Button eventHandle={() => changeStep(1)} color="sub">이전단계로 이동</Button>
        <Button eventHandle={() => goNextStep()} color="sub">저장 후 다음 이동</Button>
      </div>
    </div>
  )
}



export type ContentType = {
  type: string,
  name: string,
  content?: string,
}
export type FileType = {
  type: string,
  name: string,
  file?: string,
}