import React, { ReactNode } from 'react'

type CategorySelectAttr = {
  children: ReactNode;
  selected?: string;
  clickItem: () => void;
}

export const CategoryItem = ({ children, selected = '', clickItem }: CategorySelectAttr) => {
  return (
    <div className={`border-2 pr-5 pl-5 pt-1 pb-1 inline-block rounded-sm m-1 cursor-pointer ${children === selected && 'border-[#9333ea]'}`} onClick={clickItem}>{children}</div>
  )
}
