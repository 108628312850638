import React, { useContext, useState } from 'react'
import { AlbumContext } from '../../../context/AlbumContext'


export const Remote = ({ target }) => {
  const { album } = useContext(AlbumContext);

  const [active, setActive] = useState(false);

  const handleScrollToSection = (id) => {
    const pointer = target.current.find(item => item.dataset.id === id)
    pointer.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={`fixed top-[250px] right-[50px] ${active ? 'w-[350px]' : 'w-[40px]'} overflow-hidden ${active && ' overflow-y-auto'} ease-in duration-300 min-h-[40px] max-h-[60vh] h-full tablet:top-[80px] tablet:right-0`}>
      <div className={`absolute flex ${active ? 'right-[0]' : 'right-[-300px]'} ease-in duration-300`}>
        <div
          className="cursor-pointer w-[25px] bg-slate-300 text-sm h-[40px] pt-1 text-white mt-10px text-center rounded-l-lg"
          onClick={() => setActive((prev) => !prev)}
        >
          {active ? '▶' : '◀'}
        </div>
        <div className={`h-full border  bg-white`}>
          <ul className='Remote'>
            {album?.chapter.map((chapterItem, index) => {
              return <li className='font-bold font-lg text-lg bg-gray-100' key={index}>
                {chapterItem.name}
                {
                  <ul>
                    {chapterItem?.content.map(item => {
                      return (
                        <li className="w-[300px] cursor-pointer p-1 h-[30px] hover:font-bold overflow-hidden text-ellipsis whitespace-nowrap font-normal text-base bg-white"
                          key={item.id}
                          onClick={() => handleScrollToSection(item.id)}
                        >
                          {item.name}
                        </li>
                      );
                    })}
                  </ul>

                }
              </li>
            })}
          </ul>
        </div>
      </div>
    </div >
  )
}

type listAttr = {
  name: string;
  target: number;
}