import React, { useContext, useEffect, useState } from 'react';
import styles from './Header.module.css';
import logo from '../../../image/logo.png';
import person from '../../../image/person.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfirmContext } from '../../../context/ModalContext';
import { UserContext } from '../../../context/UserContext';
import Link from '../atoms/Link';

type userLogin = {
  state: boolean;
  type: number | false;
}

export const Header = () => {
  //context에서 user상태를 가져옴
  const { user, logout, getUserInfo } = useContext(UserContext);
  //Header UI 업데이트를 위한 state
  const [loginState, setLoginState] = useState<userLogin | null>(null);
  //다이아로그
  const { openConfirm } = useContext(ConfirmContext);

  //모바일 전용
  const [menuToggle, setMenuToggle] = useState(false);

  const updateUser = () => {
    user !== null ? setLoginState({ state: true, type: user.type }) : setLoginState({ state: false, type: false });
  }

  const [mypage, setMypage] = useState<boolean>(false);
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const handleLogout = async () => {
    const result = await logout();
    if (result === 'success') {
      sessionStorage.removeItem('loginState/user');
      sessionStorage.removeItem('loginState/user-id');
      openConfirm('로그아웃 되었습니다', () => navigate(''));
    } else {
      openConfirm('로그아웃 실패');
    }
  }

  const routeAlbum = () => {
    loginState.state === true
      ? navigate('/mycontents/album/list')
      : navigate('/user/login');
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    setMenuToggle(false);
    updateUser();
    (sessionStorage.getItem('loginState/user') === 'true' && user === null) && getUserInfo();
  }, [pathname]);
  useEffect(() => {
    updateUser();
  }, [user])

  return (
    <header className={styles.header}>
      <div className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl m-auto'>
        <div className={`float-left ${styles.logo}`}>
          <Link route='/'>
            <div className='hover:cursor-pointer mt-2'>
              <img src={logo} alt="logo" />
            </div>
          </Link>
        </div>
        <nav>
          <ul className=' hidden desktop:flex float-left ml-3'>
            <li className='hover:cursor-pointer m-5 font-bold text-slate-700 ' onClick={() => sessionStorage.setItem('category', null)}>
              <Link route='/album/list'>앨범 보기</Link>
            </li>
            <li className='hover:cursor-pointer m-5 font-bold text-slate-700 '>
              <div onClick={routeAlbum}>내 앨범 보기</div>
            </li>
            <li className='hover:cursor-pointer m-5 font-bold text-slate-700 '>
              <Link route='/doc/service'>서비스 소개</Link>
            </li>
            <li className='hover:cursor-pointer m-5 font-bold text-slate-700 '>
              <Link route='/board/notice/list'>게시판</Link>
            </li>
          </ul>
        </nav>
        <nav>
          <ul className='flex float-right laptop:hidden'>
            {/* loginState가 null일 때 <></>을 반환, userContext를 통해 user 상태를 체크 후 ui를 변경시켜준다 */}
            {(loginState === null) ? <></>
              : loginState.state === true ? <>
                <li onMouseOver={() => setMypage(true)} onMouseLeave={() => setMypage(false)} >
                  <img src={person} alt="사람 아이콘" className='w-8 hover:cursor-pointer text-sm mt-4 float-right' />
                  {mypage && <ul className='bg-white border clear-both z-50'>
                    <li className=' p-1 pr-3 pl-3  border-b hover:bg-slate-100'><Link route='/mycontents/album/view' >내 열람</Link></li>
                    <li className=' p-1 pr-3 pl-3  border-b hover:bg-slate-100'><Link route='/mycontents/album/list'>내 앨범</Link></li>
                    <li className=' p-1 pr-3 pl-3  border-b hover:bg-slate-100'><Link route='/user/modify/info'>마이페이지</Link></li>
                    {
                      loginState.type === 1 && <li className=' p-1 pr-3 pl-3  border-b hover:bg-slate-100'><Link route='/manage/user'>관리자모드</Link></li>
                    }
                    <li className=' p-1 pr-3 pl-3  hover:bg-slate-100'>
                      <p className='cursor-pointer' onClick={() => handleLogout()}>로그아웃</p>
                    </li>
                  </ul>}
                </li>
              </>
                : loginState.state === false && <>
                  <li className='hover:cursor-pointer text-sm m-2 text-slate-600'>
                    <Link route='/user/login'>로그인</Link></li>
                  <li className='hover:cursor-pointer text-sm m-2 text-slate-600'>
                    <Link route='/user/join'>회원가입</Link>
                  </li>
                </>}
          </ul>
          {
            menuToggle ? (
              <ul className='hidden laptop:block'>
                <nav className='fixed top-0 right-0 bg-[#efefef] border border-r h-[100vh] w-[50vw]'>
                  <button className='absolute top-4 right-4' onClick={() => setMenuToggle(false)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <ul className='absolute top-10'>
                    <li className='m-5 font-bold text-slate-700 '>
                      <Link route='/album/list'>앨범 보기</Link>
                    </li>
                    <li className='m-5 font-bold text-slate-700 '>
                      <Link route='/doc/service'>서비스 소개</Link>
                    </li>
                    <li>
                      {(loginState === null || loginState.state === false) ?
                        <li className='m-5 font-bold text-slate-700 '>
                          <Link route='/user/login'>로그인</Link>
                        </li> :
                        <li className='m-5 font-bold text-slate-700 ' onClick={() => handleLogout()}>
                          로그아웃
                        </li>}
                    </li>
                  </ul>
                </nav>
              </ul>
            ) : (
              <button className='hidden laptop:block absolute top-4 right-4' onClick={() => setMenuToggle(true)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            )
          }
        </nav>
      </div >
    </header >
  )
};
