import { createContext, useState } from "react";


//@@@@@@ prompt @@@@@@
export const ModalContext = createContext(null);

export const PromptProvider = ({ children }: any) => {

  const [promptState, setPromptState] = useState(false);
  const [defaultVal, setDefaultVal] = useState('');
  const [promptSubmit, setPromptSubmit] = useState(() => { })


  const [promptText, setPromptText] = useState('');

  const openPrompt = (text, submit, val) => {
    (val !== undefined) && setDefaultVal(val);
    setPromptState(true);
    setPromptText(text);
    setPromptSubmit(submit);
  }


  const closePrompt = () => {
    setPromptState(false);
  }

  return (
    <ModalContext.Provider
      value={{ openPrompt, closePrompt, promptState, promptSubmit, setPromptSubmit, promptText, setPromptText, defaultVal, }}>
      {children}
    </ModalContext.Provider>
  )
}

//@@@@@@ confirm @@@@@@
export const ConfirmContext = createContext(null);

export const ConfirmProvider = ({ children }: any) => {
  const [confirmState, setConfirmState] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(() => { });
  const [confirmText, setConfirmText] = useState('');

  const openConfirm = (text, submit?) => {
    setConfirmState(true);
    setConfirmText(text);
    setConfirmSubmit(submit);
  };

  const closeConfirm = () => {
    setConfirmState(false);
  };

  return (
    <ConfirmContext.Provider value={{ openConfirm, closeConfirm, confirmState, confirmSubmit, setConfirmSubmit, confirmText, setConfirmText }}>
      {children}
    </ConfirmContext.Provider>
  );
};

//@@@@@ alert @@@@@
export const AlertContext = createContext(null);

export const AlertProvider = ({ children }: any) => {
  const [alertState, setAlertState] = useState(false);
  const [alertSubmit, setAlertSubmit] = useState(() => { });
  const [alertText, setAlertText] = useState('');

  const openAlert = (text, submit?) => {
    setAlertState(true);
    setAlertText(text);
    setAlertSubmit(() => submit);
  };

  const closeAlert = () => {
    setAlertState(false);
  };

  return (
    <AlertContext.Provider value={{ openAlert, closeAlert, alertState, alertSubmit, setAlertSubmit, alertText, setAlertText }}>
      {children}
    </AlertContext.Provider>
  );

};


//@@@@@ Dialog @@@@@
export const DialogContext = createContext(null);

export const DialogProvider = ({ children }: any) => {
  const [dialogState, setDialogState] = useState(false);
  const [dialogSubmit, setDialogSubmit] = useState(() => { });
  const [dialogComponent, setDialogComponent] = useState(null);

  const openDialog = (component, submit?) => {
    setDialogState(true);
    setDialogComponent(component);
    console.log(component)
    setDialogSubmit(() => submit);
  };

  const closeDialog = () => {
    setDialogState(false);
  };

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog, dialogState, dialogSubmit, setDialogSubmit, dialogComponent, setDialogComponent }}>
      {children}
    </DialogContext.Provider>
  );
}