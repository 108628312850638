import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlbumApi } from '../../../../api/album';
import { AlbumManageCard } from '../../../../components/utils/atoms/AlbumCard';
import { Button } from '../../../../components/utils/atoms/Button';
import { Filter } from '../../../../components/utils/atoms/Filter';
import { PagiNation } from '../../../../components/utils/atoms/PagiNation';
import { Title } from '../../../../components/utils/atoms/Title';
import { AlertContext, ConfirmContext } from '../../../../context/ModalContext';
import { SubNav } from '../../../utils/molecules/SubNav';


export const MyAlbumListPage = () => {
  const navigate = useNavigate();

  const { openConfirm } = useContext(ConfirmContext);
  const { openAlert } = useContext(AlertContext);


  const [params, setParams] = useState({
    user_agent: 'web',
    page: 1,
    sort_option: null,
    filter: null,
  });

  const [page, setPage] = useState({
    count: 1,
    currentPage: 1,
    endPage: 1,
    next: false,
    prev: false,
    startCount: 0,
    startPage: 1,
    totalPage: 1
  });

  const [myAlbumList, setMyAlbumList] = useState(null);

  const getMyAlbum = async () => {
    AlbumApi.getMyAlbumList(params).then(res => {

      setMyAlbumList(prev => res.data.resultList);
      setPage(prev => res.data.resultMap);
    });
  }

  useEffect(() => {
    getMyAlbum();
  }, [params]);


  const handlePageChange = (page) => {
    setParams((prev): any => ({ ...prev, page: page }));
    setPage(prev => ({ ...prev, currentPage: page }))
  }


  const handleClick = (item) => {
    item.pk === null ? navigate('/album/view/' + item.id) : navigate('/album/view/' + item.id + item.pk);
  }

  const handleCopyBtn = (id) => {
    navigator.clipboard.writeText('/codeenator.' + id);
    openConfirm('url이 복사되었습니다.');
  }
  const handleDelBtn = (item) => {
    openAlert('삭제하시겠습니까?', () => deleteAlbum(item));
  }

  const handleInfoBtn = async (item) => {
    function download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }
    const albumInfo = (await AlbumApi.getAlbum({ id: item.id })).data.resultMap;
    const saveAlbum = { ...albumInfo };
    delete saveAlbum['thumbnail'];


    download(JSON.stringify(saveAlbum), item.name + '.json', 'text/plain');
  }

  const handleEditBtn = (item) => {
    item.pk === null ? navigate('/mycontents/album/modify/' + item.id) : navigate('/mycontents/album/modify/' + item.id + item.pk);
    navigate('/mycontents/album/modify/' + item.id);
  }

  const deleteAlbum = (item) => {
    AlbumApi.deleteAlbum({ id: item.id }).then(() => getMyAlbum());
  }

  const handleSort = (item) => {
    setParams((prev): any => ({ ...prev, sort_option: item }));
  }
  const handleFilter = (item) => {
    setParams((prev): any => ({ ...prev, filter: item }));
  }

  return (
    <div className='desktop:max-w-7xl m-auto flex pt-5'>
      <SubNav menu={menuArr} />
      <div className='flex-auto ml-3 mb-12'>
        <Title>내 앨범 리스트</Title>
        <div className='flex w-[700px] float-left'>
          <div className='border overflow-hidden my-3 flex-1'>
            <Filter category={SortArr} propsHandle={handleSort} />
          </div>
          <div className='border overflow-hidden m-3 flex-1'>
            <Filter category={FilterArr} propsHandle={handleFilter} />
          </div>
        </div>
        <div className='m-3 float-right'>
          <Button eventHandle={() => navigate('/mycontents/album/create')} size="wide">앨범 만들기</Button>
        </div>
        <div className='w-[1130px] flex flex-wrap clear-both'>
          {(myAlbumList !== null && myAlbumList !== undefined) && myAlbumList.map((item) =>
            <AlbumManageCard
              key={item.id}
              pk={item.pk}
              img={`/codeenator${item.thumbnail}`}
              mainText={item.name}
              subText={''}
              mainTag={item.user_tag !== "" ? item.user_tag.split(",") : null}
              subTag={[]} state={item.status}
              callbackFx={() => handleClick(item)}
              handleCopyBtn={() => handleCopyBtn(item.id)}
              handleInfoBtn={() => handleInfoBtn(item)}
              handleEditBtn={() => handleEditBtn(item)}
              handleDelBtn={() => handleDelBtn(item)}
            />
          )}
        </div>
        <PagiNation
          activePage={page.currentPage}
          itemsCountPerPage={25}
          totalItemsCount={page.count}
          pageRangeDisplayed={5}
          prevPageText={"‹"}
          nextPageText={"›"}
          firstPageText={"‹‹"}
          lastPageText={"››"}
          onChange={handlePageChange} />
      </div>
    </div >
  )
}


export const menuArr = [
  {
    url: '/mycontents/album/list',
    text: '내 앨범'
  },
  {
    url: '/mycontents/album/comment',
    text: '댓글 관리'
  }
]

const SortArr = [{
  'title': '최신순',
  "value": 1,
}, {
  'title': '인기순',
  "value": 2,
}, {
  'title': '이름순',
  "value": 3,
}];

const FilterArr = [{
  'title': '전체보기',
  "value": null,
}, {
  'title': '등록취소',
  "value": '등록취소',
}, {
  'title': '검토중',
  "value": '검토중',
}, {
  'title': '등록',
  "value": '등록',
}];
