import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingSpinner } from '../../../../components/utils/atoms/LoadingSpinner'
import { PagiNation } from '../../../../components/utils/atoms/PagiNation'
import { Remote } from '../../../../components/utils/atoms/Remote'
import { AlbumInfo } from '../../../../components/utils/molecules/AlbumInfo'
import { CommentArea } from '../../../../components/utils/molecules/CommentArea'
import { AlbumContext } from '../../../../context/AlbumContext'
import book from '../../../../image/book.png'
import { FileContent } from '../../template/album/FileContent'
import { PDFContent } from '../../template/album/PDFContent'
import { TextContent } from '../../template/album/TextContent'
import { URLContent } from '../../template/album/URLContent'
import { VideoContent } from '../../template/album/VideoContent'
import { ConfirmContext } from '../../../../context/ModalContext';


export const AlbumViewPage = () => {
  const { id } = useParams();
  const { page, album, getAlbum, comment, changePage, commentSubmit, commentEdit } = useContext(AlbumContext);
  const [spinner, setSpinner] = useState(true);
  const { openConfirm } = useContext(ConfirmContext);


  const targetRef = useRef([]);

  const navigate = useNavigate();

  const handlePageChange = (page) => {
    changePage(prev => ({ ...prev, currentPage: page }))
  }


  //앨범 상세보기 (컨텐츠를 보는중엔 해당 컨텐츠부터)
  const openAlbum = () => {
    const elementsInView = Array.from(document.querySelectorAll(".album-content")).filter((el) => {
      const rect = el.getBoundingClientRect();
      return rect.bottom > 0 && rect.top < window.innerHeight;
    });


    const chapterId = elementsInView.length > 0
      ? elementsInView[0].getAttribute('data-id')
      : album.chapter[0].content[0].id;

    navigate('/album/contents/' + id + '/' + chapterId);

  }

  const handleOpenAlbum = (chapterId: string) => {
    navigate('/album/contents/' + id + '/' + chapterId)
  }

  useEffect(() => {
    getAlbum().then((res) => {
      setSpinner(false)
    });
  }, [page.currentPage]);

  useEffect(() => {
    if (album === undefined) {
      openConfirm('앨범 정보를 불러오는 중 에러가 발생했습니다', () => navigate('/album/list'));
      return;
    }
  }, []);


  return (
    <>
      {spinner && <LoadingSpinner />}
      <div className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl m-auto'>
        <section className='w-full mt-5 p-3 border rounded shadow-sm'>
          {album !== null && <AlbumInfo info={album} />}
        </section>
        <section className='w-full mt-5 rounded shadow-sm'>
          {(album !== null && album.chapter !== null) && album.chapter.map(((section, i) => {
            return (<div className='border border-[#333] p-1 mb-[15vh]' key={section.id} data-id={section.id}>
              <div className='leading-[50px] h-[50px]  p-2 text-lg items-center sticky top-[60px]  bg-white border-b'>
                <div>
                  <h2 className='text-center mb-5 text-[#9333EA] text-2xl'>Chapter {i + 1} <span className='text-center  text-[#333]'>{section.name}</span></h2>
                </div>
              </div>
              {
                section.content.map((content, j) => {
                  return (
                    <div className='album-content p-2 border shadow-md py-5'
                      data-id={content.id}
                      key={content.id}
                      ref={(ref) => {
                        if (ref !== null) {
                          targetRef.current = Array.from(new Set([...targetRef.current, ref]));
                        }
                      }
                      }
                    >
                      <div className='p-2 bg-slate-300 cursor-pointer' onClick={() => handleOpenAlbum(content.id)}>
                        <h3 className='album-content-title font-bold my-2 text-lg text-center'>{i + 1}-{j + 1}, {content.name}</h3>
                      </div>
                      {content.type === 1 ? <VideoContent videoLink={content.link} height='93vh' />
                        : content.type === 2 ? <PDFContent pdf={content.link} />
                          : content.type === 3 ? <URLContent urlLink={content.link} />
                            : content.type === 4 ? <FileContent file={content} />
                              : content.type === 5 && <TextContent text={content.link} />
                      }
                    </div>
                  )
                })
              }
            </div>)
          }))}
        </section >
        <section className='w-full mt-5 p-3 border rounded shadow-sm mb-10'>
          {(comment !== null && album !== null)
            && <CommentArea
              board='album'
              writer={album.user_id}
              submit={(text, id?) => commentSubmit(text, id)}
              editSubmitHandle={(text, seq?) => commentEdit(text, seq)}
              comments={comment} />}

          <PagiNation
            activePage={page.currentPage}
            itemsCountPerPage={20}
            totalItemsCount={page.count}
            pageRangeDisplayed={5}
            prevPageText={"‹"}
            nextPageText={"›"}
            firstPageText={"‹‹"}
            lastPageText={"››"}
            onChange={handlePageChange} />
        </section>
      </div >
      <Remote target={targetRef} />
      <div
        className='fixed cursor-pointer bottom-10 right-10 w-[60px] h-[60px] border bg-[#9333EA] rounded-md shadow-md 
        tablet:bottom-3 tablet:right-0 tablet:w-[50px] tablet:h-[50px] tablet:rounded-none tablet:rounded-l-lg'
        onClick={openAlbum}>
        <img src={book} alt="book" />
      </div>
    </>
  )
};

