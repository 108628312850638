/* eslint-disable react/jsx-pascal-case */
import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { PortalPage } from '../components/views/page/PortalPage';
import { RouteAlbum } from './RouteAlbum';
import { RouteBoard } from './RouteBoard';
import { RouteManage } from './RouteManage';
import { RouteMyContents } from './RouteMyContents';
import { RouteServiceDoc } from './RouteServiceDoc';
import { RouteUserInfo } from './RouteUserInfo';
import { UserContext } from '../context/UserContext';
import Modal from 'react-modal';
import { ModalContext, ConfirmContext, AlertContext, DialogContext } from '../context/ModalContext';
import { Alert, Confirm, Custom, Prompt } from '../components/utils/atoms/Dialog';

export const Router = () => {
  const { user } = useContext(UserContext);
  const { promptState, closePrompt, buttonText, promptSubmit, promptText, defaultVal } = useContext(ModalContext);
  const { confirmState, confirmText, closeConfirm } = useContext(ConfirmContext)
  const { alertState, alertText, alertSubmit, closeAlert } = useContext(AlertContext)
  const { dialogState, component, dialogSubmit, closeDialog } = useContext(DialogContext)

  // 페이지 컴포넌트 내용
  return (
    <>
      <Routes>
        <Route path='/' element={<PortalPage />}></Route>
        <Route path='/album/*' element={<RouteAlbum />}></Route>
        <Route path='/mycontents/*' element={<RouteMyContents />}></Route>
        <Route path='/manage/*' element={<RouteManage user={user} />}></Route>
        <Route path='/user/*' element={<RouteUserInfo />}></Route>
        <Route path='/board/*' element={<RouteBoard />}></Route>
        <Route path='/doc/*' element={<RouteServiceDoc />}></Route>
      </Routes>
      <Modal className='w-full absolute' isOpen={confirmState}>
        <Confirm
          msg={confirmText}
          closeHandle={closeConfirm}
          submitHandle={() => closeConfirm()} />
      </Modal>
      <Modal className='w-full absolute' isOpen={promptState}>
        <Prompt
          msg={promptText}
          confirmButton={buttonText}
          defaultVal={defaultVal}
          submitHandle={(text) => promptSubmit(text)}
          cancelHandle={closePrompt} />
      </Modal>
      <Modal className='w-full absolute' isOpen={alertState}>
        <Alert msg={alertText} submitHandle={alertSubmit} closeHandle={closeAlert} />
      </Modal>
      <Modal className='w-full absolute' isOpen={dialogState}>
        <Custom component={component} submitHandle={dialogSubmit} closeHandle={closeDialog} />
      </Modal>
    </>
  )
}
