import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "../../../swiper.css";
import "swiper/css/bundle";
import { Pagination, Navigation, Autoplay } from "swiper";
import styles from './BannerSwiper.module.css';

type BannerProps = {
  imageArray: any;
  listView: number;
}

export const BannerSwiper = ({ ...props }: BannerProps) => {
  // useEffect(() => { console.log(props.imageArray) }, [])


  const handleClick = (img: any) => {
    img.link && window.open(img.link);
  }
  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '">&nbsp; </span>';
    },
  };
  return (
    <>
      <div className={styles.container}>
        <Swiper
          spaceBetween={20}
          loop={true}
          autoplay={{
            delay: 3000,
          }}
          pagination={pagination}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper2"
        >
          {props.imageArray !== undefined && props.imageArray.map((img: any, index: number) => {
            return (
              <div
                key={index}>
                <SwiperSlide
                  key={index}
                  style={{ cursor: 'pointer' }}
                  onClick={() => { handleClick(img) }} >
                  <img src={'/codeenator' + img.file} alt="banner" />
                </SwiperSlide>
              </div>
            )
          })}
        </Swiper>
      </div>
    </>
  )
}
