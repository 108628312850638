import React from 'react'
import { useNavigate } from 'react-router-dom'

export const AlbumHomeNav = ({ albumId, albumTitle, contentTitle }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className='cursor-pointer mr-4 text-2xl font-bold h-10 leading-10 bg-white'
        onClick={() => navigate(`/album/view/${albumId}`)}>
        ←스크롤 형식으로 보기</div>
      <div className='w-full bg-black text-white p-3 h-10' >
        {albumTitle + ' / ' + contentTitle}
      </div>
    </>
  )
}
