import React, { useState } from 'react';
import Pagination from "react-js-pagination";
import styles from './PagiNation.module.css';

type PaginationAttr = {
  activePage: number,
  itemsCountPerPage: number,
  totalItemsCount: number,
  pageRangeDisplayed: number,
  prevPageText: string,
  nextPageText: string,
  firstPageText: string,
  lastPageText: string,
  onChange: (page) => void;
}

export const PagiNation = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  pageRangeDisplayed,
  prevPageText,
  nextPageText,
  firstPageText,
  lastPageText,
  onChange }: PaginationAttr) => {


  return (
    <div className={`${styles.pagination} flex justify-center `}>
      <div className='flex mt-10'>
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={5}
          prevPageText={"‹"}
          nextPageText={"›"}
          firstPageText={"‹‹"}
          lastPageText={"››"}
          onChange={onChange} />
      </div>
    </div>
  )
}
