import React from 'react'
import arrow from '../../../image/Arrow.png';

type PortalProps = {
  text: string;
}

export const PortalTitle = ({ text }: PortalProps) => {
  return (
    <div>
      <h3 className='inline-block text-lg'>{text}</h3>
      <img src={arrow} alt={'arrow'} className="w-5 pl-1 inline-block" />
    </div>
  )
}
