import { SubNav } from '../../../../components/utils/molecules/SubNav';
import React, { useEffect, useMemo, useState } from 'react';
import { manageMenuArr } from './ManageUserPage';
import { BoardTable } from '../../template/manage/BoardTable';
import { ManageApi } from '../../../../api/manage';
import { PagiNation } from '../../../../components/utils/atoms/PagiNation';

export const ManageBoardPage = () => {

  const [contentsList, setContentsList] = useState([]);
  const [page, setPage] = useState({
    count: 1,
    currentPage: 1,
    endPage: 1,
    next: false,
    prev: false,
    startCount: 0,
    startPage: 1,
    totalPage: 1
  });

  const columns = useMemo(() => columnData, []);

  const getList = async () => {
    const resultList = (await (ManageApi.getContentsList({ page: page.currentPage }))).data.resultList;
    const resultMap = (await (ManageApi.getContentsList({ page: page.currentPage }))).data.resultMap;
    setContentsList(resultList);
    setPage(resultMap);
  }

  useEffect(() => {
    getList();
  }, [page.currentPage]);

  const data = useMemo(() => contentsList, [contentsList]);

  const handlePageChange = (page) => {
    setPage((prev): any => ({ ...prev, currentPage: page }));
  }


  return (
    <>
      <div className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl m-auto flex pt-5'>
        <SubNav menu={manageMenuArr} />
        <div className='pl-5 w-full'>
          <h3>총 게시글 {page.count}개</h3>
          <BoardTable get={getList} columns={columns} data={data} />
        </div>
      </div>
      <PagiNation
        activePage={page.currentPage}
        itemsCountPerPage={10}
        totalItemsCount={page.count}
        pageRangeDisplayed={5}
        prevPageText={"‹"}
        nextPageText={"›"}
        firstPageText={"‹‹"}
        lastPageText={"››"}
        onChange={handlePageChange} />
    </>
  )
}

const columnData = [
  {
    Header: '게시글',
    accessor: 'title',
  },
  {
    Header: '구분',
    accessor: 'board_name',
  },
  {
    Header: '등록일',
    accessor: 'datetime',
  },
  {
    Header: '조회수',
    accessor: 'view_count',
  },
  {
    Header: '기능',
    accessor: 'manage',
  },
];