import React, { useContext, useEffect } from 'react'
import { InputCommnet } from '../atoms/InputCommnet'
import { Comment } from './Comment'
import { UserContext } from '../../../context/UserContext';
import { PagiNation } from '../atoms/PagiNation';
import { AlbumComments } from '../../../context/AlbumContext';
import { BoardComment } from './BoardComment';

type commentAttr = {
  board: 'album' | 'qna' | 'notice',
  writer?: string;
  submit: (text, seq?) => void;
  editSubmitHandle?: (text, seq) => void;
  deleteComment: (seq) => void;
  comments: AlbumComments;
}

export const BoardCommentArea = ({ board, writer, submit, deleteComment, editSubmitHandle, comments }: commentAttr) => {
  const { user, changeUserState, userState, getUserInfo } = useContext(UserContext);
  useEffect(() => {
    getUserInfo();
    changeUserState(true);

  }, [userState]);


  return (
    <div>
      {/* {user} */}
      {user !== null}
      {
        user !== null && <InputCommnet submit={submit} />
      }
      {comments !== null &&
        comments.map((comment) => {
          comment.mode = 'view'
          return <BoardComment
            board={board}
            submit={submit}
            writer={writer}
            comment={comment}
            deleteComment={deleteComment}
            editSubmitHandle={editSubmitHandle}
            key={comment.seq} />
        })}
    </div>
  )
}
