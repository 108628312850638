import React from 'react';
import styles from './CategoryCard.module.css';

type CategoryCardProps = {
  img?: string;
  textTop: string;
  textBottom: string;
  handleClick: () => void;
}

export const CategoryCard = ({ img, textTop, textBottom, handleClick }: CategoryCardProps) => {
  return (
    <div className={`shadow-md p-2 flex rounded cursor-pointer bg-white ${styles.flexItem}`} onClick={handleClick}>
      <div className={styles.textDiv}>
        <p className='text-sm'>{textTop}</p>
        <p className={`mt-8 font-bold  ${styles.bottomText}`}>{textBottom}</p>
      </div>
      {img && <img src={img} alt='책 이미지' className={styles.image} />}
    </div>
  )
}
