import React, { useRef } from 'react';
import search from '../../../image/search.png';


export const Search = ({ options, propsHandle }) => {

  const inputKeyword = useRef<HTMLInputElement>(null);
  const selectOption = useRef<HTMLSelectElement>(null);

  const handleSearch = () => {
    const keyword = inputKeyword.current.value.toString();
    const option = selectOption.current.value.toString();
    propsHandle(option, keyword);
  }

  return (
    <div className='relative border float-right  w-[439px] h-10 top-0 mr-3 tablet:w-[100%] tablet:float-left'>
      <select name="option" className='absolute right-[326px] top-[1px] px-2 h-9 tablet:left-0 tablet:w-[130px]' ref={selectOption}>
        {options.map(option => <option value={option.value} key={option.value}>{option.title}</option>)}
      </select>
      <input type="text"
        ref={inputKeyword}
        onKeyUp={(e) => { e.key === 'Enter' && handleSearch() }}
        className='float-right w-[300px] mt-[1px] mr-[1px] text-left space-x-3 px-4 h-9 bg-white  hover:ring-slate-300 focus:outline-none focus:ring-2 focus:ring-black shadow-sm rounded-sm dark:bg-slate-800 dark:ring-0 dark:text-slate-300 dark:highlight-white/5 dark:hover:bg-slate-700 border-none tablet:w-[200px]' />
      <img src={search} alt="search"
        onClick={handleSearch} className='absolute right-3 top-1 cursor-pointer' />
    </div>
  )
}
