import { IntroducePage } from '../components/views/page/serviceDoc/IntroducePage';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

export const RouteServiceDoc = () => {
  return (
    <Routes>
      <Route path='/service' element={<IntroducePage />}></Route>
    </Routes>
  )
}
