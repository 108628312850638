import { VideoAttr } from "../../../components/views/template/album/VideoContent";


export const Video = ({ videoLink, height }: VideoAttr) => {


  return (
    <div data-vjs-player>
      <iframe
        title="video"
        src={videoLink}
        width='100%' height={height} do-not-allow="autoplay"></iframe>
    </div>
  );
}


