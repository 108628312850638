import { ChildProcess } from 'child_process'
import React, { ReactNode } from 'react';
import styles from './Button.module.css';

type ButtonAttr = {
  children: ReactNode;
  size?: 'normal' | 'wide' | 'full';
  color?: 'main' | 'sub' | 'gray';
  eventHandle: () => void;
}

export const Button = ({ children, size = "normal", color = "main", eventHandle }: ButtonAttr) => {


  return (
    <button
      className={`rounded p-2 text-white 
    ${size === 'normal' ? styles.normalSize : size === 'wide' ? styles.wideSize : styles.fullSize}
    ${color === 'main' ? styles.mainColor : color === 'sub' ? styles.subColor : styles.grayColor}`}
      onClick={eventHandle}
    >
      {children}
    </button>
  )
}
