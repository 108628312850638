import React from 'react';
import styles from './Footer.module.css';
import logo from '../../../image/logo-bw.png';
import Link from '../atoms/Link';

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl m-auto pt-3'>
        <Link route='/'>
          <div className='hover:cursor-pointer'>
            <img src={logo} alt="logo" />
          </div>
        </Link>
        <p className='text-sm pt-3'>
          (82)2-494-4707 / soxcom@naver.com <br />
          301ho, 23, Seokgye-ro 18-gil, Nowon-gu, Seoul, 01886 Republic of Korea<br />
          © 2018-2022 by S.O.X Co.Ltd. All rights reserved<br />
        </p>
      </div>
    </footer>
  )
}
