import { CreateAlbumPage } from '../components/views/page/myContents/CreateAlbumPage';
import { MyAlbumListPage } from '../components/views/page/myContents/MyAlbumListPage';
import { ListenAlbumViewPage } from '../components/views/page/myContents/ListenAlbumViewPage';
import { MyCommentListPage } from '../components/views/page/myContents/MyCommentListPage';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CreateAlbumProvider } from '..//context/CreateAlbumContext';
import { CommentProvider } from '../context/CommentContext';
import { ModifyAlbumPage } from '../components/views/page/myContents/ModifyAlbumPage';

export const RouteMyContents = () => {
  return (
    <Routes>
      <Route path='/album/list' element={<MyAlbumListPage />}></Route>
      <Route path='/album/view' element={<ListenAlbumViewPage />}></Route>
      <Route
        path='/album/comment'
        element={
          <CommentProvider>
            <MyCommentListPage />
          </CommentProvider>}>
      </Route>
      <Route
        path='/album/create'
        element={
          <CreateAlbumProvider>
            <CreateAlbumPage />
          </CreateAlbumProvider>}>
      </Route>
      <Route
        path='/album/modify/:id'
        element={
          <CreateAlbumProvider>
            <ModifyAlbumPage />
          </CreateAlbumProvider>}>
      </Route>
    </Routes>
  )
}
