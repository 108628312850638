import { Button } from '../../../../components/utils/atoms/Button';
import { CreateAlbumContext } from '../../../../context/CreateAlbumContext';
import { UserContext } from '../../../../context/UserContext';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { ContentsInput } from '../../template/myContents/create/ContentsInput';
import { Publish } from '../../template/myContents/create/Publish';
import { AlbumInfo } from '../../template/myContents/create/AlbumInfo';
import CircleGray from '../../../../image/CheckCircleOutlineFilled.png';
import CirclePurple from '../../../../image/CheckCircleOutlineFilledPurple.png';
import { AlbumApi } from '../../../../api/album';
import { ConfirmContext } from '../../../../context/ModalContext';


export const ModifyAlbumPage = () => {

  const { id } = useParams();

  const { step, contentAlbum, changeInfo } = useContext(CreateAlbumContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { openConfirm } = useContext(ConfirmContext);
  const [pk, setPk] = useState('');


  //json파일 처리
  const JSONfileRef = useRef<HTMLInputElement>(null); //파일입력창(히든)

  const loadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const json = JSON.parse(event.target?.result as string);
      const toText = JSON.stringify(json);
      const escaped = toText.replace(/'/g, "＇");
      const data = JSON.parse(escaped)
      changeInfo(data);
    };
    reader.readAsText(file);
  }

  const saveFile = () => {
    function download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }
    delete contentAlbum.thumbnail;
    download(JSON.stringify(contentAlbum), contentAlbum.name + '.json', 'text/plain');
  }


  const chackUserState = () => {
    user === null && openConfirm('해당 메뉴는 로그인 후 이용 가능합니다.', () => navigate('/album/list'));
  }
  const getAlbum = async () => {
    let albumId: string, albumPk: string;
    if (id.length === 6) {
      albumId = id;
    } else if (id.length === 12) {
      albumId = id.slice(0, 6);
      albumPk = id.slice(-6);
      setPk(albumPk)
    } else {
      navigate('/album/list');
      return;
    }
    try {
      const albumRes = await AlbumApi.getAlbum({ id: albumId, pk: albumPk });
      const { result, resultMap } = albumRes.data;

      changeInfo(resultMap, albumPk);

      if (result === "failed") {
        navigate('/album/list');
        return;
      }

    } catch (error) {
      console.error('Error while fetching album:', error);
    }
  }

  useEffect(() => {
    chackUserState();
  }, [user])

  useEffect(() => {
    getAlbum();
  }, [])




  return (
    <div className='bg-zinc-100 min-h-[100vh] pb-10'>
      <div className='desktop:max-w-7xl laptop:max-w-4xl tablet:max-w-3xl m-auto flex'>
        <div className='flex-auto w-1/4 pt-8'>
          <h3 className='font-bold text-lg'>앨범 제작</h3>
          <ul className='mt-5 mb-10'>
            <li className='mt-3 mb-3'>
              {step === 1
                ? <img src={CircleGray} alt="" className='inline' />
                : <img src={CirclePurple} alt="" className='inline' />}
              앨범 정보
            </li>
            <li className='mt-3 mb-3'>
              {step === 1 || step === 2
                ? <img src={CircleGray} alt="" className='inline' />
                : <img src={CirclePurple} alt="" className='inline' />}
              콘텐츠 입력
            </li>
            <li className='mt-3 mb-3'>
              <img src={CircleGray} alt="" className='inline' />
              동의 및 발행
            </li>
          </ul>
          <input
            type="file"
            ref={JSONfileRef}
            onChange={(e) => loadFile(e)}
            hidden />
          <div className='flex gap-2'>
            {
              (step === 2 || step === 3) && <Button eventHandle={saveFile}>JSON 저장하기</Button>
            }
          </div>
        </div>
        <div className='flex-auto w-3/4 bg-white p-8'>
          {step === 1
            ? <AlbumInfo privateKey={pk} />
            : step === 2
              ? <ContentsInput />
              : <Publish />}
        </div>
      </div>
    </div>
  )
}
